import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import unionBy from 'lodash/unionBy';
import mergeWith from 'lodash/mergeWith';
import {
  RECEIVE_DELETE_ITEM,
  RECEIVE_ORDER_ITEMS,
  RECEIVE_CHANGE_SHIPMENT_DATE,
  RECEIVE_CANCEL_SUBSCRIPTION,
  RECEIVE_PAUSE_SUBSCRIPTION,
  RECEIVE_CHANGE_SUBSCRIPTION_RENEWAL_BEHAVIOR,
  RECEIVE_CHANGE_ORDER_SHIPPING,
  REPLACE_ORDER_ITEMS
} from '../constants';
import { groupBy } from '../utils';
import { mapOrderItem } from './order_item_by_id';
import { isCancelledPrepaidSubscriptionWithNoOrdersRemaining } from '../sagas/request-change-subscription-renewal-behavior';
import { State } from '../types';

function customizer(objValue, srcValue) {
  if (Array.isArray(objValue)) {
    return unionBy(
      srcValue,
      objValue.map(orderItem =>
        orderItem.subscription && orderItem.subscription === srcValue[0].subscription ? srcValue[0] : orderItem
      ),
      'public_id'
    );
  }
  return undefined;
}
const hasLength = it => it.length > 0;

function removeItemsAssociatedWithSubscription(items, subscription_public_id: string) {
  return pickBy(
    mapValues(items, order_items => order_items.filter(item => item.subscription !== subscription_public_id)),
    hasLength
  );
}

function resultsToState(results) {
  return groupBy(results.map(mapOrderItem), 'order');
}

export default function items_by_order(
  state: State['items_by_order'] = {},
  { type, payload }: { type?: string; payload?: any } = {}
) {
  switch (type) {
    case RECEIVE_ORDER_ITEMS:
      return mergeWith(state, resultsToState(payload.results), customizer);
    case REPLACE_ORDER_ITEMS:
      return resultsToState(payload.results);

    case RECEIVE_CHANGE_SHIPMENT_DATE:
    case RECEIVE_CHANGE_ORDER_SHIPPING:
      if (payload.current.public_id === payload.previous_order_id) return state;
      /**
       * These actions have the potential to merge orders. If the API response contains a new public ID, then the original order was merged with another.
       */
      return {
        ...omit(state, payload.previous_order_id),
        [payload.current.public_id]: [
          ...state[payload.current.public_id],
          ...state[payload.previous_order_id].map(order_item => ({
            ...order_item,
            order: payload.current.public_id
          }))
        ]
      };

    case RECEIVE_CANCEL_SUBSCRIPTION:
      return removeItemsAssociatedWithSubscription(state, payload.subscription.public_id);

    case RECEIVE_DELETE_ITEM:
      return pickBy(
        mapValues(state, order_items =>
          order_items.find(it => it.public_id === payload.item_id)
            ? order_items.filter(it => it.public_id !== payload.item_id)
            : order_items
        ),
        hasLength
      );

    case RECEIVE_PAUSE_SUBSCRIPTION:
      return pickBy(
        mapValues(state, order_items =>
          order_items.length > 1 && order_items.find(it => it.subscription === payload.subscription_id)
            ? order_items.filter(it => it.subscription !== payload.subscription_id)
            : order_items
        ),
        hasLength
      );

    case RECEIVE_CHANGE_SUBSCRIPTION_RENEWAL_BEHAVIOR:
      if (isCancelledPrepaidSubscriptionWithNoOrdersRemaining(payload.subscription)) {
        return removeItemsAssociatedWithSubscription(state, payload.subscription.public_id);
      }
      return state;
    default:
      return state;
  }
}
