/**
 * @module Filters
 */
import memoize from 'lodash/memoize';

import { ifDefined } from 'lit-html/directives/if-defined';
import { until } from 'lit-html/directives/until';

import first from 'lodash/first';
import last from 'lodash/last';
import get from 'lodash/get';
import shuffle from 'lodash/shuffle';
import createDate from './create-date';
import createCurrency from './create-currency';
import find from './find';
import select from './select';
import reject from './reject';
import sort from './sort';
import action from './action';
import entries from './entries';
import key from './key';
import batch from './batch';

/**
 * Filters are functions that can be applied to variables.
 * They are called with a pipe operator (|) and can take arguments.
 * Below you can see all the filters currently implemented in the Subscription Manager templates.
 */
export interface Filters {
  /**
   * This filter handles the form validation and submission to Ordergroove backend
   *
   * ```jinja-html
   * <form action="{{ 'send_now' | action }}">
   *  <input type="hidden" value="{{ subscription.public_id }}" name="subscription" />
   *  <button class="og-button" type="submit" name="send_now">Send</button>
   * </form>
   * ```
   *
   * By default, the submit button will be disabled during form submission. If you don't want that to happen, set `data-disable-submitter="false"` on the form element.
   *
   * @param formName Special keyword describing how the form needs to be handled in Ordergroove's backend.
   *  Note that some handlers require special fields in the form to be present as hidden inputs.
   *
   */

  action: (
    formName:
      | 'cancel_subscription'
      | 'change_quantity'
      | 'change_item_quantity'
      | 'change_product'
      | 'change_shipment_date'
      | 'change_shipping_address'
      | 'delete_item'
      | 'reactivate_subscription'
      | 'send_now'
      | 'change_subscription_frequency'
      | 'change_order_address_id'
      | 'skip_shipment'
      | 'pause_subscription'
      | 'send_payment_email'
      | 'upgrade_subscription_to_prepaid'
      | 'upgrade_one_time_to_subscription'
  ) => (part: any) => void;
  /**
   * https://lit-html.polymer-project.org/guide/template-reference#until
   */
  until: (...args: unknown[]) => (part: import('lit-html').Part) => void;
  /**
   * https://lit-html.polymer-project.org/guide/template-reference#ifdefined
   */
  if_defined: (value: unknown) => (part: import('lit-html').Part) => void;

  /**
   *
   * Returns a localized date in specified format
   * @param value - Value any string like date value
   * @param format - string may be any combination of DateFormatString, see [dayjs](https://day.js.org/docs/en/display/format)
   *
   *
   * @returns A formatted date string
   */
  date: (value: string, format: string) => string;

  /**
   * Returns a localized currency number. Internally using [NumberFormat](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat)
   *
   * @param amount Number or string like number
   * @param currency A valid currency code it defaults whatever localization file says as "currency_code": "USD",
   * @param currencyDisplay How to display the currency defaults to narrow
   */
  currency: (amount: string | number, currency?: string, currencyDisplay?: string) => string;

  /**
   * https://lodash.com/docs/4.17.15#find
   */
  find: (list: any, ...identity: any[]) => any;

  /**
   * Creates an identity function based on an optional filter function.
   * If no filter function is provided and the argument is a string of 32 length,
   * a default filter function using public_id will be used.
   * @param {*} Filter function
   *
   * ```jinja-html
   * {% if (orders | select(status='SEND_NOW')).length %}
   *   You have some orders in send now status
   * {% endif %}
   * ```
   */
  select: (list: any, ...identity: any[]) => any;

  /**
   * https://lodash.com/docs/4.17.15#reject
   */
  reject: (list: any, ...identity: any[]) => any;

  /**
   * https://lodash.com/docs/4.17.15#sort
   */
  sort: (list: any, ...identity: any[]) => any;

  /**
   * https://lodash.com/docs/4.17.15#first
   */
  first: (list: any, ...identity: any[]) => any;

  /**
   * https://lodash.com/docs/4.17.15#last
   */
  last: (list: any, ...identity: any[]) => any;

  /**
   * https://lodash.com/docs/4.17.15#get
   */
  get: (list: any, ...identity: any[]) => any;

  /**
   * Creates an array of shuffled values, using a version of the
   * [Fisher-Yates shuffle](https://en.wikipedia.org/wiki/Fisher-Yates_shuffle).
   *
   * ```jinja-html
   * {% for reason in cancel_reasons | entries | shuffle %}
   *   {% set code = reason | first %}
   *   {% set text = reason | last %}
   *   {% if code != '1' %}
   *     <div class="og-input-group">
   *       <input class="og-check-radio" required type="radio" name="cancel_reason"
   *         value="{{ code }} | {{ text }}" id="cancel_reason_{{ code }}_{{ subscription.public_id }}"/>
   *       <label class="og-check-radio-label" for="cancel_reason_{{ code }}_{{ subscription.public_id }}">
   *         {{ text }}
   *       </label>
   *     </div>
   *   {% endif %}
   * {% endfor %}
   * ```
   */
  shuffle: (list: any) => any;

  /**
   * Dumps the string representation of the value, useful for debugging purpose.
   */
  dump: (value: any) => string;

  /**
   * Returns the result of JavaScript's Object.entries function
   */
  entries: (value: any) => string;

  /**
   * Extends the given list with a key, to allow efficient updates when the list changes.
   */
  key: (list: any[], key: string) => any;

  /**
   *Return a list of lists with the given number of items
   */
  batch: (list: any[], n: number) => any[][];
}

/**
 * @internal
 * Setups the filter being used in templates under `_F` namespace
 * @returns
 */
export function createFilters(locale): Filters {
  const warnedFilters = new Set();
  return new Proxy<Filters>(
    {
      action: action,
      until,
      if_defined: ifDefined,
      date: createDate(locale),
      currency: createCurrency(locale),
      find: find,
      select: select,
      reject: reject,
      sort: sort,
      first: first,
      last: last,
      get: get,
      shuffle: shuffle,
      dump: JSON.stringify,
      entries: entries,
      key,
      batch
    },
    {
      // if we attempt to access a filter that does not exist, apply a no-op instead
      get(target, method) {
        if (method in target) {
          return target[method];
        }
        return x => {
          if (!warnedFilters.has(method)) {
            console.warn(`${String(method)} filter is not present`);
            warnedFilters.add(method);
          }
          return x;
        };
      }
    }
  );
}

/**
 * @internal
 * @type {Function}
 */
export default memoize(createFilters);
