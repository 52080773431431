import { RECEIVE_SUBSCRIPTION_DISCOUNT_CODES } from '../constants';
import { DiscountCodesPayload } from '../types';

export default function discount_codes_by_subscription_id(
  state = {},
  action: {
    type: string;
    payload?: DiscountCodesPayload;
  } = { type: '' }
) {
  const { type, payload = {} } = action;

  if (type !== RECEIVE_SUBSCRIPTION_DISCOUNT_CODES) {
    return state;
  }

  const { results = {}, subscription_id = '' } = payload;
  const { discount_codes_summary = [] } = results;
  const discountCodes = discount_codes_summary.map(({ title }) => ({
    title
  }));

  if (!subscription_id) {
    return state;
  }

  return {
    ...state,
    [subscription_id]: discountCodes
  };
}
