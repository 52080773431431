import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';

import { State } from '@ordergroove/smi-store/types';

export const selectSubscription = memoize(order_id =>
  createSelector(
    (state: State) => state.subscriptions,
    subscriptions => subscriptions.find(order => order.public_id === order_id)
  )
);

export const selectOrderItem = memoize(order_item_id =>
  createSelector(
    (state: any) => state.order_item_by_id,
    order_item_by_id => order_item_by_id[order_item_id]
  )
);

export const selectOrder = memoize(order_id =>
  createSelector(
    (state: State) => state.orders,
    orders => orders.find(order => order.public_id === order_id)
  )
);

export const selectProduct = memoize(product_id =>
  createSelector(
    (state: State) => state.products,
    products => products.find(product => product.external_product_id === product_id)
  )
);

export const selectPayment = memoize(payment_token =>
  createSelector(
    (state: State) => state.payments,
    payments => payments.find(payment => payment.token_id === payment_token)
  )
);

export const publicId = order => (typeof order === 'object' ? order.public_id : order);

export const getPaymentId = payment => (typeof payment === 'object' ? payment.token_id : payment);
