import { call, put, select } from 'redux-saga/effects';
import { State } from '..';

import { RECEIVE_CHANGE_PRODUCT, ERROR_CHANGE_PRODUCT } from '../constants';
import { lego_subscriptions_change_product } from './api';

export default function* REQUEST_CHANGE_PRODUCT({
  payload: { subscription_id, product_id } = {}
}: {
  payload: { subscription_id?: string; product_id?: string };
}) {
  try {
    const { items_by_order, subscriptions } = (yield select()) as State;
    const old_subscription_object = subscriptions.find(sub => sub.public_id === subscription_id);
    const subscription = yield call(lego_subscriptions_change_product, subscription_id, product_id);
    const refresh_orders = Array.from(
      new Set(
        Object.values(items_by_order)
          .flat()
          .filter(order_item => order_item.subscription === subscription_id)
          .map(order_item => order_item.order)
      )
    );
    yield put({
      type: RECEIVE_CHANGE_PRODUCT,
      payload: {
        subscription,
        refresh_orders,
        old_product: old_subscription_object.product
      }
    });
  } catch (error) {
    Object.assign(error, { subscription_id, product_id });
    const errorResponse = yield error.response.json();
    if (errorResponse && errorResponse.detail) {
      yield put({ type: ERROR_CHANGE_PRODUCT, payload: errorResponse.detail });
    }
  }
}
