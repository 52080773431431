import orderBy from 'lodash/orderBy';
import wrapFilter from './wrap-filter';

const reduceOrderBy = ([fields, order], cur) =>
  cur.startsWith('-')
    ? [fields.concat(cur.substring(1)), order.concat('desc')]
    : [fields.concat(cur), order.concat('asc')];

function sort(state, predicate) {
  return orderBy(state, ...[].concat(predicate).reduce(reduceOrderBy, [[], []]));
}

export default wrapFilter(sort);
