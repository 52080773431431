import { all, call, put, take, select } from 'redux-saga/effects';
import authResolver from '@ordergroove/auth';

import { getPayload } from '../utils';
import { AUTHORIZE, SET_AUTH_URL, SET_MERCHANT_ID, UNAUTHORIZED } from '../constants';

export function* watchRequestAuth() {
  const { auth } = yield select();

  if (!auth) {
    let { merchant_id, auth_url } = yield select();

    if (!merchant_id && !auth_url) {
      [merchant_id, auth_url] = (yield all([take(SET_MERCHANT_ID), take(SET_AUTH_URL)])).map(getPayload);
    }
    try {
      const { sig_field, ts, sig } = yield call(authResolver, auth_url);
      yield put({
        type: AUTHORIZE,
        payload: {
          public_id: merchant_id,
          sig_field,
          ts,
          sig
        }
      });
    } catch (err) {
      yield put({ type: UNAUTHORIZED });
      console.error(err);
    }
  }
}
