import { uniqBy } from 'lodash';
import { RECEIVE_SUBSCRIPTION_DISCOUNT_CODES } from '../constants';

export default function discount_codes_by_order_id(
  state: any = {
    order_items: [],
    items_by_order: {},
    discount_codes_by_order_id: {},
    discount_codes_by_subscription_id: {}
  },
  { type, payload = {} }: { type: string; payload: any }
) {
  if (type !== RECEIVE_SUBSCRIPTION_DISCOUNT_CODES) {
    return state;
  }

  const { order_items, discount_codes_by_subscription_id, items_by_order } = state;

  const { subscription_id } = payload;
  if (
    !subscription_id ||
    !order_items.length ||
    !Object.keys(discount_codes_by_subscription_id).length ||
    !Object.keys(items_by_order).length
  ) {
    return state;
  }

  // find the order that the subscription is associated with
  const order_id = order_items.find(order_item => order_item.subscription === subscription_id).order;

  // compile a list of the subscriptions in order_id
  const subscriptions = items_by_order[order_id].map(order_item => order_item.subscription);

  // compile a unique list of discount codes for the subscriptions
  const order_discount_codes = uniqBy(
    subscriptions.flatMap(subscription => discount_codes_by_subscription_id[subscription] || []),
    'title'
  );

  const updated_discount_codes_by_order_id = { ...state.discount_codes_by_order_id, [order_id]: order_discount_codes };

  return {
    ...state,
    discount_codes_by_order_id: updated_discount_codes_by_order_id
  };
}
