/**
 * Template Overview
 *
 * The SMI application consists of templates. You can change these templates to alter
 * the layout or even inject your own content as you see fit.
 *
 * @internal
 */
import { findBestMatchingLocale } from '@ordergroove/smi-store/utils';
import { TemplateResult } from 'lit-html';
import { State } from '@ordergroove/smi-store/types';
import memoizedCreateFilters from './filters/create-filters';

/**
 * @internal
 * The representation of an SMI template reads as a function that given an
 * state produces a [lit-html TemplateResult](https://lit-html.polymer-project.org/guide/writing-templates)
 *
 */
export type Template = (state: State & { client_state: Record<string, unknown> }) => TemplateResult;

/** @internal */
export default function createRenderer(templates: Template | { [key: string]: Template }): Template {
  const localizedTemplates = typeof templates === 'object' ? Object.entries(templates) : [['en-US', templates]];

  return state => {
    const bestMatchLocale = findBestMatchingLocale(
      state.locale,
      localizedTemplates.map(t => t[0])
    );
    const tmpl = localizedTemplates.find(([key]) => key === bestMatchLocale)[1] as Function;
    // use the raw locale here, since we want to allow for currency/date localization in locales that the merchant doesn't have a locale file for
    return tmpl(state, memoizedCreateFilters(state.locale)) as TemplateResult;
  };
}
