interface KeyCheckedArrayItem {
  __og_key: any;
}

interface KeyCheckedArray<T extends KeyCheckedArrayItem = KeyCheckedArrayItem> extends Array<T> {
  __og_should_key?: boolean;
}

export default function key(arr: object[], property = 'public_id') {
  const extendedArray = arr.map(item => ({
    ...item,
    __og_key: item[property]
  })) as KeyCheckedArray;
  // the compiler will check this field to determine whether to use an unkeyed `map` function or Lit's `repeat` directive to render the loop
  extendedArray.__og_should_key = true;
  return extendedArray;
}
