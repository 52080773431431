import requestApplyDiscountCode from '@ordergroove/smi-store/sagas/request-apply-discount-code-to-order';

import { State, sagaMiddleware } from '../core/store';
import { selectOrder } from './selectors';

export function initialize(form: HTMLFormElement) {
  const orderEl = form.querySelector('[name="order"]');
  if (!orderEl) {
    throw new Error('Missing <input type="hidden" name="order" value="{{ order.public_id }}">');
  }
}

export function validate(data: FormData, state: State) {
  const order_id = data.get('order');

  if (!order_id) return { valid: false, field: 'order', reason: 'INVALID_ORDER_ID' };

  const order = selectOrder(order_id)(state);

  if (typeof order === 'undefined') return { valid: false, field: 'order', reason: 'ORDER_NOT_FOUND' };

  const discountCode = data.get('discount_code');
  if (!discountCode) return { valid: false, field: 'discount_code', reason: 'DISCOUNT_CODE_REQUIRED' };

  return { valid: true };
}

export function submit(data: FormData) {
  const order = data.get('order') as string;
  const discount_code = data.get('discount_code') as string;

  const task = sagaMiddleware.run(requestApplyDiscountCode, {
    payload: {
      order,
      discount_code
    }
  });
  return task;
}

export default {
  validate,
  initialize,
  submit
};
