import {
  RECEIVE_ADDRESS,
  RECEIVE_ADDRESSES,
  RECEIVE_CREATE_SHIPPING_ADDRESS,
  RECEIVE_DELETE_SHIPPING_ADDRESS,
  RECEIVE_EDIT_SHIPPING_ADDRESS
} from '../constants';
import { indexBy } from '../utils';

export default function address_by_id(state = {}, { type, payload }) {
  switch (type) {
    case RECEIVE_CREATE_SHIPPING_ADDRESS:
      return { ...state, [payload.public_id]: payload };
    case RECEIVE_ADDRESSES:
      return indexBy(payload.results, 'public_id');
    case RECEIVE_ADDRESS:
      return { ...state, [payload.public_id]: payload };
    case RECEIVE_EDIT_SHIPPING_ADDRESS:
      return {
        ...state,
        [payload.old_public_id]: { ...state[payload.old_public_id], live: false },
        [payload.new_address.public_id]: payload.new_address
      };
    case RECEIVE_DELETE_SHIPPING_ADDRESS:
      return {
        ...state,
        [payload.public_id]: {
          ...state[payload.public_id],
          live: false
        }
      };
    default:
      return state;
  }
}
