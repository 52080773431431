import * as constants from '../constants';

export default function session_id(state = null, action) {
  switch (action.type) {
    case constants.SET_MERCHANT_ID:
      return `${action.payload}.${Math.floor(Math.random() * 999999)}.${Math.round(new Date().getTime() / 1000.0)}`;
    default:
      return state;
  }
}
