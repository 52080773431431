import requestChangeSubscriptionFrequency from '@ordergroove/smi-store/sagas/request-change-subscription-frequency';
import { sagaMiddleware } from '../core/store';
import { selectSubscription } from './selectors';

const isNumber = n => !Number.isNaN(parseInt(n, 10)) && Number.isFinite(parseInt(n, 10));
const validFrequency = frequency =>
  typeof frequency === 'string' ? frequency.split('_').filter(isNumber).length === 2 : false;

export const parseFrequency = frequency => ({
  every: parseInt(frequency.split('_')[0], 10),
  every_period: parseInt(frequency.split('_')[1], 10)
});

export function submit(formData) {
  const subscription_id = formData.get('subscription');
  const frequency = parseFrequency(formData.get('frequency'));

  const task = sagaMiddleware.run(requestChangeSubscriptionFrequency, {
    payload: {
      subscription_id: subscription_id,
      ...frequency
    }
  });
  return task;
}

export function initialize(form) {
  const subscriptionEl = form.querySelector('[name="subscription"]');
  if (!subscriptionEl) {
    throw new Error('Missing <input name="subscription" value="{{ subscription.public_id }}">');
  }
  const frequencyEl = form.querySelector('[name="frequency"]');
  if (!frequencyEl) {
    throw new Error('Missing <input name="frequency" value="{{frequency}}">');
  }
}

/**
 *
 * @param {FormData} data
 * @param {Object} state
 * @returns
 */
export function validate(data, state) {
  const subscription_id = data.get('subscription');
  if (!subscription_id) return { valid: false, field: 'subscription', reason: 'INVALID_SUBSCRIPTION_ID' };
  const rawFrequency = data.get('frequency');
  if (!validFrequency(rawFrequency)) return { valid: false, field: 'frequency', reason: 'INVALID_FREQUENCY_FORMAT' };

  const subscription = selectSubscription(subscription_id)(state);
  if (typeof subscription === 'undefined')
    return { valid: false, field: 'subscription', reason: 'SUBSCRIPTION_NOT_FOUND' };

  return { valid: true };
}

export default {
  validate,
  initialize,
  submit
};
