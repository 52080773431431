import cancelSubscription from './cancel-subscription';
import changeQuantity from './change-quantity';
import changeItemQuantity from './change-item-quantity';
import changeProduct from './change-product';
import changeShipmentDate from './change-shipment-date';
import changeShippingAddress from './change-shipping-address';
import changeOrderAddressId from './change-order-address-id';
import reactivateSubscription from './reactivate-subscription';
import sendNow from './send-now';
import cancelOrder from './cancel-order';
import pauseSubscription from './pause-subscription';
import pauseSubscriptionRadio from './pause-subscription-radio';
import changeSubscriptionFrequency from './change-subscription-frequency';
import deleteItem from './delete-item';
import sendPaymentEmail from './send-payment-email';
import changeRenewalBehavior from './change-renewal-behavior';
import upgradeSubscriptionToPrepaid from './upgrade-subscription-to-prepaid';
import upgradeOneTimeToSubscription from './upgrade-one-time-to-subscription';
import deleteShippingAddress from './delete-shipping-address';
import applyDiscountCodeToOrder from './apply-discount-code-to-order';

import { registerHandler } from '../core/filters/action';

registerHandler('cancel_subscription', cancelSubscription);
registerHandler('change_quantity', changeQuantity);
registerHandler('change_item_quantity', changeItemQuantity);
registerHandler('change_product', changeProduct);
registerHandler('change_shipment_date', changeShipmentDate);
registerHandler('change_shipping_address', changeShippingAddress);
registerHandler('change_order_address_id', changeOrderAddressId);
registerHandler('delete_shipping_address', deleteShippingAddress);
registerHandler('delete_item', deleteItem);
registerHandler('reactivate_subscription', reactivateSubscription);
registerHandler('send_now', sendNow);
registerHandler('change_subscription_frequency', changeSubscriptionFrequency);
registerHandler('skip_shipment', cancelOrder);
registerHandler('pause_subscription', pauseSubscription);
registerHandler('pause_subscription_radio', pauseSubscriptionRadio);
registerHandler('send_payment_email', sendPaymentEmail);
registerHandler('change_renewal_behavior', changeRenewalBehavior);
registerHandler('upgrade_subscription_to_prepaid', upgradeSubscriptionToPrepaid);
registerHandler('upgrade_one_time_to_subscription', upgradeOneTimeToSubscription);
registerHandler('apply_discount_code_to_order', applyDiscountCodeToOrder);
