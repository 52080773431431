import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import unionBy from 'lodash/unionBy';
import merge from 'lodash/merge';

import {
  RECEIVE_CHANGE_ORDER_SHIPPING,
  RECEIVE_CHANGE_SHIPMENT_DATE,
  RECEIVE_ORDER,
  RECEIVE_ORDERS,
  RECEIVE_PAYMENT,
  RECEIVE_PAYMENTS,
  RECEIVE_SEND_NOW
} from '../constants';
import { indexBy } from '../utils';

dayjs.extend(customParseFormat);

const ccTypes = {
  1: 'Visa',
  2: 'Mastercard',
  3: 'American Express',
  4: 'Discover',
  5: 'Diners',
  6: 'JCB'
};

const ccTypeLogoMap = {
  1: 'visa.webp',
  2: 'mastercard.webp',
  3: 'amex.webp',
  4: 'discover.webp',
  5: 'diners.webp',
  6: 'jcb.webp'
};

const paymentMethodLogoMap = {
  applepay: 'applepay.webp',
  googlepay: 'googlepay.webp',
  paypal: 'paypal.webp',
  shoppay: 'shoppay.webp'
};

function getPaymentLogo(cc_type: number, payment_method: string) {
  let logoAsset = cc_type ? ccTypeLogoMap[cc_type] : paymentMethodLogoMap[payment_method];
  return logoAsset ? `/cc-logos/${logoAsset}` : null;
}

export const getPaymentIsExpired = diff => diff < 0;
export const getPaymentIsExpiring = diff => diff === 0;
export const getParsedExpDate = (str = '') => dayjs(str, 'MM/YYYY');
export const getCommonCcExpDate = (str = '') => {
  if (str === null) return null;
  return str.length === 7 ? str : `0${str}`;
};

export const getPaymentFlags = ccExpDate => {
  const now = dayjs();
  const parsedExpDate = getParsedExpDate(ccExpDate);
  const diff = parsedExpDate.diff(now, 'month');
  return {
    is_expired: getPaymentIsExpired(diff),
    is_expiring: getPaymentIsExpiring(diff)
  };
};

export const getPaymentWillBeExpired = (ccExpDate, placeDate) => {
  const parsedExpDate = getParsedExpDate(ccExpDate);
  const parsedPlaceDate = dayjs(placeDate, 'YYYY-MM-DD');
  const diff = parsedExpDate.diff(parsedPlaceDate, 'month');
  return diff < 0;
};

export const mergePaymentsOntoOrdersByPayment = (state, payload) => ({
  ...(state?.[payload.public_id]?.orders
    ? {
        orders: state[payload.public_id].orders.map(order => ({
          ...order,
          payment_will_be_expired: getPaymentWillBeExpired(payload.cc_exp_date, order.place)
        }))
      }
    : {})
});

export const mergeOrdersOntoPayments = (payments, orders) =>
  orders.reduce(
    (acc, order) => ({
      ...acc,
      [order.payment]: {
        ...acc[order.payment],
        orders: unionBy(
          [
            {
              public_id: order.public_id,
              ...(order.place && { place: order.place.substr(0, 10) }),
              ...(acc?.[order.payment]?.cc_exp_date && order.place
                ? {
                    payment_will_be_expired: getPaymentWillBeExpired(
                      payments[order.payment].cc_exp_date,
                      order.place.substr(0, 10)
                    )
                  }
                : {})
            }
          ],
          acc?.[order.payment]?.orders || [],
          'public_id'
        )
      }
    }),
    payments
  );

export const mapPayments = (state, results) =>
  merge(
    indexBy(
      results.map(payment => {
        const ccExpDate = getCommonCcExpDate(payment.cc_exp_date);
        return {
          ...payment,
          cc_type: ccTypes[payment.cc_type],
          cc_exp_date: ccExpDate,
          ...getPaymentFlags(ccExpDate),
          ...mergePaymentsOntoOrdersByPayment(state, payment),
          logo_url: getPaymentLogo(payment.cc_type, payment.payment_method)
        };
      }),
      'public_id'
    ),
    state
  );

export default function payment_by_id(state = {}, { type, payload }) {
  switch (type) {
    case RECEIVE_ORDERS:
      return mergeOrdersOntoPayments(state, payload.results);
    case RECEIVE_ORDER:
    case RECEIVE_SEND_NOW:
      return mergeOrdersOntoPayments(state, [payload]);
    case RECEIVE_CHANGE_ORDER_SHIPPING:
    case RECEIVE_CHANGE_SHIPMENT_DATE:
      return mergeOrdersOntoPayments(state, [payload.current]);
    case RECEIVE_PAYMENT:
      return mapPayments(state, [payload]);
    case RECEIVE_PAYMENTS:
      return mapPayments(state, payload.results);
    default:
      return state;
  }
}
