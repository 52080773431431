import { call, put, select } from 'redux-saga/effects';
import { OrderItem, State } from '..';
import { RECEIVE_CANCEL_SUBSCRIPTION } from '../constants';
import { lego_subscriptions_cancel } from './api';
import { delete_orphaned_items } from './helpers/delete-orphans';

export default function* REQUEST_CANCEL_SUBSCRIPTION({
  payload: { subscription_id, cancel_reason } = {}
}: {
  payload: { subscription_id?: string; cancel_reason?: string };
}) {
  const subscription = yield call(lego_subscriptions_cancel, subscription_id, cancel_reason);
  const { items_by_order } = (yield select()) as State;

  // construct list of orders that need to be refreshed in request-orders
  const multi_item_orders_with_sub = Array.from(
    new Set(
      Object.values(items_by_order)
        .flat()
        .filter(
          (order_item: OrderItem) =>
            order_item.subscription === subscription_id && items_by_order[order_item.order].length > 1
        )
        .map(order_item => order_item.order)
    )
  );

  // check orders if necessary for orphans
  if (multi_item_orders_with_sub.length) {
    yield call(delete_orphaned_items, multi_item_orders_with_sub, items_by_order, subscription_id);
  }

  yield put({
    type: RECEIVE_CANCEL_SUBSCRIPTION,
    payload: { subscription, refresh_orders: multi_item_orders_with_sub, cancel_reason }
  });
}
