const CHANGE_PRODUCT_ERRORS_MAP = {
  'Product should be prepaid eligible': 'change_product_prepaid_eligible_error',
  'Item from subscription should be from an order in unsent status': 'change_product_unsent_status_error',
  'Product price is higher than the current product price for free prepaid subscription':
    'change_product_higher_price_error',
  'Prepaid renewal subscription has other orders that needs to be placed before changing for a more expensive product':
    'change_product_orders_needs_placement_error'
};

export const getChangeProductErrorMessage = ({ error, defaultError }: { error: string; defaultError: string }) =>
  CHANGE_PRODUCT_ERRORS_MAP[error] || defaultError;
