import { all, call, delay, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import {
  RECEIVE_CHANGE_PRODUCT,
  RECEIVE_ORDER_ITEMS,
  REQUEST_ORDER_ITEMS,
  WAIT_FOR_REFRESH_ORDER_MS,
  ORDER_ITEMS_PAGE_SIZE,
  ORDER_ITEMS_INITIAL_DELAY,
  RECEIVE_UPGRADE_SUBSCRIPTION_TO_PREPAID,
  RECEIVE_UPGRADE_ONE_TIME_TO_SUBSCRIPTION,
  REQUEST_OFFERS,
  REQUEST_SUBSCRIPTION_DISCOUNT_CODES,
  RECEIVE_INITIAL_DATA,
  REPLACE_ORDER_ITEMS
} from '../constants';
import { OrderStatus } from '../reducers/orders';
import { lego_items_list } from './api';
import { request_all_of } from './request-all-of';
import { clearStatus } from './request-orders';

const getLoading = state => state.loading;
const getDiscountCodesEnabled = state => state.settings?.discount_codes_enabled;

export function* requestOrderItems({
  payload
}: {
  type: string;
  payload: {
    status: OrderStatus | OrderStatus[] | number | number[];
    // if true, replace the current order items state with the result of the API call instead of merging new orders in
    // this makes sure that canceled order items are removed from state
    replace_state?: boolean;
    [key: string]: any;
  };
}) {
  const { status, replace_state = false, ...extra } = payload;
  const result = yield call(
    request_all_of,
    lego_items_list,
    {
      status: clearStatus(status),
      ordering: 'order__place',
      ...extra
    },
    RECEIVE_ORDER_ITEMS,
    ORDER_ITEMS_PAGE_SIZE,
    ORDER_ITEMS_INITIAL_DELAY
  );

  const { results } = result;

  if (replace_state) {
    yield put({ type: REPLACE_ORDER_ITEMS, payload: { results } });
  }

  // To speed up initial load, don't dispatch additional actions until initial load is complete
  const loading = yield select(getLoading);

  if (loading) {
    yield take(RECEIVE_INITIAL_DATA);
  }

  const uniqueOneTimeProductIds = [...new Set(results.filter(item => !!item.one_time).map(item => item.product))];

  if (uniqueOneTimeProductIds.length) {
    yield put({ type: REQUEST_OFFERS, payload: { product_ids: uniqueOneTimeProductIds } });
  }

  const discountCodesEnabled = yield select(getDiscountCodesEnabled);

  if (discountCodesEnabled) {
    const uniqueSubscriptionIds = [
      ...new Set(results.filter(item => !!item.subscription).map(item => item.subscription))
    ];

    for (const subscriptionId of uniqueSubscriptionIds) {
      yield put({ type: REQUEST_SUBSCRIPTION_DISCOUNT_CODES, payload: subscriptionId });
    }
  }

  return result;
}

export function* watchForRequestOrderItems() {
  yield all([
    takeEvery(REQUEST_ORDER_ITEMS, requestOrderItems),
    takeEvery(
      [RECEIVE_CHANGE_PRODUCT, RECEIVE_UPGRADE_SUBSCRIPTION_TO_PREPAID, RECEIVE_UPGRADE_ONE_TIME_TO_SUBSCRIPTION],
      // after these actions, request order items associated with the subscription
      function* ({ payload: { subscription } }: { type: string; payload: any }) {
        yield fork(function* () {
          yield delay(WAIT_FOR_REFRESH_ORDER_MS);
          yield call(requestOrderItems, {
            type: REQUEST_ORDER_ITEMS,
            payload: { status: OrderStatus.UNSENT, subscription: subscription.public_id }
          });
        });
      }
    )
  ]);
}
