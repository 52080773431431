import requestDeleteItem from '../request-delete-item';
import { call } from 'redux-saga/effects';
import { State } from '../../types';

export function* delete_orphaned_items(
  orders_to_check: string[],
  items_by_order: State['items_by_order'],
  subscription_id: string
) {
  for (const order_id of orders_to_check) {
    const new_order_items = items_by_order[order_id].filter(order_item => order_item.subscription !== subscription_id);
    // check if there are any other subscriptions in this order
    const subs_in_new_order = new_order_items.some(
      order_item => order_item.subscription !== null && order_item.subscription !== undefined
    );

    if (subs_in_new_order) continue; // no orphaned items

    if (new_order_items.length === 0) continue; // no items remaining once we remove the subscription

    // delete all items in order since there are no subscriptions in the order
    for (const item of new_order_items) {
      yield call(requestDeleteItem, { payload: item.public_id });
    }
  }
}
