import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';
import uniq from 'lodash/uniq';
import { State } from '../types';

export const selectSubscription = memoize(subscription_id =>
  createSelector(
    (state: State) => state.subscriptions,
    subscriptions => subscriptions.find(subscription => subscription.public_id === subscription_id)
  )
);

export const selectOrderItemsByOrderId = memoize(order_id =>
  createSelector(
    (state: State) => state.items_by_order,
    items_by_order => items_by_order[order_id]
  )
);

export const selectOrderItemById = memoize(order_item_id =>
  createSelector(
    (state: State) => state.order_item_by_id,
    order_item_by_id => order_item_by_id && order_item_by_id[order_item_id]
  )
);

export const selectIsOnlyItemInShipment = memoize(order_item_id =>
  createSelector(
    selectOrderItemById(order_item_id),
    (state: State) => state.items_by_order,
    (orderItem: any, items_by_order) =>
      orderItem && items_by_order[orderItem.order] && items_by_order[orderItem.order].length === 1
  )
);

/**
 * returns a list of unique subscription id's in the order by querying each order_item
 */
export const subscriptionsByOrder = memoize(orderId =>
  createSelector(
    (state: State) => state.items_by_order,
    items_by_order =>
      uniq(
        (items_by_order[orderId] || []).reduce(
          (acc, item) => (item.subscription ? acc.concat(item.subscription) : acc),
          []
        )
      )
  )
);

/**
 * returns a list of unique order ids with the address ID
 */
export const ordersByAddressId = memoize(addressId =>
  createSelector(
    (state: State) => state.orders,
    orders =>
      orders
        .filter(order => order.status === 'UNSENT' && order.shipping_address === addressId)
        .map(order => order.public_id)
  )
);

export const selectOrderItemsBySubscriptionId = subscription_id =>
  createSelector(
    (state: State) => state.order_items,
    order_items =>
      order_items.filter(item => item.subscription === subscription_id).map(order_item => order_item.public_id)
  );

export const selectOrdersBySubscriptionId = subscription_id =>
  createSelector(
    selectOrderItemsBySubscriptionId(subscription_id),
    (state: State) => state.order_items,
    (orderItemIds, order_items) => {
      const orderItemById = order_items.reduce((acc, item) => {
        acc[item.public_id] = item;
        return acc;
      }, {});

      return [...new Set(orderItemIds.map(item_id => orderItemById?.[item_id]?.order))];
    }
  );
