import { call, put, takeEvery } from 'redux-saga/effects';

import { REQUEST_PRODUCTS_BY_GROUP, RECEIVE_PRODUCTS, REQUEST_PRODUCTS } from '../constants';
import { lego_products_list } from './api';

export function* requestProductsByGroup({
  payload: { name: group_name, group_type }
}: {
  type: string;
  payload: { name: string; group_type: string };
}) {
  yield put({ type: REQUEST_PRODUCTS, payload: { group_name } });
  const response = yield call(lego_products_list, {
    page_size: 100,
    group_name,
    group_type
  });
  yield put({ type: RECEIVE_PRODUCTS, payload: response });
}

export function* watchForRequestProductByGroup() {
  yield takeEvery(REQUEST_PRODUCTS_BY_GROUP, requestProductsByGroup);
}
