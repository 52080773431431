import requestDeleteItem from '@ordergroove/smi-store/sagas/request-delete-item';
import { sagaMiddleware } from '../core/store';
import { publicId, selectOrderItem } from './selectors';

export function submit(formData) {
  const item_id = formData.get('item');
  const task = sagaMiddleware.run(requestDeleteItem, {
    payload: publicId(item_id)
  });
  return task;
}

export function initialize(form) {
  const itemEl = form.querySelector('[name="item"]');
  if (!itemEl) {
    throw new Error('Missing <input name="item" value="{{ order_item.public_id }}">');
  }
}

/**
 *
 * @param {FormData} data
 * @param {Object} state
 * @returns
 */
export function validate(data, state) {
  const item_id = data.get('item');

  if (!item_id) return { valid: false, field: 'item', reason: 'INVALID_ITEM_ID' };

  const item = selectOrderItem(item_id)(state);

  if (typeof item === 'undefined') return { valid: false, field: 'item', reason: 'ITEM_NOT_FOUND' };

  return { valid: true };
}

export default {
  validate,
  initialize,
  submit
};
