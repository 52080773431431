import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import uniq from 'lodash/uniq';
import { lego_addresses_list, lego_addresses_get } from './api';
import { request_all_of } from './request-all-of';
import {
  REQUEST_ADDRESSES,
  RECEIVE_ADDRESSES,
  REQUEST_ADDRESS,
  RECEIVE_ADDRESS,
  RECEIVE_ORDERS,
  RECEIVE_ORDER
} from '../constants';
import { takeUnique } from './take-unique';
import { Address } from '..';

const getShippingAddress = order => order.shipping_address;

const requestAddress = id => {
  if (id) {
    return put({ type: REQUEST_ADDRESS, payload: id });
  }
};

export function* watchForRequestAddresses() {
  yield all([
    takeLatest(REQUEST_ADDRESSES, function* () {
      yield put({ type: RECEIVE_ADDRESSES, payload: yield call(request_all_of, lego_addresses_list, { live: true }) });
    }),
    takeUnique(REQUEST_ADDRESS, function* ({ payload }) {
      yield put({ type: RECEIVE_ADDRESS, payload: yield call(lego_addresses_get, payload) });
    }),
    takeEvery(RECEIVE_ORDERS, function* ({ payload }: { type: string; payload?: { results: Address[] } }) {
      yield all(uniq(payload.results.map(getShippingAddress)).map(requestAddress));
    }),
    takeEvery(RECEIVE_ORDER, function* ({ payload }: { type: string; payload?: Address }) {
      yield requestAddress(getShippingAddress(payload));
    })
  ]);
}
