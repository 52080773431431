import { put, take, race, delay, all } from 'redux-saga/effects';
import {
  RECEIVE_INITIAL_DATA,
  RECEIVE_INITIAL_DATA_ERROR,
  RECEIVE_INITIAL_DATA_TIMEOUT,
  RECEIVE_ORDERS,
  RECEIVE_SUBSCRIPTIONS,
  RECEIVE_UNEXPECTED_API_RESPONSE,
  REQUEST_INITIAL_DATA,
  REQUEST_ORDERS
} from '../constants';

export function* receiveInitialData({ delayLength = 20000 } = {}) {
  yield put({ type: REQUEST_INITIAL_DATA });
  // wait for orders to be requested before starting the data loading timeout
  yield take(REQUEST_ORDERS);

  const { initialData, apiError } = yield race({
    initialData: all([take(RECEIVE_SUBSCRIPTIONS), take(RECEIVE_ORDERS)]),
    apiError: take(RECEIVE_UNEXPECTED_API_RESPONSE),
    timeout: delay(delayLength)
  });

  if (initialData) yield put({ type: RECEIVE_INITIAL_DATA });
  else if (apiError) yield put({ type: RECEIVE_INITIAL_DATA_ERROR });
  else yield put({ type: RECEIVE_INITIAL_DATA_TIMEOUT });
}
