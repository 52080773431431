export const OPTIN_PRODUCT = 'OPTIN_PRODUCT';
export const OPTOUT_PRODUCT = 'OPTOUT_PRODUCT';
export const PRODUCT_CHANGE_FREQUENCY = 'PRODUCT_CHANGE_FREQUENCY';
export const SET_MERCHANT_ID = 'SET_MERCHANT_ID';
export const REQUEST_OFFER = 'REQUEST_OFFER';
export const RECEIVE_OFFER = 'RECEIVE_OFFER';
export const REQUEST_OFFERS = 'REQUEST_OFFERS';
export const RECEIVE_OFFERS = 'RECEIVE_OFFERS';
export const PRODUCT_HAS_CHANGED = 'PRODUCT_HAS_CHANGED';
export const CREATED_SESSION_ID = 'CREATED_SESSION_ID';
export const SET_AUTH_URL = 'SET_AUTH_URL';

export const REQUEST_AUTH = 'REQUEST_AUTH';
export const AUTHORIZE = 'AUTHORIZE';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const REQUEST_ORDER = 'REQUEST_ORDER';
export const RECEIVE_ORDER = 'RECEIVE_ORDER';
export const REQUEST_ORDERS = 'REQUEST_ORDERS';
export const RECEIVE_ORDERS = 'RECEIVE_ORDERS';
export const REPLACE_ORDERS = 'REPLACE_ORDERS';
export const COMPLETE_ORDERS_REQUEST = 'COMPLETE_ORDERS_REQUEST';
export const REQUEST_ADDRESSES = 'REQUEST_ADDRESSES';
export const RECEIVE_ADDRESSES = 'RECEIVE_ADDRESSES';
export const REQUEST_ADDRESS = 'REQUEST_ADDRESS';
export const RECEIVE_ADDRESS = 'RECEIVE_ADDRESS';
export const REQUEST_PAYMENT = 'REQUEST_PAYMENT';
export const RECEIVE_PAYMENT = 'RECEIVE_PAYMENT';
export const REQUEST_PAYMENTS = 'REQUEST_PAYMENTS';
export const RECEIVE_PAYMENTS = 'RECEIVE_PAYMENTS';
export const REQUEST_SHOP_SETTINGS = 'REQUEST_SHOP_SETTINGS';

export const CHANGE_SHIPPING_ADDRESS = 'CHANGE_SHIPPING_ADDRESS';
export const REQUEST_CHANGE_SHIPPING_ADDRESS = 'REQUEST_CHANGE_SHIPPING_ADDRESS';
export const RECEIVE_CHANGE_SHIPPING_ADDRESS = 'RECEIVE_CHANGE_SHIPPING_ADDRESS';
export const ERROR_CHANGE_SHIPPING_ADDRESS = 'ERROR_CHANGE_SHIPPING_ADDRESS';
export const ERROR_CHANGE_ORDER_ADDRESS_ID = 'ERROR_CHANGE_ORDER_ADDRESS_ID';

export const RECEIVE_DELETE_SHIPPING_ADDRESS = 'RECEIVE_DELETE_SHIPPING_ADDRESS';
export const ERROR_DELETE_SHIPPING_ADDRESS = 'ERROR_DELETE_SHIPPING_ADDRESS';
export const ERROR_SHIPPING_ADDRESS_IS_IN_USE = 'ERROR_SHIPPING_ADDRESS_IS_IN_USE';

export const REQUEST_CREATE_SHIPPING_ADDRESS = 'REQUEST_CREATE_SHIPPING_ADDRESS';
export const RECEIVE_CREATE_SHIPPING_ADDRESS = 'RECEIVE_CREATE_SHIPPING_ADDRESS';
export const RECEIVE_USE_FOR_ALL = 'RECEIVE_USE_FOR_ALL';
export const RECEIVE_EDIT_SHIPPING_ADDRESS = 'RECEIVE_EDIT_SHIPPING_ADDRESS';

export const RECEIVE_ORDER_ITEMS = 'RECEIVE_ORDER_ITEMS';
export const REPLACE_ORDER_ITEMS = 'REPLACE_ORDER_ITEMS';
export const FETCH_RESPONSE_ERROR = 'FETCH_RESPONSE_ERROR';
export const SET_ENVIRONMENT_STAGING = 'SET_ENVIRONMENT_STAGING';
export const SET_ENVIRONMENT_DEV = 'SET_ENVIRONMENT_DEV';
export const SET_ENVIRONMENT_PROD = 'SET_ENVIRONMENT_PROD';
export const CONCLUDE_UPSELL = 'CONCLUDE_UPSELL';
export const REQUEST_CREATE_IU_ORDER = 'REQUEST_CREATE_IU_ORDER';
export const CREATE_ONE_TIME = 'CREATE_ONE_TIME';
export const REQUEST_CONVERT_ONE_TIME = 'REQUEST_CONVERT_ONE_TIME';
export const CONVERT_ONE_TIME = 'CONVERT_ONE_TIME';
export const NO_UPCOMING_ORDER_ERROR = 'NO_UPCOMING_ORDER_ERROR';
export const CHECKOUT = 'CHECKOUT';
export const RECEIVE_FETCH = 'RECEIVE_FETCH';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_CORE_SETTINGS = 'SET_CORE_SETTINGS';
export const SET_PREVIEW_STANDARD_OFFER = 'SET_PREVIEW_STANDARD_OFFER';
export const SET_PREVIEW_UPSELL_OFFER = 'SET_PREVIEW_UPSELL_OFFER';
export const ADD_TEMPLATE = 'ADD_TEMPLATE';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const LOCAL_STORAGE_CHANGE = 'LOCAL_STORAGE_CHANGE';
export const LOCAL_STORAGE_CLEAR = 'LOCAL_STORAGE_CLEAR';
export const SET_FIRST_ORDER_PLACE_DATE = 'SET_FIRST_ORDER_PLACE_DATE';
export const SET_PRODUCT_TO_SUBSCRIBE = 'SET_PRODUCT_TO_SUBSCRIBE';
export const REQUEST_PRODUCT = 'REQUEST_PRODUCT';
export const RECEIVE_PRODUCT = 'RECEIVE_PRODUCT';
export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const REQUEST_PRODUCTS_BY_GROUP = 'REQUEST_PRODUCTS_BY_GROUP';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const REQUEST_PREPAID_INCENTIVES = 'REQUEST_PREPAID_INCENTIVES';
export const RECEIVE_PREPAID_INCENTIVES = 'RECEIVE_PREPAID_INCENTIVES';
export const REQUEST_ORDER_ITEMS = 'REQUEST_ORDER_ITEMS';
export const RECEIVE_TRANSLATIONS = 'RECEIVE_TRANSLATIONS';
export const REQUEST_SUBSCRIPTIONS = 'REQUEST_SUBSCRIPTIONS';
export const RECEIVE_SUBSCRIPTIONS = 'RECEIVE_SUBSCRIPTIONS';
export const REQUEST_PAUSE_SUBSCRIPTION = 'REQUEST_PAUSE_SUBSCRIPTION';
export const RECEIVE_PAUSE_SUBSCRIPTION = 'RECEIVE_PAUSE_SUBSCRIPTION';
export const ERROR_PAUSE_SUBSCRIPTION = 'ERROR_PAUSE_SUBSCRIPTION';
export const REQUEST_SUBSCRIPTION_DISCOUNT_CODES = 'REQUEST_SUBSCRIPTION_DISCOUNT_CODES';
export const RECEIVE_SUBSCRIPTION_DISCOUNT_CODES = 'RECEIVE_SUBSCRIPTION_DISCOUNT_CODES';
export const REQUEST_SEND_NOW = 'REQUEST_SEND_NOW';
export const RECEIVE_SEND_NOW = 'RECEIVE_SEND_NOW';
export const ERROR_SEND_NOW = 'ERROR_SEND_NOW';

export const REQUEST_ORDER_CANCEL = 'REQUEST_ORDER_CANCEL';
export const RECEIVE_ORDER_CANCEL = 'RECEIVE_ORDER_CANCEL';
export const ERROR_ORDER_CANCEL = 'ERROR_ORDER_CANCEL';

export const REQUEST_CHANGE_SHIPMENT_DATE = 'REQUEST_CHANGE_SHIPMENT_DATE';
export const RECEIVE_CHANGE_SHIPMENT_DATE = 'RECEIVE_CHANGE_SHIPMENT_DATE';
export const ERROR_CHANGE_SHIPMENT_DATE = 'ERROR_CHANGE_SHIPMENT_DATE';

export const TOAST_SUCCESS = 'TOAST_SUCCESS';
export const TOAST_ERROR = 'TOAST_ERROR';

export const REQUEST_REACTIVATE_SUBSCRIPTION = 'REQUEST_REACTIVATE_SUBSCRIPTION';
export const RECEIVE_REACTIVATE_SUBSCRIPTION = 'RECEIVE_REACTIVATE_SUBSCRIPTION';
export const ERROR_REACTIVATE_SUBSCRIPTION = 'ERROR_REACTIVATE_SUBSCRIPTION';

export const REQUEST_CANCEL_SUBSCRIPTION = 'REQUEST_CANCEL_SUBSCRIPTION';
export const RECEIVE_CANCEL_SUBSCRIPTION = 'RECEIVE_CANCEL_SUBSCRIPTION';
export const ERROR_CANCEL_SUBSCRIPTION = 'ERROR_CANCEL_SUBSCRIPTION';

export const REQUEST_CHANGE_ORDER_SHIPPING = 'REQUEST_CHANGE_ORDER_SHIPPING';
export const RECEIVE_CHANGE_ORDER_SHIPPING = 'RECEIVE_CHANGE_ORDER_SHIPPING';

export const REQUEST_CHANGE_SUBSCRIPTION_SHIPPING = 'REQUEST_CHANGE_SUBSCRIPTION_SHIPPING';
export const RECEIVE_CHANGE_SUBSCRIPTION_SHIPPING = 'RECEIVE_CHANGE_SUBSCRIPTION_SHIPPING';
export const REQUEST_CHANGE_SUBSCRIPTION_FREQUENCY = 'REQUEST_CHANGE_SUBSCRIPTION_FREQUENCY';
export const RECEIVE_CHANGE_SUBSCRIPTION_FREQUENCY = 'RECEIVE_CHANGE_SUBSCRIPTION_FREQUENCY';
export const ERROR_CHANGE_SUBSCRIPTION_FREQUENCY = 'ERROR_CHANGE_SUBSCRIPTION_FREQUENCY';

export const REQUEST_CHANGE_SUBSCRIPTION_RENEWAL_BEHAVIOR = 'REQUEST_CHANGE_SUBSCRIPTION_RENEWAL_BEHAVIOR';
export const RECEIVE_CHANGE_SUBSCRIPTION_RENEWAL_BEHAVIOR = 'RECEIVE_CHANGE_SUBSCRIPTION_RENEWAL_BEHAVIOR';
export const ERROR_CHANGE_SUBSCRIPTION_RENEWAL_BEHAVIOR = 'ERROR_CHANGE_RENEWAL_BEHAVIOR';

export const RECEIVE_UPGRADE_SUBSCRIPTION_TO_PREPAID = 'RECEIVE_UPGRADE_SUBSCRIPTION_TO_PREPAID';
export const REQUEST_UPGRADE_SUBSCRIPTION_TO_PREPAID = 'REQUEST_UPGRADE_SUBSCRIPTION_TO_PREPAID';
export const ERROR_UPGRADE_SUBSCRIPTION_TO_PREPAID = 'ERROR_UPGRADE_SUBSCRIPTION_TO_PREPAID';

export const RECEIVE_UPGRADE_ONE_TIME_TO_SUBSCRIPTION = 'RECEIVE_UPGRADE_ONE_TIME_TO_SUBSCRIPTION';
export const REQUEST_UPGRADE_ONE_TIME_TO_SUBSCRIPTION = 'REQUEST_UPGRADE_ONE_TIME_TO_SUBSCRIPTION';
export const ERROR_UPGRADE_ONE_TIME_TO_SUBSCRIPTION = 'ERROR_UPGRADE_ONE_TIME_TO_SUBSCRIPTION';

export const REQUEST_CHANGE_SUBSCRIPTION_QUANTITY = 'REQUEST_CHANGE_SUBSCRIPTION_QUANTITY';
export const RECEIVE_CHANGE_SUBSCRIPTION_QUANTITY = 'RECEIVE_CHANGE_SUBSCRIPTION_QUANTITY';
export const ERROR_CHANGE_SUBSCRIPTION_QUANTITY = 'ERROR_CHANGE_SUBSCRIPTION_QUANTITY';

export const REQUEST_CHANGE_ITEM_QUANTITY = 'REQUEST_CHANGE_ITEM_QUANTITY';
export const RECEIVE_CHANGE_ITEM_QUANTITY = 'RECEIVE_CHANGE_ITEM_QUANTITY';
export const ERROR_CHANGE_ITEM_QUANTITY = 'ERROR_CHANGE_ITEM_QUANTITY';

export const REQUEST_CHANGE_PRODUCT = 'REQUEST_CHANGE_PRODUCT';
export const RECEIVE_CHANGE_PRODUCT = 'RECEIVE_CHANGE_PRODUCT';
export const ERROR_CHANGE_PRODUCT = 'ERROR_CHANGE_PRODUCT';

export const REQUEST_DELETE_ITEM = 'REQUEST_DELETE_ITEM';
export const RECEIVE_DELETE_ITEM = 'RECEIVE_DELETE_ITEM';
export const ERROR_DELETE_ITEM = 'ERROR_DELETE_ITEM';

export const REQUEST_INITIAL_DATA = 'REQUEST_INITIAL_DATA';
export const RECEIVE_INITIAL_DATA = 'RECEIVE_INITIAL_DATA';
export const RECEIVE_INITIAL_DATA_ERROR = 'RECEIVE_INITIAL_DATA_ERROR';
export const RECEIVE_INITIAL_DATA_TIMEOUT = 'RECEIVE_INITIAL_DATA_TIMEOUT';
export const RECEIVE_UNEXPECTED_API_RESPONSE = 'RECEIVE_UNEXPECTED_API_RESPONSE';

export const RECEIVE_SEND_PAYMENT_EMAIL = 'RECEIVE_SEND_PAYMENT_EMAIL';
export const ERROR_SEND_PAYMENT_EMAIL = 'ERROR_SEND_PAYMENT_EMAIL';

export const SM_LOAD_START = 'SM_LOAD_START';
export const SKU_SWAP_DIALOG_TRIGGER_CLICKED = 'SKU_SWAP_DIALOG_TRIGGER_CLICKED';
export const SKU_SWAP_DIALOG_CLOSED = 'SKU_SWAP_DIALOG_CLOSED';

export const CANCEL_DIALOG_TRIGGER_CLICKED = 'CANCEL_DIALOG_TRIGGER_CLICKED';
export const CANCEL_DIALOG_CLOSED = 'CANCEL_DIALOG_CLOSED';
export const CANCEL_SAVED_BY_PAUSE = 'CANCEL_SAVED_BY_PAUSE';
export const CANCEL_SAVED_BY_SKIP = 'CANCEL_SAVED_BY_SKIP';

export const RECEIVE_APPLY_DISCOUNT_CODE_TO_ORDER = 'RECEIVE_APPLY_DISCOUNT_CODE_TO_ORDER';
export const ERROR_DISCOUNT_CODE_NOT_APPLICABLE = 'ERROR_DISCOUNT_CODE_NOT_APPLICABLE';
export const ERROR_APPLY_DISCOUNT_CODE_TO_ORDER = 'ERROR_APPLY_DISCOUNT_CODE_TO_ORDER';

export const DEFAULT_PAGE_SIZE = 100;

export const ORDERS_PAGE_SIZE = 10;
export const ORDERS_INITIAL_DELAY = 500;
export const WAIT_FOR_REFRESH_ORDER_MS = 500;

export const ORDER_ITEMS_PAGE_SIZE = 100;
export const ORDER_ITEMS_INITIAL_DELAY = 500;
