import changeOrderAddressId from '@ordergroove/smi-store/sagas/request-change-order-address-id';

import { sagaMiddleware } from '../core/store';

export function initialize(form) {
  const customerEl = form.querySelector('[name="order_id"]');
  if (!customerEl) {
    throw new Error('Missing <input name="order_id" value="{{ order.public_id }}">');
  }
}

/**
 *
 * @param {FormData} data
 * @param {Object} state
 * @returns
 */
export function validate(data, state, form) {
  return { valid: form.checkValidity() };
}

export function submit(formData) {
  const task = sagaMiddleware.run(changeOrderAddressId, {
    payload: {
      order_id: formData.get('order_id'),
      address_id: formData.get('address_id')
    }
  });
  return task;
}

export default {
  validate,
  initialize,
  submit
};
