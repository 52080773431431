import differenceBy from 'lodash/differenceBy';
import unionBy from 'lodash/unionBy';
import {
  RECEIVE_CANCEL_SUBSCRIPTION,
  RECEIVE_CHANGE_PRODUCT,
  RECEIVE_REACTIVATE_SUBSCRIPTION,
  RECEIVE_SUBSCRIPTIONS,
  RECEIVE_CHANGE_SUBSCRIPTION_QUANTITY,
  RECEIVE_CHANGE_SUBSCRIPTION_FREQUENCY,
  RECEIVE_USE_FOR_ALL,
  RECEIVE_CHANGE_SUBSCRIPTION_RENEWAL_BEHAVIOR,
  RECEIVE_UPGRADE_SUBSCRIPTION_TO_PREPAID,
  RECEIVE_UPGRADE_ONE_TIME_TO_SUBSCRIPTION
} from '../constants';
import { Subscription } from '../types';
import { isMultiItemBundleComponentSubscription } from './order_items_reducer';

function mapSubscription(subscription: Subscription) {
  return {
    ...subscription,
    can_change_quantity: !isMultiItemBundleComponentSubscription(subscription)
  };
}

export default function subscriptions(state = [], { type, payload }) {
  switch (type) {
    case RECEIVE_SUBSCRIPTIONS:
      return unionBy(payload.results.map(mapSubscription), state, 'public_id');
    case RECEIVE_REACTIVATE_SUBSCRIPTION:
    case RECEIVE_CANCEL_SUBSCRIPTION:
    case RECEIVE_CHANGE_SUBSCRIPTION_QUANTITY:
    case RECEIVE_CHANGE_SUBSCRIPTION_FREQUENCY:
    case RECEIVE_CHANGE_SUBSCRIPTION_RENEWAL_BEHAVIOR:
    case RECEIVE_UPGRADE_SUBSCRIPTION_TO_PREPAID:
    case RECEIVE_UPGRADE_ONE_TIME_TO_SUBSCRIPTION:
      return unionBy([mapSubscription(payload.subscription)], state, 'public_id');
    case RECEIVE_CHANGE_PRODUCT:
      return [mapSubscription(payload.subscription), ...differenceBy(state, [payload.subscription], 'public_id')];
    case RECEIVE_USE_FOR_ALL:
      return state.map(subscription => {
        return {
          ...subscription,
          shipping_address: payload.public_id
        };
      });
    default:
      return state;
  }
}
