import { call, put, select, take, takeEvery } from 'redux-saga/effects';
import { offers_offers_get } from './api';
import { RECEIVE_OFFERS, REQUEST_OFFERS, RECEIVE_PREPAID_INCENTIVES, SET_MERCHANT_ID } from '../constants';

const getMerchantId = state => state.merchant_id;
const getPrepaidIncentives = state => state.prepaid_incentives;
const getSessionId = state => state.session_id;

export default function* requestOffers({
  payload
}: {
  type: string;
  payload?: {
    merchant_id?: string;
    module?: string | 'pdp';
    session_id?: string;
    page_type?: number | 5;
    product_ids: string | string[];
    module_view?: string | string[] | ['regular'];
  };
}) {
  const {
    merchant_id = yield select(getMerchantId),
    module = 'pdp',
    session_id = yield select(getSessionId),
    page_type = 5,
    product_ids,
    module_view = ['regular'],
    ...extra
  } = payload || {};

  const results = yield call(
    offers_offers_get,
    {
      session_id,
      page_type,
      p: JSON.stringify(Array.isArray(product_ids) ? product_ids : [product_ids]),
      module_view: JSON.stringify(Array.isArray(module_view) ? module_view : [module_view]),
      ...extra
    },
    merchant_id,
    module
  );

  const prepaid_incentives = yield select(getPrepaidIncentives);

  yield put({ type: RECEIVE_OFFERS, payload: { ...results, prepaid_incentives } });
}

export function* watchForRequestOffers() {
  yield takeEvery(REQUEST_OFFERS, function* (action) {
    const merchant_id = yield select(getMerchantId);
    const prepaid_incentives = yield select(getPrepaidIncentives);
    if (!merchant_id) {
      yield take(SET_MERCHANT_ID);
    }
    if (!Object.keys(prepaid_incentives?.derived || {}).length) {
      yield take(RECEIVE_PREPAID_INCENTIVES);
    }
    yield call(requestOffers, action);
  });
}
