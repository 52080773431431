import { call, put } from 'redux-saga/effects';

import { RECEIVE_CHANGE_SHIPMENT_DATE } from '../constants';
import { lego_orders_change_place_date } from './api';

export default function* REQUEST_CHANGE_SHIPMENT_DATE({
  payload: { order_id, date } = {}
}: {
  payload: { order_id?: string; date?: string };
}) {
  const response = yield call(lego_orders_change_place_date, order_id, `${date}T00:00:00.000Z`);
  yield put({
    type: RECEIVE_CHANGE_SHIPMENT_DATE,
    payload: { current: response, previous_order_id: order_id }
  });
}
