import { call, put, takeEvery, all, delay, fork, select } from 'redux-saga/effects';

import unionBy from 'lodash/unionBy';
import { request_all_of } from './request-all-of';

import {
  RECEIVE_ORDERS,
  REQUEST_ORDERS,
  COMPLETE_ORDERS_REQUEST,
  RECEIVE_ORDER,
  REQUEST_ORDER,
  REQUEST_ORDER_ITEMS,
  RECEIVE_SEND_NOW,
  RECEIVE_CANCEL_SUBSCRIPTION,
  RECEIVE_CHANGE_PRODUCT,
  RECEIVE_REACTIVATE_SUBSCRIPTION,
  RECEIVE_ORDER_CANCEL,
  RECEIVE_DELETE_ITEM,
  RECEIVE_PAUSE_SUBSCRIPTION,
  RECEIVE_CHANGE_SUBSCRIPTION_QUANTITY,
  RECEIVE_CHANGE_ITEM_QUANTITY,
  ORDERS_PAGE_SIZE,
  ORDERS_INITIAL_DELAY,
  WAIT_FOR_REFRESH_ORDER_MS,
  RECEIVE_CHANGE_SUBSCRIPTION_RENEWAL_BEHAVIOR,
  RECEIVE_UPGRADE_SUBSCRIPTION_TO_PREPAID,
  RECEIVE_UPGRADE_ONE_TIME_TO_SUBSCRIPTION,
  RECEIVE_USE_FOR_ALL,
  RECEIVE_APPLY_DISCOUNT_CODE_TO_ORDER,
  REPLACE_ORDERS
} from '../constants';
import { lego_orders_get, lego_orders_list } from './api';
import { OrderStatus, CompleteOrdersRequestPayload } from '../reducers/orders';

export const clearStatus = status => {
  const statusArray = Array.isArray(status) ? status : [status];
  return statusArray.map(orderStatusToString);
};

const orderStatusToString = it => {
  if (Number.isNaN(parseInt(it, 10))) {
    return OrderStatus[it];
  }
  return `${it}`.toUpperCase();
};

export function* requestOrders({
  payload
}: {
  type: string;
  payload?: {
    status: OrderStatus | OrderStatus[] | number | number[];
    ordering: string | 'place';
    // if true, replace the current orders state with the result of the API call instead of merging new orders in
    // this makes sure that canceled orders are removed from state
    replace_state?: boolean;
  };
}) {
  const { status, ordering, replace_state = false, ...extra } = payload;
  const statusArray = clearStatus(status);
  const filters = {
    status: statusArray,
    ordering: 'place',
    ...extra
  };
  const result = yield call(
    request_all_of,
    lego_orders_list,
    filters,
    RECEIVE_ORDERS,
    ORDERS_PAGE_SIZE,
    ORDERS_INITIAL_DELAY
  );

  const { results } = result;

  if (replace_state) {
    yield put({ type: REPLACE_ORDERS, payload: { results } });
  }

  const completeOrdersPayload: CompleteOrdersRequestPayload = {
    results,
    filters
  };
  yield put({
    type: COMPLETE_ORDERS_REQUEST,
    payload: completeOrdersPayload
  });

  return result;
}

export function* requestOrder({ payload: order_id }: { type: string; payload: any }) {
  try {
    const payload = yield call(lego_orders_get, order_id);

    yield put({
      type: RECEIVE_ORDER,
      payload
    });
  } catch (err) {
    if (err.status !== 404) {
      throw err;
    }
  }
}

export function* requestOrdersToUpdateOrder({ payload: { order_id } }) {
  const response = yield call<typeof request_all_of>(
    request_all_of,
    lego_orders_list,
    { ordering: 'place', status: OrderStatus.UNSENT },
    null,
    ORDERS_PAGE_SIZE,
    ORDERS_INITIAL_DELAY
  );
  const identifyOrder = ({ public_id }) => public_id === order_id;
  const { orders } = yield select();
  const order = orders.find(identifyOrder);
  const newOrder = response.results.find(identifyOrder) || { ...order, status: OrderStatus.CANCELLED };

  yield put({
    type: RECEIVE_ORDERS,
    payload: { ...response, results: unionBy(response.results, [newOrder], 'public_id') }
  });
}

export function* watchForRequestOrders() {
  yield all([
    takeEvery(REQUEST_ORDERS, requestOrders),
    takeEvery(REQUEST_ORDER, requestOrder),
    takeEvery(
      [
        RECEIVE_CANCEL_SUBSCRIPTION,
        RECEIVE_CHANGE_PRODUCT,
        RECEIVE_CHANGE_SUBSCRIPTION_RENEWAL_BEHAVIOR,
        RECEIVE_UPGRADE_SUBSCRIPTION_TO_PREPAID,
        RECEIVE_UPGRADE_ONE_TIME_TO_SUBSCRIPTION
      ],
      // after these actions, request the orders given in the payload
      function* ({ payload: { refresh_orders } }: { type: string; payload: any }) {
        yield fork(function* () {
          yield delay(WAIT_FOR_REFRESH_ORDER_MS);
          const ordersRequest = Array.from(refresh_orders).map(payload =>
            call<typeof requestOrder>(requestOrder, { type: REQUEST_ORDER, payload })
          );

          if (ordersRequest.length) yield all(ordersRequest);
        });
      }
    ),
    takeEvery(
      [
        RECEIVE_SEND_NOW,
        RECEIVE_ORDER_CANCEL,
        RECEIVE_REACTIVATE_SUBSCRIPTION,
        RECEIVE_CHANGE_SUBSCRIPTION_QUANTITY,
        RECEIVE_CHANGE_ITEM_QUANTITY,
        // when a shipping address is marked "use for all", orders could potentially merge since they all have the same address
        // however, the use for all response doesn't let us derive which orders were merged (like changing the shipment date does), so we need to request all orders and items to update the state properly
        RECEIVE_USE_FOR_ALL,
        // ideally we would only refresh the order that the discount code applied to
        // however, a current limitation is that order items with discount codes applied need to be split out from the other items in the order
        // so we need to request all orders in case the order was split
        RECEIVE_APPLY_DISCOUNT_CODE_TO_ORDER
      ],
      // after these actions, request all orders and order items
      function* () {
        yield fork(function* () {
          yield delay(WAIT_FOR_REFRESH_ORDER_MS);
          yield all([
            put({ type: REQUEST_ORDERS, payload: { status: OrderStatus.UNSENT, ordering: 'place' } }),
            put({ type: REQUEST_ORDER_ITEMS, payload: { status: OrderStatus.UNSENT, ordering: 'place' } })
          ]);
        });
      }
    ),
    takeEvery(
      [RECEIVE_PAUSE_SUBSCRIPTION, RECEIVE_DELETE_ITEM],
      // after these actions, request all orders but only update state with a single one of those orders
      // also request all order items
      function* ({ payload }: { type: string; payload: any }) {
        yield fork(function* () {
          yield delay(WAIT_FOR_REFRESH_ORDER_MS);

          yield all([
            call(requestOrdersToUpdateOrder, { payload }),
            put({ type: REQUEST_ORDER_ITEMS, payload: { status: OrderStatus.UNSENT, ordering: 'place' } })
          ]);
        });
      }
    )
  ]);
}
