import { RECEIVE_PRODUCT, RECEIVE_PRODUCTS } from '../constants';

import { parseOrNull, indexBy, isPrepaidEligibilityGroup } from '../utils';

const mapProduct = ({ name, image_url, detail_url, groups, ...product }) => ({
  name,
  image_url,
  detail_url,
  groups,
  ...product,
  initial_i18n: { name, image_url, detail_url },
  is_prepaid_eligible: isPrepaidEligibleProduct(groups),
  extra_data: parseOrNull(product.extra_data)
});

const isPrepaidEligibleProduct = groups => groups?.some(isPrepaidEligibilityGroup) ?? false;

export default function product_by_id(state = {}, action) {
  if (action.type === RECEIVE_PRODUCT)
    return {
      ...state,
      [action.payload.external_product_id]: mapProduct(action.payload)
    };

  if (action.type === RECEIVE_PRODUCTS) {
    return {
      ...state,
      ...indexBy(action.payload.results.map(mapProduct), 'external_product_id')
    };
  }
  return state;
}
