import { put, call, all, select } from 'redux-saga/effects';
import {
  RECEIVE_CREATE_SHIPPING_ADDRESS,
  RECEIVE_CHANGE_SHIPPING_ADDRESS,
  RECEIVE_USE_FOR_ALL,
  RECEIVE_CHANGE_ORDER_SHIPPING,
  RECEIVE_EDIT_SHIPPING_ADDRESS
} from '../constants';
import { subscriptionsByOrder, ordersByAddressId } from '../selectors';
import {
  lego_addresses_create,
  lego_addresses_use_for_all,
  lego_orders_change_shipping,
  lego_subscriptions_change_shipping,
  lego_addresses_edit
} from './api';

export default function* REQUEST_CHANGE_SHIPPING_ADDRESS({
  payload: { order_id, use_for_all, prev_address_id, ...address_payload }
}) {
  const address = yield call(lego_addresses_create, address_payload);

  // update addresses in store
  if (prev_address_id) {
    // mark previous address as not live
    yield call(lego_addresses_edit, prev_address_id, { live: false });
    yield put({
      type: RECEIVE_EDIT_SHIPPING_ADDRESS,
      payload: { old_public_id: prev_address_id, new_address: address }
    });
  } else {
    yield put({ type: RECEIVE_CREATE_SHIPPING_ADDRESS, payload: address });
  }

  // get subscriptions and orders to change
  let subscriptions_ids = [];
  let order_ids = [];
  // when editing, change subs related to previous address ID
  // when creating, change subs related to current order ID
  if (!prev_address_id) {
    order_ids = [order_id];
    subscriptions_ids = yield select(subscriptionsByOrder(order_id));
  } else {
    order_ids = yield select(ordersByAddressId(prev_address_id));
    for (const order_id of order_ids) {
      const subs = yield select(subscriptionsByOrder(order_id));
      subscriptions_ids = subscriptions_ids.concat(subs);
    }
  }

  const shipping_address_id = address.public_id;

  if (use_for_all) {
    yield call(lego_addresses_use_for_all, shipping_address_id);
    yield put({
      type: RECEIVE_USE_FOR_ALL,
      payload: address
    });
  } else {
    // perform update on orders and subscriptions
    // if applying to all, the use_for_all endpoint will handle it
    yield all(order_ids.map(order_id => call(handleUpdateOrderShipping, order_id, shipping_address_id)));

    if (subscriptions_ids.length) {
      yield all(
        subscriptions_ids.map(subscription_id =>
          call(lego_subscriptions_change_shipping, subscription_id, shipping_address_id)
        )
      );
    }
  }

  yield put({ type: RECEIVE_CHANGE_SHIPPING_ADDRESS, payload: { address, use_for_all } });
}

function* handleUpdateOrderShipping(order_id: string, shipping_address_id: string) {
  const response = yield call(lego_orders_change_shipping, order_id, shipping_address_id);
  yield put({
    type: RECEIVE_CHANGE_ORDER_SHIPPING,
    payload: {
      current: response,
      previous_order_id: order_id
    }
  });
}
