import { call, put, takeEvery } from 'redux-saga/effects';

import { RECEIVE_SUBSCRIPTION_DISCOUNT_CODES, REQUEST_SUBSCRIPTION_DISCOUNT_CODES } from '../constants';
import { lego_subscription_get_discount_codes } from './api';

export default function* requestSubscriptionDiscountCodes({
  payload: subscription_id
}: {
  type?: string;
  payload?: string;
}) {
  const results = yield call(lego_subscription_get_discount_codes, subscription_id);

  if (results.error) {
    return;
  }

  yield put({
    type: RECEIVE_SUBSCRIPTION_DISCOUNT_CODES,
    payload: {
      results,
      subscription_id
    }
  });
}

export function* watchForRequestSubscriptionDiscountCodes() {
  yield takeEvery(REQUEST_SUBSCRIPTION_DISCOUNT_CODES, function* (action) {
    yield call(requestSubscriptionDiscountCodes, action);
  });
}
