import { RECEIVE_INITIAL_DATA_TIMEOUT, UNAUTHORIZED } from '../constants';

export default function global_error(state = false, action) {
  switch (action.type) {
    case RECEIVE_INITIAL_DATA_TIMEOUT:
    case UNAUTHORIZED:
      return true;
    default:
      return state;
  }
}
