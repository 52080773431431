import { call, put } from 'redux-saga/effects';

import { ERROR_CHANGE_SHIPMENT_DATE, RECEIVE_REACTIVATE_SUBSCRIPTION } from '../constants';
import { lego_subscriptions_reactivate, lego_orders_list, lego_orders_change_place_date } from './api';

export default function* REQUEST_REACTIVATE_SUBSCRIPTION({
  payload: { subscription_id, every, every_period, start_date, first_order_date }
}: {
  payload: {
    subscription_id?: string;
    every?: string;
    every_period?: string;
    start_date?: string;
    first_order_date?: string;
  };
}) {
  const subscription = yield call(lego_subscriptions_reactivate, subscription_id, start_date, every, every_period);

  // '24 template lets user choose the first order date
  if (first_order_date) {
    try {
      const orders_list = yield call(lego_orders_list, { subscription: subscription_id, status: 1 });
      const order_id = orders_list?.results?.[0]?.public_id;
      if (order_id) yield call(lego_orders_change_place_date, order_id, `${first_order_date}T00:00:00.000Z`);
    } catch (error) {
      yield put({ type: ERROR_CHANGE_SHIPMENT_DATE, payload: error });
    }
  }

  yield put({ type: RECEIVE_REACTIVATE_SUBSCRIPTION, payload: { subscription } });
}
