import requestSubscriptionUpgradeToPrepaid from '@ordergroove/smi-store/sagas/request-upgrade-subscription-to-prepaid';

import { sagaMiddleware } from '../core/store';
import { selectSubscription, selectOrder, selectProduct } from './selectors';

export function initialize(form) {
  const subscriptionEl = form.querySelector('[name="subscription"]');
  if (!subscriptionEl) {
    throw new Error('Missing <input type="hidden" name="subscription" value="{{ subscription.public_id }}">');
  }
  const orderEl = form.querySelector('[name="order"]');
  if (!orderEl) {
    throw new Error('Missing <input type="hidden" name="order" value="{{ order.public_id }}">');
  }
  const productEl = form.querySelector('[name="product"]');
  if (!productEl) {
    throw new Error('Missing <input type="hidden" name="product" value="{{ product.external_product_id }}">');
  }
}

/**
 *
 * @param {FormData} data
 * @param {Object} state
 * @returns
 */
export function validate(data, state) {
  const subscription_id = data.get('subscription');
  if (!subscription_id) return { valid: false, field: 'subscription', reason: 'INVALID_SUBSCRIPTION_ID' };
  const subscription = selectSubscription(subscription_id)(state);
  if (typeof subscription === 'undefined')
    return { valid: false, field: 'upgrade_subscription_to_prepaid', reason: 'SUBSCRIPTION_NOT_FOUND' };

  const order_id = data.get('order');
  if (!order_id) return { valid: false, field: 'order', reason: 'INVALID_ORDER_ID' };
  const order = selectOrder(order_id)(state);
  if (typeof order === 'undefined')
    return { valid: false, field: 'upgrade_subscription_to_prepaid', reason: 'ORDER_NOT_FOUND' };

  const product_id = data.get('product');
  if (!product_id) return { valid: false, field: 'product', reason: 'INVALID_PRODUCT_ID' };
  const product = selectProduct(product_id)(state);
  if (typeof product === 'undefined')
    return { valid: false, field: 'upgrade_subscription_to_prepaid', reason: 'PRODUCT_NOT_FOUND' };

  return { valid: true };
}

export function submit(formData) {
  const task = sagaMiddleware.run(requestSubscriptionUpgradeToPrepaid, {
    payload: {
      subscription_id: formData.get('subscription'),
      order_id: formData.get('order'),
      product_id: formData.get('product'),
      orders_per_billing: Number(formData.get('ordersPerBilling'))
    }
  });
  return task;
}

export default {
  validate,
  initialize,
  submit
};
