import reduceReducers from 'reduce-reducers';
import { Reducer, combineReducers } from 'redux';

import locale from './locale';
import customer from './customer';
import merchant_id from './merchant_id';
import auth_url from './auth_url';
import environment from './environment';
import orders from './orders';
import items_by_order from './items_by_order';
import order_item_by_id from './order_item_by_id';
import product_by_id from './product_by_id';
import product_id_by_subscription from './product_id_by_subscription';
import sku_swap from './sku_swap';
import sku_swap_subscriptions from './sku_swap_subscriptions';
import subscriptions from './subscriptions';
import address_by_id from './address_by_id';
import payment_by_id from './payment_by_id';
import localization_reducer from './localization_reducer';
import orders_reducer from './orders_reducer';
import notifications from './notifications';
import loading from './loading';
import prepaid_incentives from './prepaid_incentives';
import global_error from './global_error';
import order_items_reducer from './order_items_reducer';
import session_id from './session_id';
import incentives_by_product_id from './incentives_by_product_id';
import offer_by_product_id from './offer_by_product_id';
import discount_codes_by_order_id from './discount_codes_by_order_id';
import discount_codes_by_subscription_id from './discount_codes_by_subscription_id';
import settings from './settings';

const initial =
  val =>
  (state = val) =>
    state;

const reducers = combineReducers({
  locale,
  customer,
  merchant_id,
  auth_url,
  environment,
  orders,
  order_items: initial([]),
  payments: initial([]),
  addresses: initial([]),
  products: initial([]),

  localized_product_by_id: initial({}), // deprecated
  items_by_order, // deprecated
  order_item_by_id, // deprecated
  product_by_id, // deprecated
  product_id_by_subscription, // internal, not public
  sku_swap,
  subscriptions,
  prepaid_incentives,
  address_by_id, // deprecated
  payment_by_id, // deprecated
  notifications,
  loading,
  global_error,
  session_id,
  incentives_by_product_id,
  offer_by_product_id,
  discount_codes_by_subscription_id,
  discount_codes_by_order_id: initial({}),
  settings
});

export default reduceReducers<any>(
  {},
  reducers,
  localization_reducer as Reducer<any>,
  orders_reducer as Reducer<any>,
  order_items_reducer as Reducer<any>,
  (state => ({
    ...state,
    // for legacy api
    localized_product_by_id: state.product_by_id,
    // expose plain array state props instead of _by_id and enforce to use find/filters
    order_items: Object.values(state.items_by_order).flat(),
    payments: Object.values(state.payment_by_id),
    addresses: Object.values(state.address_by_id),
    products: Object.values(state.product_by_id)
  })) as Reducer<any>,
  // this needs to be after the previous reducer so it can receive the current value of `products`
  sku_swap_subscriptions as Reducer<any>,
  discount_codes_by_order_id as Reducer<any>
);
