import { call, put, select } from 'redux-saga/effects';
import { State } from '..';
import { RECEIVE_UPGRADE_ONE_TIME_TO_SUBSCRIPTION, ERROR_UPGRADE_ONE_TIME_TO_SUBSCRIPTION } from '../constants';
import { lego_subscription_upgrade_from_one_time } from './api';

interface UpgradeOneTimeToSubscriptionPayload {
  payload: {
    order: string;
    item: string;
    every: number | string;
    every_period: number | string;
    offer?: string;
  };
}

export default function* requestUpgradeOneTimeToSubscription({
  payload: { order, item, every, every_period, offer }
}: UpgradeOneTimeToSubscriptionPayload) {
  try {
    const subscription = yield call(lego_subscription_upgrade_from_one_time, item, every, every_period, offer);
    const { products } = (yield select()) as State;
    const product = products.find(p => p.external_product_id === subscription.product);
    yield put({
      type: RECEIVE_UPGRADE_ONE_TIME_TO_SUBSCRIPTION,
      payload: { subscription, product, refresh_orders: [order] }
    });
  } catch (error) {
    yield put({ type: ERROR_UPGRADE_ONE_TIME_TO_SUBSCRIPTION });
  }
}
