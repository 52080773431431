import { html } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat.js';

import setMerchantId from '@ordergroove/smi-store/actions/set-merchant-id';
import setAuthUrl from '@ordergroove/smi-store/actions/set-auth-url';
import setEnvironment from '@ordergroove/smi-store/actions/set-environment';

import { parseAuth } from '@ordergroove/auth';
import { AUTHORIZE, SET_CORE_SETTINGS } from '@ordergroove/smi-store/constants';
import { store } from './core/store';
import HTMLSmiElement from './smi-element';
import { Template } from './core/create-renderer';

const parseAuthAttr = (raw, public_id) => {
  let auth;
  try {
    auth = JSON.parse(raw);
  } catch (err) {
    auth = parseAuth(raw);
  }
  return auth
    ? {
        public_id,
        ...auth
      }
    : null;
};

/**
 * Replaces legacy smi markup with new og-smi element
 */
export function upgradeSmi() {
  const smiElements = document.getElementsByTagName('og-smi');
  let smiElement = smiElements[0];
  const legacySmiMarkup = document.getElementById('og-msi');
  if (!smiElement && legacySmiMarkup) {
    smiElement = document.createElement('og-smi');
    legacySmiMarkup.appendChild(smiElement);
  }
}

export function isPreviewingSmiTheme() {
  const loc = window.location;
  return loc.search.includes('og_preview') && loc.search.includes('main_theme=');
}

export async function initPreviewTheme() {
  const loc = window.location;
  const searchParams = new URLSearchParams(loc.search);

  // link url should contain ?og_preview&main_theme__name=some-theme or ?og_preview&main_theme=some-id
  if (!(searchParams.has('og_preview') && searchParams.has('main_theme'))) return;

  const mainSmi = document.querySelector('script[src*="/msi.js"]');

  if (!mainSmi) {
    throw new Error('msi.js not found in document');
  }

  const smiUrl = new URL(mainSmi.getAttribute('src'));
  const mainTheme = searchParams.get('main_theme');
  smiUrl.searchParams.set('main_theme', mainTheme);

  if (document.querySelector(`script[src="${smiUrl}"]`)) {
    // theme already loaded (should not be catchin this but just in case)
    throw new Error('theme already loaded skipping');
  }

  smiUrl.searchParams.set('initOnly', 'true');
  smiUrl.searchParams.set('ts', new Date().getTime().toString());

  const msiJs = document.createElement('script');
  msiJs.setAttribute('src', `${smiUrl}`);

  return new Promise((resolve, reject) => {
    msiJs.onload = () => {
      resolve(mainTheme);
    };

    msiJs.onerror = reject;

    document.head.appendChild(msiJs);
  });
}

bootstrap.calls = 0;
/**
 * @internal
 * Function that bootstraps SMI for a given merchant
 *
 * NOTE: auth and auth_url are boot optionals, if none is present smi will attempt
 * find *og_auth* cookie
 * @param runtimeConfig Runtime configuration indicating merchant and auth resolution
 * @param templateGenerator Main template factory
 * @category API
 * @returns
 */
export async function bootstrap(
  runtimeConfig: {
    merchant_id: string;
    auth_url?: string;
    auth?: object;
    env: 'prod' | 'staging';
    core_settings?: object;
  },
  templateGenerator: (html: Function, repeat: Function) => Template
) {
  // count the number of times bootstrap(), in order to detect preview works or not.
  bootstrap.calls++;

  if (isPreviewingSmiTheme()) {
    try {
      // initPreviewTheme it will load another script at head that
      // will call eventually bootstrap again.
      const themeName = await initPreviewTheme();
      if (bootstrap.calls === 2) {
        console.info('%cOrdergroove SMI Preview Mode', 'background: #222; color: #bada55; font-size: 16px');
        console.info('Previewing theme', themeName);
        bootstrap.calls = 0;
        return;
      } else if (bootstrap.calls === 1) {
        console.info('%cOrdergroove SMI Preview Mode Error', 'background: darkred; color: #fff; font-size: 22px');
        console.info(
          '%cTheme name or id might not exist %c' + themeName,
          'font-size:16px',
          'font-size:16px;font-weight:bold;'
        );
        console.info('%cSMI is showing the published live theme', 'font-size:16px');
      }
      bootstrap.calls = 0;
    } catch (err) {
      if (err.message !== 'theme already loaded skipping') {
        console.error(err);
      }
    }
  }

  HTMLSmiElement.template = templateGenerator(html, repeat);

  const { merchant_id, auth_url, env, auth, core_settings } = runtimeConfig;

  if (typeof merchant_id !== 'undefined') store.dispatch(setMerchantId(merchant_id));
  if (typeof env !== 'undefined') store.dispatch(setEnvironment(env));
  else store.dispatch(setEnvironment('prod'));
  if (typeof auth !== 'undefined') {
    const parsedAuth = parseAuthAttr(auth, merchant_id);
    if (parsedAuth) {
      store.dispatch({ type: AUTHORIZE, payload: parsedAuth });
    }
    if (!merchant_id && parsedAuth.public_id) {
      store.dispatch(setMerchantId(parsedAuth.public_id));
    }
  } else {
    // No auth url means cookie only auth resolver take.
    // We might set this anyway to allow sagas bootstrap start cascading all actions
    store.dispatch(setAuthUrl(auth_url || ''));
  }

  if (typeof core_settings !== 'undefined') {
    store.dispatch({ type: SET_CORE_SETTINGS, payload: core_settings });
  }

  if (document.readyState === 'loading') {
    window.addEventListener('DOMContentLoaded', () => upgradeSmi());
  } else {
    upgradeSmi();
  }
}
