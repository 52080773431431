import { render } from 'lit-html';
import { SET_LOCALE, SM_LOAD_START } from '@ordergroove/smi-store/constants';
import { store, Store } from './core/store';
import createRenderer, { Template } from './core/create-renderer';
import { trackingSelectors, handleTrackingEvent } from './tracking-selectors';

const attrHasChanged = name => mutation => mutation.type === 'attributes' && mutation.attributeName === name;

function createHtmlLangObserver(action: (locale: string) => void, node = document.firstElementChild): MutationObserver {
  const observer = new MutationObserver(mutationsList => {
    const mutation = mutationsList.find(attrHasChanged('lang'));
    if (mutation) {
      const lang = (mutation.target as HTMLElement).getAttribute('lang');
      action(lang);
    }
  });
  observer.observe(node, { attributes: true, childList: false, subtree: false });
  return observer;
}
/**
 * @internal
 * Custom element that renders an smi-template
 *
 * Usage
 *
 * ```html
 * <og-smi></og-smi>
 * ```
 * @tag *og-smi*
 */
export class HTMLSmiElement extends HTMLElement {
  /** @internal */
  static instances: Set<HTMLSmiElement> = new Set();

  /**
   * This value is used to identify you as the merchant and is the same value that you would find under the customer.public_id key above.
   * @attribute **merchant_id** This property is exposed to HTML attribute `merchant_id`
   * @type {String} merchant_id
   */
  merchant_id: string = '';

  /**
   *
   * The name of the Ordergroove environment where the SMI is currently running. Default prod
   * @attribute **env** This property is exposed to HTML attribute `env`
   * @type {'prod' | 'staging'}
   */
  env: 'prod' | 'staging' = 'prod';

  /**
   * Triggered after smi elements initialize and gets connected to DOM
   * @event
   */
  static READY = 'og:smi:ready';

  /**
   * Defines the global redux store
   */
  static store: Store = store;
  private static _template?: Template;

  constructor() {
    super();
    this.action(SM_LOAD_START);
    this.handleTrackingClick = this.handleTrackingClick.bind(this);
  }

  /**
   * Sets the default template for all smi instaces
   */
  static set template(value: Template) {
    HTMLSmiElement._template = value;
    HTMLSmiElement.instances.forEach((smi: HTMLSmiElement) => {
      smi.template = value;
    });
  }
  /**  @internal */
  private renderer?: Template = null;
  /**  @internal */
  private unsubscribeRenderer?: Function = null;
  /**  @internal */
  private htmlLangObserver?: MutationObserver = null;

  /**
   * Key-value store for client-side state, separate from Redux.
   * This is made available to the templates via `client_state`.
   * This should be used sparingly - prefer interacting with Redux or writing custom elements, if possible.
   */
  public clientState: Record<string, unknown> = {};

  /** Update the client state. The object passed to this function is merged with the existing state. */
  setClientState(newState: object) {
    this.clientState = {
      ...this.clientState,
      ...newState
    };
    // re-render the template with the updated state
    this.requestUpdate();
  }

  /**
   * @internal
   * Sets template for current smi instance
   *
   * The following example ilustrates a smi template that renders all state as JSON string
   * ```js
   * document.querySelector('og-smi').templatye = html => state => html`<pre>${JSON.stringify(state,null,4)}</pre>`
   * ```
   *
   * @param template Template generator function
   * @returns {void}
   */
  set template(template: Template) {
    this.renderer = createRenderer(template);
    this.requestUpdate();
  }

  /**
   * Click handler for elements that should trigger an tracking reducer action.
   * @internal
   */
  handleTrackingClick(e: MouseEvent) {
    // get the element that was clicked
    // we can't use currentTarget because we're using event delegation (currentTarget is the smi-element)
    // note: v0 template uses <a> for many dialog triggers
    const target = (e.target as HTMLElement).closest<HTMLElement>('button, a');
    if (!target) return;

    for (const [eventType, selectors] of Object.entries(trackingSelectors)) {
      if (selectors.some(selector => target.matches(selector))) {
        handleTrackingEvent(eventType, target, this.store);
        return;
      }
    }
  }

  connectedCallback() {
    HTMLSmiElement.instances.add(this);

    if (!this.renderer && HTMLSmiElement._template) {
      this.template = HTMLSmiElement._template;
    }

    /**  @internal */
    this.unsubscribeRenderer = this.store.subscribe(() => this.requestUpdate());

    /**  @internal */
    this.htmlLangObserver = createHtmlLangObserver(lang => this.setLocale(lang));

    this.setLocale(this.getAttribute('lang') || document.firstElementChild.getAttribute('lang') || 'en-US');
    if (this.store.getState().settings?.sm_tracking_enabled) {
      this.addEventListener('click', this.handleTrackingClick);
    }

    this.requestUpdate();
    this.dispatchEvent(new CustomEvent(HTMLSmiElement.READY, { bubbles: true, cancelable: false }));
  }

  /**  @internal */
  requestUpdate() {
    if (this.renderer) {
      render(this.renderer({ ...this.store.getState(), client_state: this.clientState }), this, {
        eventContext: this
      });
    }
  }

  /**  @internal */
  disconnectedCallback() {
    if (this.htmlLangObserver) this.htmlLangObserver.disconnect();
    HTMLSmiElement.instances.delete(this);
    this.unsubscribeRenderer();
    this.removeEventListener('click', this.handleTrackingClick);
  }

  /**
   * Returns the instance of redux store
   */
  get store(): Store {
    return HTMLSmiElement.store;
  }

  /**
   * @internal
   * @param {String} locale
   */
  setLocale(locale) {
    this.action(SET_LOCALE, locale);
  }

  /**
   * @internal
   */
  action(type, payload = {}) {
    this.store.dispatch(typeof type === 'object' ? type : { type, payload });
  }
}

try {
  window.customElements.define('og-smi', HTMLSmiElement);
} catch (err) {
  console.warn('Ordergroove smi custom element already defined', err);
}

export default HTMLSmiElement;
