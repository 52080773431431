import {
  RECEIVE_INITIAL_DATA,
  RECEIVE_INITIAL_DATA_ERROR,
  RECEIVE_INITIAL_DATA_TIMEOUT,
  REQUEST_INITIAL_DATA,
  UNAUTHORIZED
} from '../constants';

export default function loading(state = 1, action) {
  switch (action.type) {
    case RECEIVE_INITIAL_DATA:
    case RECEIVE_INITIAL_DATA_ERROR:
    case RECEIVE_INITIAL_DATA_TIMEOUT:
    case UNAUTHORIZED:
      return 0;
    case REQUEST_INITIAL_DATA:
      return 1;
    default:
      return state;
  }
}
