import { call, put, takeEvery } from 'redux-saga/effects';

import { REQUEST_SHOP_SETTINGS, SET_CORE_SETTINGS } from '../constants';
import { shopify_app_shop_settings_get } from './api';

interface ShopifyWindow extends Window {
  Shopify: {
    shop: string;
  };
}

declare let window: ShopifyWindow;

function parseResponse(response) {
  try {
    return JSON.parse(response).shop;
  } catch (e) {
    console.error('Unable to parse shop settings: response was', response);
    return {};
  }
}

export function* requestShopSettings() {
  const shopDomain = window?.Shopify?.shop;
  if (!shopDomain) return;
  const response = yield call(shopify_app_shop_settings_get, {
    shopDomain
  });
  const shop = parseResponse(response);

  const settingsValues = {
    shopify_shop_id: shop?.shopInfo?.id,
    shopify_customer_accounts_version: shop?.settings?.customerAccountsVersion
  };

  yield put({ type: SET_CORE_SETTINGS, payload: settingsValues });
}

export function* watchForRequestShopSettings() {
  yield takeEvery(REQUEST_SHOP_SETTINGS, requestShopSettings);
}
