import requestUpgradeOneTimeToSubscription from '@ordergroove/smi-store/sagas/request-upgrade-one-time-to-subscription';

import { sagaMiddleware } from '../core/store';
import { parseFrequency } from './change-subscription-frequency';
import { selectOrder, selectOrderItem } from './selectors';

export function initialize(form) {
  const orderEl = form.querySelector('[name="order"]');
  if (!orderEl) {
    throw new Error('Missing <input type="hidden" name="order" value="{{ order.public_id }}">');
  }
  const itemEl = form.querySelector('[name="item"]');
  if (!itemEl) {
    throw new Error('Missing <input type="hidden" name="item" value="{{ order_item.public_id }}">');
  }
  const frequencyEl = form.querySelector('[name="frequency"]');
  if (!frequencyEl) {
    throw new Error('Missing <input name="frequency"> or <select name="frequency">');
  }
}

/**
 *
 * @param {FormData} data
 * @param {Object} state
 * @returns
 */
export function validate(data, state) {
  const order_id = data.get('order');
  if (!order_id) return { valid: false, field: 'order', reason: 'INVALID_ORDER_ID' };
  const order = selectOrder(order_id)(state);
  if (typeof order === 'undefined') return { valid: false, field: 'order', reason: 'ORDER_NOT_FOUND' };

  const item_id = data.get('item');
  if (!item_id) return { valid: false, field: 'item', reason: 'INVALID_ITEM_ID' };
  const item = selectOrderItem(item_id)(state);
  if (typeof item === 'undefined') return { valid: false, field: 'item', reason: 'ITEM_NOT_FOUND' };

  const frequency = data.get('frequency');
  if (!frequency) {
    return { valid: false, field: 'frequency', reason: 'INVALID_FREQUENCY' };
  }
  const { every, every_period: everyPeriod } = parseFrequency(frequency);
  if (!Number.isInteger(every) || !Number.isInteger(everyPeriod) || everyPeriod < 1 || everyPeriod > 3) {
    return { valid: false, field: 'frequency', reason: 'INVALID_FREQUENCY' };
  }

  return { valid: true };
}

export function submit(formData) {
  const { every, every_period: everyPeriod } = parseFrequency(formData.get('frequency'));

  const task = sagaMiddleware.run(requestUpgradeOneTimeToSubscription, {
    payload: {
      order: formData.get('order'),
      item: formData.get('item'),
      every: every,
      every_period: everyPeriod,
      ...(formData.get('offer') ? { offer: formData.get('offer') } : {})
    }
  });
  return task;
}

export default {
  validate,
  initialize,
  submit
};
