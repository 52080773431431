import { all, call, put, takeEvery } from 'redux-saga/effects';

import uniqBy from 'lodash/uniqBy';
import { REQUEST_SUBSCRIPTIONS, RECEIVE_SUBSCRIPTIONS, REQUEST_PRODUCT } from '../constants';
import { lego_subscriptions_list } from './api';
import { request_all_of } from './request-all-of';
import { Subscription } from '..';

export function* requestSubscriptions({ payload }: { type: string; payload: any }) {
  const response: { results: Subscription[] } = yield call(
    request_all_of,
    lego_subscriptions_list,
    payload,
    RECEIVE_SUBSCRIPTIONS
  );
  yield all(uniqBy(response.results, 'product').map(({ product }) => put({ type: REQUEST_PRODUCT, payload: product })));

  return response;
}

export function* watchForRequestSubscriptions() {
  yield takeEvery(REQUEST_SUBSCRIPTIONS, requestSubscriptions);
}
