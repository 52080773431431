import { all, call, put, select } from 'redux-saga/effects';
import { REQUEST_PRODUCT, RECEIVE_PRODUCT, REQUEST_PRODUCTS_BY_GROUP } from '../constants';
import { State } from '../types';
import { lego_products_get } from './api';
import { takeUnique } from './take-unique';

export function* requestProduct({ payload: id }: { type: string; payload?: string }) {
  const response = yield put({
    type: RECEIVE_PRODUCT,
    payload: yield call(lego_products_get, id)
  });
  const { sku_swap: { requested } = {} } = (yield select()) as State;

  yield all(
    (response.payload.groups || [])
      .filter(({ group_type, name }) => group_type === 'sku_swap' && !requested?.[name])
      .map(group => put({ type: REQUEST_PRODUCTS_BY_GROUP, payload: group }))
  );

  return response;
}

export function* watchForRequestProduct() {
  yield takeUnique(REQUEST_PRODUCT, requestProduct);
}
