export default function createCurrency(locale) {
  return (amount, currency, currencyDisplay = 'symbol') => {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency || 'USD',
      currencyDisplay
    });
    return formatter.format(amount);
  };
}
