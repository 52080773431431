import unionBy from 'lodash/unionBy';
import sortBy from 'lodash/sortBy';

import {
  RECEIVE_DELETE_ITEM,
  RECEIVE_ORDERS,
  RECEIVE_ORDER,
  RECEIVE_SEND_NOW,
  RECEIVE_CHANGE_SHIPMENT_DATE,
  RECEIVE_CHANGE_ORDER_SHIPPING,
  RECEIVE_USE_FOR_ALL,
  RECEIVE_ORDER_CANCEL,
  COMPLETE_ORDERS_REQUEST,
  REPLACE_ORDERS
} from '../constants';

import { selectOrderItemsByOrderId } from '../selectors';

/**
 * @internal
 * Defines the order type
 */
export enum OrderType {
  SUBSCRIPTION = 1,
  ONE_TIME = 2,
  OTHER = 3
}

/**
 * @categoryDescription Fields
 */

/**
 *
 * This field contains the status of an order by its numerical value.
 * @category Fields on Objects
 *
 */
export enum OrderStatus {
  /**
   *  Order will be placing some time in the future.
   */
  UNSENT = 1,
  SENT,
  /**
   * Orders are set to 'REJECTED' after the retry limit has been reached.
   */
  REJECTED,
  CANCELLED,
  SUCCESS,
  /**
   * Order is currently processing and will be sent as soon as possible.
   */
  SEND_NOW,
  ONE_TIME,
  UNKNOWN_PLACEMENT_RESPONSE,
  PENDING_BATCH_RESPONSE,
  PENDING_VERIFICATION,
  PENDING_PLACEMENT,
  EXCEPTION_DURING_PLACEMENT_PREPARATION,
  /**
   * Unable to reach the merchant's order placement service and will be retried on the next placement. No retry limit.
   */
  CONNECTION_ERROR_DURING_PLACEMENT,
  /**
   * The batch or http response from the merchant was not understood by our system. This order is not retried.
   */
  RESPONSE_PROCESSING_ERROR,
  /**
   * A generic error was sent or defaulted by our system. Merchant sets number of times to retry.
   */
  GENERIC_ERROR_RESPONSE,
  EXPIRED,
  /**
   * This order was merged with another order. To find the order, check the Order's extra data for the field 'merged_order_id'.
   */
  MERGED,
  /**
   * The order failed with a 140 error code and is going through the credit card retry process.
   */
  CREDIT_CARD_RETRY
}

/**
 * @category Objects in State
 */
export type Order = {
  /**
   * This is the order id.
   */
  public_id: string;
  /**
   * This id is used to identify you as the merchant.
   */
  merchant: string;

  /**
   * This field is used to determine the current status of the order.
   * List of possible order status which are important to note:
   */
  status: string;

  /**
   *
   * This field represents your customer's id.
   * This id is used to map the customer between Ordergroove and your ecommerce system.
   */
  customer: string;
  /**
   * This is the id of the payment record associated with the order.
   */
  payment: string;
  /**
   * This is the id of the shipping address record associated with the order.
   */
  shipping_address: string;
  /**
   * This is the order sub total. Inclusive of discounts but does not include tax and shipping costs.
   */
  sub_total: string;
  /**
   * Any tax which was already applied to the order. (Please note that Ordergroove typically does not calculate tax so this value will most likely be '0.00')
   */
  tax_total: string;
  /**
   * Any shipping cost which was already applied to the order.
   */
  shipping_total: string;
  /**
   * The total discount amount being applied to the order.
   */
  discount_total: string;
  /**
   * The total cost of the order, inclusive of any applicable discounts, taxes and shipping costs.
   */
  total: string;

  /**
   * The date and time that the order was created by the Ordergroove system.
   */
  created: string;
  /**
   * The date that the order will be placed into the ecommerce system for fulfillment.
   */
  place: string;
  /**
   * The date that the order was cancelled.
   */
  cancelled?: string;
  /**
   * The amount of times that the Ordergroove system attempted to place this order into the ecommerce system.
   */
  tries: number;
  /**
   * An internal counter to determine how many times an order failed to place due to a unknown error.
   */
  generic_error_count: number;

  /**
   * A order type. In most cases this value will be set to 1 to indicate that this is a subscription order.
   */
  type: OrderType;

  /**
   * This field is updated after the order is successfully placed with the id of the resulting order in the ecommerce system.
   */
  order_merchant_id?: string | null;
  /**
   * The reason that an order failed to place into the ecommerce system.
   */
  rejected_message?: string | null;
  /**
   * This field is reserved to hold any meta data tied to the order.
   */
  extra_data: object;
  /**
   * This field will be set to true if the order has been locked and is no longer in an editable state.
   */
  locked: boolean;
  /**
   * This field is used for internal purposes and can be ignored.
   * @deprecated
   */
  oos_free_shipping: boolean;
};

/**
 * Order payload that comes from api response
 */
type RawOrder = {
  cancelled?: string;
  created: string;
  customer: string;
  discount_total: string;
  extra_data: object;
  generic_error_count: number;
  locked: boolean;
  merchant: string;
  oos_free_shipping: false;
  order_merchant_id?: string;
  payment: string;
  place: string;
  public_id: string;
  rejected_message?: string;
  shipping_address: string;
  shipping_total: string;
  status: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  sub_total: string;
  tax_total: string;
  total: string;
  tries: number;
  type: number;
};

export type CompleteOrdersRequestPayload = {
  /** the filter query parameters the orders request was made with */
  filters: { status: string[]; ordering: string; [key: string]: unknown };
  /** the results of the orders request, including all pages of the response  */
  results: RawOrder[];
};

export const mapOrder = (order: RawOrder): Order => ({
  ...order,
  status: OrderStatus[order.status],
  // place date should be with no time
  ...(order.place && { place: order.place.substr(0, 10) })
});

export default function orders(state: Order[] = [], { type, payload }: { type?: string; payload?: any } = {}): Order[] {
  switch (type) {
    case RECEIVE_ORDERS:
      return sortBy(unionBy(payload.results.map(mapOrder), state, 'public_id'), 'place');
    case REPLACE_ORDERS:
      return sortBy(payload.results.map(mapOrder), 'place');
    case RECEIVE_ORDER:
    case RECEIVE_SEND_NOW:
    case RECEIVE_ORDER_CANCEL:
      return sortBy(unionBy([mapOrder(payload)], state, 'public_id'), 'place');
    case RECEIVE_CHANGE_SHIPMENT_DATE:
    case RECEIVE_CHANGE_ORDER_SHIPPING:
      /**
       * These actions have the potential to merge orders. If the API response contains a new public ID, then the original order was merged with another.
       */
      return sortBy(
        state
          .filter(
            ({ public_id }) => !(public_id === payload.current.public_id || public_id === payload.previous_order_id)
          )
          .concat(mapOrder(payload.current)),
        'place'
      );
    case RECEIVE_USE_FOR_ALL:
      return state.map(order => {
        if (order.status === 'UNSENT') {
          order.shipping_address = payload.public_id;
        }
        return order;
      });
    case RECEIVE_DELETE_ITEM:
      return state.filter(order =>
        order.public_id === payload.order_id ? selectOrderItemsByOrderId(payload.order_id)(payload).length > 1 : true
      );
    case COMPLETE_ORDERS_REQUEST: {
      const { filters, results }: CompleteOrdersRequestPayload = payload;

      if (Object.keys(filters).some(key => key !== 'status' && key !== 'ordering')) {
        // don't attempt to filter out stale data if the orders were requested with more filter params
        // in that case, the list of orders we have may not be complete
        return state;
      }

      const publicIds = results.map(r => r.public_id);
      const parsedStatuses = filters.status.map(s => OrderStatus[s]);

      // since we have the entire set of orders in the requested statuses, we can remove orders that no longer exist
      // this is so that when orders merge, we remove the orders that no longer exist
      // we only consider orders that match the status(es) we requested
      return state.filter(order => !parsedStatuses.includes(order.status) || publicIds.includes(order.public_id));
    }
    default:
      return state;
  }
}
