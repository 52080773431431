import { call, put, select } from 'redux-saga/effects';

import { ERROR_CHANGE_SUBSCRIPTION_QUANTITY, RECEIVE_CHANGE_SUBSCRIPTION_QUANTITY } from '../constants';
import { lego_subscriptions_change_quantity } from './api';
import { selectSubscription } from '../selectors';
import { Subscription } from '../types';

export default function* REQUEST_CHANGE_SUBSCRIPTION_QUANTITY({
  payload: { subscription_id, quantity } = {}
}: {
  payload: { subscription_id?: string; quantity?: string };
}) {
  const state = yield select();
  const subscription: Subscription = selectSubscription(subscription_id)(state);
  if (subscription.can_change_quantity) {
    const updatedSubscription = yield call(lego_subscriptions_change_quantity, subscription_id, quantity);
    yield put({
      type: RECEIVE_CHANGE_SUBSCRIPTION_QUANTITY,
      payload: { subscription: updatedSubscription }
    });
  } else {
    yield put({
      type: ERROR_CHANGE_SUBSCRIPTION_QUANTITY
    });
  }
}
