import filter from 'lodash/filter';
import wrapFilter from './wrap-filter';

/**
 * Makes OG identity function.
 * If argument is strings of 32 chars it searches for public_id
 * searches
 * @param {*} filterFN
 * @returns
 */
export const makePredicate = filterFN => (val, predicate) => {
  let computed = predicate;

  if (computed == null) return undefined;
  if (typeof computed === 'string' && computed.length === 32) {
    computed = { public_id: predicate };
  }
  if (typeof computed === 'object') {
    const oneKey = Object.keys(computed).join();
    if (oneKey === 'id' || oneKey === 'pk') {
      computed = { public_id: computed[oneKey] };
    }
    if (oneKey === 'public_id' || oneKey === 'id' || oneKey === 'pk') {
      const public_id = computed.public_id;
      computed = it =>
        typeof it === 'object' &&
        (('public_id' in it && it.public_id === public_id) ||
          ('external_product_id' in it && it.external_product_id === public_id));
    }
  }
  return filterFN(val, computed);
};

export default wrapFilter(makePredicate(filter));
