import { call, put } from 'redux-saga/effects';

import { RECEIVE_CHANGE_SUBSCRIPTION_FREQUENCY } from '../constants';
import { lego_subscriptions_change_frequency } from './api';

export default function* REQUEST_CHANGE_SUBSCRIPTION_FREQUENCY({
  payload: { subscription_id, every, every_period } = {}
}: {
  payload: { subscription_id?: string; every?: number | string; every_period?: number | string };
}) {
  const subscription = yield call(lego_subscriptions_change_frequency, subscription_id, every, every_period);
  yield put({
    type: RECEIVE_CHANGE_SUBSCRIPTION_FREQUENCY,
    payload: { subscription }
  });
}
