import requestChangeSubscriptionRenewalBehavior, {
  PrepaidSubscriptionRenewalBehavior
} from '@ordergroove/smi-store/sagas/request-change-subscription-renewal-behavior';
import { sagaMiddleware } from '../core/store';
import { selectSubscription } from './selectors';

export function initialize(form) {
  const subscriptionEl = form.querySelector('[name="subscription"]');
  if (!subscriptionEl) {
    throw new Error('Missing <input name="subscription" value="{{ subscription.public_id }}">');
  }

  const checkbox = form.querySelector('[name="autorenew_on"]');
  if (!checkbox) {
    throw new Error('Missing <input type="checkbox" name="autorenew_on" value="autorenew">');
  }
}

/**
 *
 * @param {FormData} data
 * @param {Object} state
 * @returns
 */
export function validate(data, state) {
  const subscription_id = data.get('subscription');
  if (!subscription_id) {
    return { valid: false, field: 'subscription', reason: 'INVALID_SUBSCRIPTION_ID' };
  }
  const subscription = selectSubscription(subscription_id)(state);
  if (typeof subscription === 'undefined') {
    return { valid: false, field: 'subscription', reason: 'SUBSCRIPTION_NOT_FOUND' };
  }
  if (!subscription.prepaid_subscription_context) {
    return { valid: false, field: 'subscription', reason: 'SUBSCRIPTION_NOT_PREPAID' };
  }

  return { valid: true };
}

export function submit(formData) {
  const subscription_id = formData.get('subscription');
  const renewal_behavior = formData.get('autorenew_on')
    ? PrepaidSubscriptionRenewalBehavior.AUTORENEW
    : PrepaidSubscriptionRenewalBehavior.CANCEL;
  const task = sagaMiddleware.run(requestChangeSubscriptionRenewalBehavior, {
    payload: {
      subscription_id,
      renewal_behavior
    }
  });
  return task;
}

export default {
  validate,
  initialize,
  submit
};
