import { call, put, select, take } from '@redux-saga/core/effects';
import { AUTHORIZE, RECEIVE_UNEXPECTED_API_RESPONSE } from '../constants';

import mapValues from 'lodash/mapValues';
import { Customer, State } from '../types';

const DEFAULT_SUCCESS_STATUSES = {
  DELETE: 204,
  GET: 200,
  PATCH: 200,
  POST: 201,
  PUT: 200
};

export class UnexpectedApiResponseError extends Error {
  response?: any;
  status?: any;

  constructor(message, response: any = {}) {
    super(message);
    this.name = 'UnexpectedApiResponseError';
    this.response = response;
    this.status = response.status;
  }
}

export function* resolve_auth() {
  const { customer } = yield select();

  let auth = customer;

  if (!auth || !customer.sig) {
    auth = (yield take(AUTHORIZE)).payload;
  }
  const { environment }: State = yield select();

  return { auth, environment };
}

export async function request<RequestArgs extends any[]>(
  host: string,
  auth: Customer,
  environment: { tracking_url: string; api_url: string; offers_url: string },
  method: ApiMethod,
  action: Action<RequestArgs>,
  ...args: RequestArgs
) {
  const { path, body, ...options } = action;
  const url = new URL(host);
  if (typeof path === 'function') {
    url.pathname = path(...args);
  } else if (path !== null && typeof path !== 'undefined') {
    url.pathname = path;
  }

  if (host !== (environment || {}).api_url && host !== (environment || {}).offers_url) {
    if (!url.pathname.endsWith('/')) url.pathname += '/';
  }

  if (args[0] !== null && typeof args[0] === 'object' && method === 'GET') {
    Object.entries(args[0] as ApiSearchParams).forEach(([key, val]) => {
      if (Array.isArray(val)) {
        val.forEach(_val => url.searchParams.append(key, _val.toString()));
      } else {
        url.searchParams.append(key, val.toString());
      }
    });
  }

  const isMutationOrHasBody = method !== 'GET' || body;
  const response = await fetch(url.toString(), {
    method,
    headers: {
      Authorization: JSON.stringify(auth),
      ...(isMutationOrHasBody && { 'Content-type': 'application/json' }),
      ...(isMutationOrHasBody && host.includes('restapi') && { 'tracking-source': 'MSI' })
    },
    ...options,
    ...(body && { body: JSON.stringify(body(...args)) })
  });

  const expectedStatuses =
    'success_status' in options
      ? Array.isArray(options.success_status)
        ? options.success_status
        : [options.success_status]
      : [DEFAULT_SUCCESS_STATUSES[method]];
  if (expectedStatuses.includes(response.status)) {
    const contentType = response.headers.get('content-type') || '';
    if (['application/json', 'application/javascript', 'text/javascript'].includes(contentType))
      return await response.json();
    return response.text();
  }

  if (!options.ignore_errors) {
    throw new UnexpectedApiResponseError(`Unexpected API response for request ${url.toString()}`, response);
  }
}

function makeGenerator<RequestArgs extends any[]>(apiName: string, method: ApiMethod, action: Action<RequestArgs>) {
  return function* (...args: RequestArgs) {
    const { auth, environment } = yield call(resolve_auth);
    const host = environment[`${apiName}_url`];
    try {
      const response = yield call(request<RequestArgs>, host, auth, environment, method, action, ...args);
      return response;
    } catch (e) {
      if (e instanceof UnexpectedApiResponseError) {
        yield put({
          type: RECEIVE_UNEXPECTED_API_RESPONSE
        });
      }
      throw e;
    }
  };
}

type ApiDeclaration = {
  [apiName: string]: {
    [collectionName: string]: {
      [actionName: string]: Action;
    } & {
      // in this case, the key name is the same as the method
      // list is mapped to get
      [methodName in Lowercase<ApiMethod> | ApiMethod | 'list']?: Omit<Action, 'method'>;
    };
  };
};

const ApiMethods = ['GET', 'PUT', 'POST', 'PATCH', 'DELETE'] as const;
type ApiMethod = (typeof ApiMethods)[number];

// defines how to call an API endpoint
// it's tricky to enforce via types, but both functions are passed the same set of arguments
type Action<RequestArgs extends any[] = any[]> = {
  method?: ApiMethod;
  body?: (...args: RequestArgs) => { [s: string]: any };
  path: ((...args: RequestArgs) => string) | string;
  success_status?: number | number[];
  ignore_errors?: boolean;
};

type ApiCallFunc = (...args: any[]) => any;
type ApiSearchParams = Record<string, string | number | boolean | (string | number)[]>;
type SearchParamsFuncSignature = [searchParams?: ApiSearchParams];

type ApiCallGenerator<CallSignature extends any[]> = ReturnType<typeof makeGenerator<CallSignature>>;

// the type for the parameters that the endpoint is called with
// if body or path are functions, derive the signature from that
// body takes priority, since it usually takes the most parameters
type ApiCallSignature<T extends Action, ActionName extends string | number | symbol> = T['body'] extends ApiCallFunc
  ? Parameters<T['body']>
  : T['path'] extends ApiCallFunc
    ? Parameters<T['path']>
    : T['method'] extends 'GET'
      ? SearchParamsFuncSignature
      : // 'get' and 'list' keys are implicitly method = 'GET'
        ActionName extends 'get' | 'list'
        ? SearchParamsFuncSignature
        : Parameters<() => void>;

type MappedApiSpec<T extends ApiDeclaration> = {
  [K in keyof T]: {
    [J in keyof T[K]]: {
      [L in keyof T[K][J]]: ApiCallGenerator<ApiCallSignature<T[K][J][L], L>>;
    };
  };
};

export function makeApi<T extends ApiDeclaration>(declaration: T): MappedApiSpec<T> {
  return mapValues(declaration, (collections, apiName) =>
    mapValues(collections, actions =>
      mapValues(actions, (action, actionName) => {
        const derivedMethod = (
          action.method || (actionName === 'list' ? 'GET' : actionName)
        ).toUpperCase() as ApiMethod;
        return makeGenerator(apiName, derivedMethod, action);
      })
    )
  ) as MappedApiSpec<T>;
}
