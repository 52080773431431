import requestChangeSubscriptionQuantity from '@ordergroove/smi-store/sagas/request-change-subscription-quantity';

import { sagaMiddleware } from '../core/store';
import { selectSubscription } from './selectors';

export function initialize(form) {
  const subscriptionEl = form.querySelector('[name="subscription"]');
  if (!subscriptionEl) {
    throw new Error('Missing <input type="hidden" name="subscription" value="{{ subscription.public_id }}">');
  }
}

/**
 *
 * @param {FormData} data
 * @param {Object} state
 * @returns
 */
export function validate(data, state) {
  const subscription_id = data.get('subscription');

  if (!subscription_id) return { valid: false, field: 'subscription', reason: 'INVALID_SUBSCRIPTION_ID' };

  const subscription = selectSubscription(subscription_id)(state);

  if (typeof subscription === 'undefined')
    return { valid: false, field: 'subscription', reason: 'SUBSCRIPTION_NOT_FOUND' };

  const product = data.get('product');
  if (!product) return { valid: false, field: 'product', reason: 'PRODUCT_REQUIRED' };

  return { valid: true };
}

export function submit(data, store, actionArgument) {
  if (!data.has('subscription')) return false;

  const subscription_id = data instanceof FormData ? data.get('subscription') : actionArgument;
  const quantity = data.get('quantity');
  const task = sagaMiddleware.run(requestChangeSubscriptionQuantity, {
    payload: {
      subscription_id,
      quantity
    }
  });
  return task;
}

export default {
  validate,
  initialize,
  submit
};
