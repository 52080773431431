import { all } from 'redux-saga/effects';

import { bootstrapApp } from './bootstrap-app';
import { receiveInitialData } from './receive-initial-data';
import { watchRequestAuth } from './request-auth';
import { watchForRequestOrders } from './request-orders';
import { watchForRequestOrderItems } from './request-order-items';
import { watchForRequestPayments } from './request-payments';
import { watchForRequestAddresses } from './request-addresses';
import { watchForRequestSubscriptions } from './request-subscriptions';
import { watchForRequestProduct } from './request-product';
import { watchForRequestProductByGroup } from './request-products-by-group';
import { watchForRequestPrepaidIncentives } from './request-prepaid-incentives';
import { watchForRequestOffers } from './request-offers';
import { watchForRequestCollectTrackingEvent } from './request-collect-tracking-event';
import { watchForRequestSubscriptionDiscountCodes } from './request-subscription-discount-codes';
import { watchForRequestShopSettings } from './request-shop-settings';

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once

export default function* rootSaga() {
  yield all([
    receiveInitialData(),
    bootstrapApp(),
    watchRequestAuth(),
    watchForRequestOrders(),
    watchForRequestOrderItems(),
    watchForRequestPayments(),
    watchForRequestAddresses(),
    watchForRequestSubscriptions(),
    watchForRequestProduct(),
    watchForRequestProductByGroup(),
    watchForRequestPrepaidIncentives(),
    watchForRequestOffers(),
    watchForRequestCollectTrackingEvent(),
    watchForRequestSubscriptionDiscountCodes(),
    watchForRequestShopSettings()
  ]);
}
