import dayjs from 'dayjs';

import 'dayjs/locale/es';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/ja';
import 'dayjs/locale/de';

import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

const loadedLocales = ['es', 'en', 'fr', 'ja', 'de'];

export default function createDateFilter(desiredLocale) {
  // TODO lazy load dayjs locale information
  const language = desiredLocale.substring(0, 2);
  const locale = loadedLocales.includes(language) ? language : 'en';
  return (value, format) =>
    value
      ? dayjs(value)
          .locale(locale)
          .format(format || 'LL')
      : '';
}
