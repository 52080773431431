import * as constants from '../constants';

interface IncentiveDisplay {
  type: string;
  field: string;
  value: number;
  [key: string]: any;
}

interface DerivedValues {
  shipping: { max_amount: number; max_percent: number };
  total: { max_amount: number; max_percent: number };
  subtotal: { max_amount: number; max_percent: number };
}

interface State {
  [key: string]: {
    initial: DerivedValues;
    ongoing: DerivedValues;
  };
}

interface Action {
  type: string;
  payload: {
    incentives: { [key: string]: { initial: string[]; ongoing: string[] } };
    incentives_display: { [key: string]: IncentiveDisplay };
    prepaid_incentives: { data: [{ discounts: [{ public_id: string }] }]; derived: { [key: string]: any } };
  };
}

const getMaxValue = (
  incentives: string[],
  incentiveDisplay: { [key: string]: IncentiveDisplay },
  field: string,
  type: string
) =>
  incentives
    .filter(id => incentiveDisplay[id].field === field && incentiveDisplay[id].type === type)
    .reduce((max, id) => Math.max(max, incentiveDisplay[id].value), 0);

const getMaxValuesByIncentiveType = (
  incentives: string[],
  incentiveDisplay: { [key: string]: IncentiveDisplay }
): DerivedValues => ({
  shipping: {
    max_amount: getMaxValue(incentives, incentiveDisplay, 'shipping_total', 'Discount Amount'),
    max_percent: getMaxValue(incentives, incentiveDisplay, 'shipping_total', 'Discount Percent')
  },
  total: {
    max_amount: getMaxValue(incentives, incentiveDisplay, 'total_price', 'Discount Amount'),
    max_percent: getMaxValue(incentives, incentiveDisplay, 'total_price', 'Discount Percent')
  },
  subtotal: {
    max_amount: getMaxValue(incentives, incentiveDisplay, 'sub_total', 'Discount Amount'),
    max_percent: getMaxValue(incentives, incentiveDisplay, 'sub_total', 'Discount Percent')
  }
});

export default function incentives_by_product_id(state: State = {}, action: Action): State {
  switch (action.type) {
    case constants.RECEIVE_OFFERS: {
      const { incentives = {}, incentives_display, prepaid_incentives } = action.payload;

      // Extract all public_id values from prepaid_incentives.data
      const prepaidPublicIds = new Set(
        prepaid_incentives.data.flatMap(item => item.discounts.map(discount => discount.public_id))
      );

      return Object.entries(incentives).reduce(
        (result, [productId, { initial, ongoing }]) => {
          // Filter out incentives whose ID exists as a public_id in prepaidPublicIds
          const filteredInitial = initial.filter(id => !prepaidPublicIds.has(id));
          const filteredOngoing = ongoing.filter(id => !prepaidPublicIds.has(id));

          result[productId] = {
            initial: getMaxValuesByIncentiveType(filteredInitial, incentives_display),
            ongoing: getMaxValuesByIncentiveType(filteredOngoing, incentives_display)
          };

          return result;
        },
        { ...state }
      );
    }

    default:
      return state;
  }
}
