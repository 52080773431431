import reject from 'lodash/reject';
import {
  RECEIVE_CANCEL_SUBSCRIPTION,
  RECEIVE_CHANGE_SUBSCRIPTION_RENEWAL_BEHAVIOR,
  RECEIVE_ORDER_ITEMS,
  RECEIVE_ORDER,
  RECEIVE_ORDERS,
  REPLACE_ORDER_ITEMS,
  REPLACE_ORDERS,
  RECEIVE_SUBSCRIPTIONS
} from '../constants';

const getOrderWithNoItems = state => it =>
  !state.items_by_order[it.public_id] || !state.items_by_order[it.public_id].length;

const addPrepaidSubscriptionContextToOrder = (state: any) => {
  state.orders = state.orders.map(order => {
    const items = state.items_by_order[order.public_id];
    if (!items || items.length === 0) return { ...order, prepaid_subscription_context: null };
    // note: this code is only here for backwards-compatibility reasons.
    // Orders can contain a mix of prepaid and pay-as-you-go (a.k.a. pay-per-delivery) items,
    // so there is not a correlation between whether an order is prepaid and whether the first item is prepaid.
    // Prepaid status should be determined on a per-item/subscription basis
    const firstItem = items[0];
    const subscription = state.subscriptions.find(sub => sub.public_id === firstItem.subscription);

    return { ...order, prepaid_subscription_context: subscription?.prepaid_subscription_context };
  });

  return state;
};

export default function orders_reducer(state: any = { orders: [], items_by_order: {}, subscriptions: [] }, { type }) {
  const orderWithNoItems = getOrderWithNoItems(state);
  switch (type) {
    case RECEIVE_ORDER_ITEMS:
    case RECEIVE_ORDERS:
    case RECEIVE_ORDER:
    case REPLACE_ORDERS:
    case REPLACE_ORDER_ITEMS:
    case RECEIVE_SUBSCRIPTIONS: // if orders are received before subscriptions, we need to also re-add the context when subscriptions are received
      return addPrepaidSubscriptionContextToOrder(state);
    case RECEIVE_CHANGE_SUBSCRIPTION_RENEWAL_BEHAVIOR:
    case RECEIVE_CANCEL_SUBSCRIPTION:
      if (state.orders.find(orderWithNoItems)) {
        return {
          ...state,
          orders: reject(state.orders, orderWithNoItems)
        };
      }
      return state;
    default:
      return state;
  }
}
