import { validate, submit } from './pause-subscription';

// this is a forked version of the default pause-subscription handler
// instead of a single type="date" input, it expects radio buttons instead

export function initialize(form) {
  const subEl = form.querySelector('[name="subscription"]');
  if (!subEl) {
    throw new Error('Missing <input name="subscription" value="{{ subscription.public_id }}">');
  }
  const orderEl = form.querySelector('[name="order"]');
  if (!orderEl) {
    throw new Error('Missing <input name="order" value="{{ order.public_id }}">');
  }

  // radios are not validated during init because they are rendered inside a {% for %} loop, and they are not yet present in the form when this function runs
}

export { validate, submit };

export default {
  initialize,
  validate,
  submit
};
