import { call, put } from 'redux-saga/effects';

import { RECEIVE_CHANGE_ITEM_QUANTITY } from '../constants';
import { lego_item_change_quantity } from './api';

export default function* REQUEST_CHANGE_ITEM_QUANTITY({
  payload: { item_id, quantity } = {}
}: {
  payload?: { item_id?: string; quantity?: number };
}) {
  const order_item = yield call(lego_item_change_quantity, item_id, quantity);
  yield put({
    type: RECEIVE_CHANGE_ITEM_QUANTITY,
    payload: { result: order_item, quantity: order_item.quantity }
  });
}
