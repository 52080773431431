import { AttributePart, EventPart, directive } from 'lit-html';
import noop from 'lodash/noop';
import debounce from 'lodash/debounce';
import { store } from '../store';

export const registry = new Map();

const defaultValidate = () => {
  true;
};
const defaultInitialize = noop;
const defaultSubmit = noop;

export function registerHandler(key, { validate, initialize, submit }) {
  registry.set(key, {
    validate: validate || defaultValidate,
    initialize: initialize || defaultInitialize,
    submit: submit || defaultSubmit
  });
}

function shouldValidate(form) {
  if (Array.from(form.elements).find(({ nodeName, type }) => nodeName === 'INPUT' && type !== 'hidden')) {
    return true;
  }

  return false;
}

function checkValidity(form) {
  const formKey = form.dataset.ogActionKey;
  if (registry.has(formKey)) {
    const validityResult = registry.get(formKey).validate(new FormData(form), store.getState(), form);
    if (formKey in form) {
      form[formKey].toggleAttribute('disabled', !validityResult.valid);
    }
  }
}

function wrapTaskPromise(task, element) {
  return task
    .toPromise()
    .then(
      () => {
        element.dispatchEvent(new CustomEvent('success'));
      },
      () => element.dispatchEvent(new CustomEvent('failure'))
    )
    .finally(() => {
      element.dispatchEvent(new CustomEvent('finally'));
    });
}

export const mutationRecordIncludesAddedNodes = mutationRecord => !!mutationRecord.addedNodes.length;

function createNodesAddedObserver(action, form) {
  const observer = new MutationObserver(mutationsList => {
    if (mutationsList.find(mutationRecordIncludesAddedNodes)) {
      action(form);
    }
  });
  observer.observe(form, { attributes: false, childList: true, subtree: true });
  return observer;
}

const submitting = new Set();

export function handleFormSubmit(ev) {
  const form = ev.srcElement;
  const formKey = form.dataset.ogActionKey;
  const shouldDisable = form.dataset.disableSubmitter !== 'false';
  const submitter = ev.submitter || form.querySelector('[type=submit]') || form;

  ev.preventDefault();

  // prevent double-submissions
  if (submitting.has(submitter)) return;

  checkValidity(form);
  if (registry.has(formKey)) {
    if (form.reportValidity()) {
      const action = registry.get(formKey);
      if (shouldDisable) {
        submitter.toggleAttribute('disabled', true);
      }
      submitting.add(submitter);

      const formData = new FormData(form);
      if (submitter.name && submitter.value) formData.append(submitter.name, submitter.value);
      const task = action.submit(formData, store, ev);
      Promise.resolve(wrapTaskPromise(task, ev.target)).then(() => {
        submitter.toggleAttribute('disabled', false);
        submitting.delete(submitter);
      });
    }
  }
}

const validateForm = debounce(form => shouldValidate(form) && checkValidity(form), 250);

function handleFormChange(ev) {
  validateForm(ev.currentTarget);
}

function initializeFormAction(form) {
  const formKey = form.dataset.ogActionKey;
  if (registry.has(formKey)) {
    form.addEventListener('submit', handleFormSubmit);
    form.addEventListener('change', handleFormChange);

    registry.get(formKey).initialize(form);
    createNodesAddedObserver(theForm => shouldValidate(theForm) && checkValidity(theForm), form);

    if (shouldValidate(form)) {
      checkValidity(form);
    }
  } else {
    throw new Error(`action key ${formKey} does not exist`);
  }
}

function createEventHandler(...args) {
  return function handleEvent(ev) {
    const element = ev.target;
    const formKey = element.dataset.ogActionKey;
    if (registry.has(formKey)) {
      const action = registry.get(formKey);
      element.toggleAttribute('disabled', true);
      const task = action.submit(element, store, ...args);
      Promise.resolve(wrapTaskPromise(task, element)).then(() => {
        element.toggleAttribute('disabled', false);
      });
    }
  };
}
/**
 * @param {String} formName The name of the form handling the action
 */
export default directive((formName, ...args) => part => {
  const committer = part.committer;
  const element = committer && part.committer.element;

  if (!element || element.dataset.ogActionKey) {
    return;
  }
  element.dataset.ogActionKey = formName || element.name;
  if (
    part instanceof AttributePart &&
    element instanceof HTMLFormElement &&
    ['action', 'onsubmit'].includes(committer.name)
  ) {
    initializeFormAction(element);
  }
  if (part instanceof AttributePart && ['onclick', 'onchange', 'onblur', 'onfocus'].includes(committer.name)) {
    element.addEventListener(committer.name.substr(2), createEventHandler(...args));
  } else if (part instanceof EventPart) {
    element.addEventListener(committer.eventName, createEventHandler(...args));
  }
});
