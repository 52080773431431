import { all, call, put, takeEvery } from 'redux-saga/effects';

import uniq from 'lodash/uniq';
import { lego_payments_list, lego_payments_get } from './api';
import {
  RECEIVE_PAYMENT,
  REQUEST_PAYMENT,
  RECEIVE_PAYMENTS,
  REQUEST_PAYMENTS,
  RECEIVE_ORDERS,
  RECEIVE_ORDER
} from '../constants';
import { takeUnique } from './take-unique';
import { Order } from '..';

const getPayment = order => order.payment;

const requestPayment = id => {
  if (id) {
    return put({ type: REQUEST_PAYMENT, payload: id });
  }
};

export function* watchForRequestPayments() {
  yield all([
    yield takeEvery(REQUEST_PAYMENTS, function* () {
      yield put({ type: RECEIVE_PAYMENTS, payload: yield call(lego_payments_list) });
    }),
    takeUnique(REQUEST_PAYMENT, function* ({ payload }) {
      yield put({ type: RECEIVE_PAYMENT, payload: yield call(lego_payments_get, payload) });
    }),
    takeEvery(RECEIVE_ORDERS, function* ({ payload }: { type: string; payload: { results: Order[] } }) {
      yield all(uniq(payload.results.map(getPayment)).map(requestPayment));
    }),
    takeEvery(RECEIVE_ORDER, function* ({ payload }: { type: string; payload: Order }) {
      yield requestPayment(getPayment(payload));
    })
  ]);
}
