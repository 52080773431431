import { RECEIVE_OFFERS } from '../constants';

// Define the types for State and Action
interface State {
  [productId: string]: string;
}

interface Action {
  type: string;
  payload: {
    module_view?: {
      regular?: string;
    };
    incentives?: {
      [key: string]: any;
    };
  };
}

export default function offer_by_product_id(state: State = {}, action: Action): State {
  switch (action.type) {
    case RECEIVE_OFFERS: {
      const { module_view, incentives = {} } = action.payload;
      const offer = module_view?.regular;

      if (!offer) return state;

      return Object.keys(incentives).reduce((result, productId) => {
        return {
          ...result,
          [productId]: offer
        };
      }, state);
    }

    default:
      return state;
  }
}
