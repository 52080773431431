import { call, put, select } from 'redux-saga/effects';
import { State } from '..';
import { RECEIVE_UPGRADE_SUBSCRIPTION_TO_PREPAID, ERROR_UPGRADE_SUBSCRIPTION_TO_PREPAID } from '../constants';
import { lego_subscription_upgrade_to_prepaid } from './api';

interface SubscriptionUpgradePayload {
  payload: {
    subscription_id: string;
    order_id: string;
    product_id: string;
    orders_per_billing: number;
  };
}

export default function* requestSubscriptionUpgradeToPrepaid({
  payload: { subscription_id, order_id, product_id, orders_per_billing }
}: SubscriptionUpgradePayload) {
  try {
    const subscription = yield call(lego_subscription_upgrade_to_prepaid, subscription_id, orders_per_billing);
    const { products } = (yield select()) as State;
    const product = products.find(p => p.external_product_id === product_id);
    yield put({
      type: RECEIVE_UPGRADE_SUBSCRIPTION_TO_PREPAID,
      payload: { subscription, product, refresh_orders: [order_id] }
    });
  } catch (error) {
    yield put({ type: ERROR_UPGRADE_SUBSCRIPTION_TO_PREPAID });
  }
}
