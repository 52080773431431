import unionBy from 'lodash/unionBy';
import { RECEIVE_PREPAID_INCENTIVES } from '../constants';
import { Incentive } from '../types';

function getMaxRecurringDiscount(incentives: Incentive[]) {
  return Math.max(
    ...incentives
      .filter(incentive => incentive.coupon_type === 'recurring')
      .map(incentive => +incentive.discounts[0].value)
  );
}

type PrepaidIncentiveState = {
  data: Incentive[];
  derived: {
    maxRecurringDiscount?: number;
  };
};

export default function prepaid_incentives(
  state: PrepaidIncentiveState = { data: [], derived: {} },
  { type, payload }
) {
  if (type === RECEIVE_PREPAID_INCENTIVES) {
    const incentives_with_discounts: Incentive[] = payload.results.filter(
      (incentive: Incentive) => incentive.discounts.length > 0
    );
    return {
      data: unionBy(incentives_with_discounts, state.data, 'public_id'),
      derived: {
        maxRecurringDiscount: getMaxRecurringDiscount(incentives_with_discounts ?? [])
      }
    };
  }
  return state;
}
