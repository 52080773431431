import omit from 'lodash/omit';

import { RECEIVE_DELETE_ITEM, RECEIVE_ORDER_ITEMS, RECEIVE_CHANGE_ITEM_QUANTITY } from '../constants';
import { indexBy } from '../utils';

export const mapOrderItem = ({ price, total_price, ...order_item }) => {
  const { quantity = 1 } = order_item;
  return {
    ...order_item,
    price,
    total_price,
    show_original_price: Math.abs(price * quantity - total_price) > 0.0001
  };
};

export default function order_item_by_id(state = {}, action) {
  switch (action.type) {
    case RECEIVE_ORDER_ITEMS:
      return {
        ...state,
        ...indexBy(action.payload.results.map(mapOrderItem), 'public_id')
      };
    case RECEIVE_CHANGE_ITEM_QUANTITY:
      return {
        ...state,
        ...indexBy([action.payload.result].map(mapOrderItem), 'public_id')
      };
    case RECEIVE_DELETE_ITEM:
      return {
        ...omit(state, action.payload.item_id)
      };
    default:
      return state;
  }
}
