import { call, race, select, take, takeEvery } from 'redux-saga/effects';
import { tracking_events_collect } from './api';
import {
  AUTHORIZE,
  CANCEL_DIALOG_CLOSED,
  CANCEL_DIALOG_TRIGGER_CLICKED,
  CANCEL_SAVED_BY_PAUSE,
  CANCEL_SAVED_BY_SKIP,
  RECEIVE_CANCEL_SUBSCRIPTION,
  RECEIVE_CHANGE_PRODUCT,
  RECEIVE_INITIAL_DATA,
  RECEIVE_INITIAL_DATA_ERROR,
  RECEIVE_INITIAL_DATA_TIMEOUT,
  SKU_SWAP_DIALOG_CLOSED,
  SKU_SWAP_DIALOG_TRIGGER_CLICKED,
  SM_LOAD_START,
  UNAUTHORIZED
} from '../constants';
import { State } from '../types';
import { getUserAgent, getTimestamp } from '../utils';

const getCustomer = state => state.customer;

const getSessionId = state => state.session_id;

const getEventContext = state => ({
  merchant_public_id: state.merchant_id,
  merchant_user_id: getCustomer(state)?.sig_field || 'unauthorized_user',
  session_id: getSessionId(state),
  event_timestamp: getTimestamp()
});

export function* ensureAuthorized() {
  const customer = yield select(getCustomer);

  if (!customer) {
    const { unauthorized } = yield race({
      authorize: take(AUTHORIZE),
      unauthorized: take(UNAUTHORIZED)
    });

    if (unauthorized) {
      yield call(handleSmLoadError);
    }
  }
}

export function* requestCollectTrackingEvent({ payload }) {
  try {
    const eventContext = yield select(getEventContext);
    yield call(tracking_events_collect, { ...payload, ...eventContext });
  } catch (error) {
    // Swallow error
  }
}

function* handleSmLoadStart() {
  yield call(requestCollectTrackingEvent, {
    payload: {
      event: 'subscriptionmanager_loading_started',
      event_attributes: { userAgent: getUserAgent() }
    }
  });
}

function* handleSmLoadSuccess() {
  yield call(requestCollectTrackingEvent, {
    payload: {
      event: 'subscriptionmanager_loading_completed',
      event_attributes: { userAgent: getUserAgent() }
    }
  });
}

function* handleSmLoadError() {
  yield call(requestCollectTrackingEvent, {
    payload: {
      event: 'subscriptionmanager_loading_globalerror',
      event_attributes: { userAgent: getUserAgent() }
    }
  });
}

function* handleSkuSwapDialogTriggerClicked({ payload }) {
  const { subscriptions } = (yield select()) as State;
  const { subscription_id } = payload;
  const subscription = subscriptions.find(sub => sub.public_id === subscription_id);
  yield call(requestCollectTrackingEvent, {
    payload: {
      event: 'skuswap_started',
      event_attributes: { subscriptionId: subscription_id, productIdFrom: subscription.product }
    }
  });
}

function* handleSkuSwapDialogClosed({ payload }) {
  const { subscriptions } = (yield select()) as State;
  const { subscription_id } = payload;
  const subscription = subscriptions.find(sub => sub.public_id === subscription_id);
  yield call(requestCollectTrackingEvent, {
    payload: {
      event: 'skuswap_exited',
      event_attributes: { subscriptionId: subscription_id, productIdFrom: subscription.product }
    }
  });
}

function* handleSkuSwapCompleted({ payload }) {
  const { subscription, old_product } = payload;
  yield call(requestCollectTrackingEvent, {
    payload: {
      event: 'skuswap_completed_swapped',
      event_attributes: {
        subscriptionId: subscription.public_id,
        productIdFrom: old_product,
        productIdTo: subscription.product
      }
    }
  });
}

function* handleCancelDialogTriggerClicked({ payload }) {
  yield call(requestCollectTrackingEvent, {
    payload: {
      event: 'subscriptioncancel_started',
      event_attributes: {
        subscriptionId: payload.subscription_id
      }
    }
  });
}

function* handleCancelDialogClosed({ payload }) {
  yield call(requestCollectTrackingEvent, {
    payload: {
      event: 'subscriptioncancel_exited',
      event_attributes: {
        subscriptionId: payload.subscription_id
      }
    }
  });
}

function* handleCancelSubscription({ payload }) {
  const { subscription, cancel_reason } = payload;
  yield call(requestCollectTrackingEvent, {
    payload: {
      event: 'subscriptioncancel_completed_canceled',
      event_attributes: {
        subscriptionId: subscription.public_id,
        cancelReason: cancel_reason
      }
    }
  });
}

function* handleCancelSavedByPause({ payload }) {
  yield call(requestCollectTrackingEvent, {
    payload: {
      event: 'subscriptioncancel_completed_saved_paused',
      event_attributes: {
        subscriptionId: payload.subscription_id
      }
    }
  });
}

function* handleCancelSavedBySkip({ payload }) {
  yield call(requestCollectTrackingEvent, {
    payload: {
      event: 'subscriptioncancel_completed_saved_skipped',
      event_attributes: {
        subscriptionId: payload.subscription_id
      }
    }
  });
}

/**
 * Dev note - when adding or removing events, please update the "Currently Tracked Events" table at
 * packages/smi-core/markdown/front-end-tracking.md to reflect your changes.
 */
const handlers = {
  [SM_LOAD_START]: handleSmLoadStart,
  [RECEIVE_INITIAL_DATA]: handleSmLoadSuccess,
  [RECEIVE_INITIAL_DATA_ERROR]: handleSmLoadError,
  [RECEIVE_INITIAL_DATA_TIMEOUT]: handleSmLoadError,
  [SKU_SWAP_DIALOG_TRIGGER_CLICKED]: handleSkuSwapDialogTriggerClicked,
  [SKU_SWAP_DIALOG_CLOSED]: handleSkuSwapDialogClosed,
  [RECEIVE_CHANGE_PRODUCT]: handleSkuSwapCompleted,
  [CANCEL_DIALOG_TRIGGER_CLICKED]: handleCancelDialogTriggerClicked,
  [CANCEL_DIALOG_CLOSED]: handleCancelDialogClosed,
  [RECEIVE_CANCEL_SUBSCRIPTION]: handleCancelSubscription,
  [CANCEL_SAVED_BY_PAUSE]: handleCancelSavedByPause,
  [CANCEL_SAVED_BY_SKIP]: handleCancelSavedBySkip
};

export function* watchForRequestCollectTrackingEvent() {
  const actionTypes = Object.keys(handlers);

  yield takeEvery(actionTypes, function* (action) {
    /**
     * We want to include merchant_user_id in the event context, so we wait for authorization
     * to either complete or fail before handling events.
     */
    yield call(ensureAuthorized);

    // Verify tracking is enabled
    const smTrackingEnabled = yield select(state => state.settings?.sm_tracking_enabled);
    if (!smTrackingEnabled) {
      return;
    }

    const handler = handlers[action.type];
    if (handler) {
      yield call(handler, action);
    }
  });
}
