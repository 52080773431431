import { call, put } from 'redux-saga/effects';

import { RECEIVE_SEND_PAYMENT_EMAIL } from '../constants';
import { shopify_app_send_payment_email } from './api';

export default function* REQUEST_SEND_PAYMENT_EMAIL({
  payload: { payment_token } = {}
}: {
  payload: { payment_token?: string };
}) {
  yield put({
    type: RECEIVE_SEND_PAYMENT_EMAIL,
    payload: yield call(shopify_app_send_payment_email, payment_token)
  });
}
