import { Dayjs } from 'dayjs';
import { call, put, select } from 'redux-saga/effects';
import { State } from '..';
import { delete_orphaned_items } from './helpers/delete-orphans';
import { RECEIVE_PAUSE_SUBSCRIPTION } from '../constants';
import { lego_subscription_change_next_order_date } from './api';

type PauseSubscriptionPayload = {
  subscription_id: string;
  order_id: string;
  date: Dayjs;
};

export default function* REQUEST_PAUSE_SUBSCRIPTION({
  payload: { subscription_id, order_id, date }
}: {
  payload: PauseSubscriptionPayload;
}) {
  yield call(lego_subscription_change_next_order_date, subscription_id, date);

  // check for orphaned IU items to delete
  const { items_by_order }: State = yield select();
  yield call(delete_orphaned_items, [order_id], items_by_order, subscription_id);

  yield put({
    type: RECEIVE_PAUSE_SUBSCRIPTION,
    payload: { subscription_id, order_id, date }
  });
}
