import { put, call, all, select } from 'redux-saga/effects';
import { RECEIVE_CHANGE_SHIPPING_ADDRESS, RECEIVE_CHANGE_ORDER_SHIPPING } from '../constants';
import { subscriptionsByOrder } from '../selectors';
import { lego_orders_change_shipping, lego_subscriptions_change_shipping } from './api';

export default function* REQUEST_CHANGE_ORDER_ADDRESS_ID({ payload: { order_id, address_id } }) {
  const subscriptions_ids = yield select(subscriptionsByOrder(order_id));

  const response = yield call(lego_orders_change_shipping, order_id, address_id);
  yield put({
    type: RECEIVE_CHANGE_ORDER_SHIPPING,
    payload: {
      current: response,
      previous_order_id: order_id
    }
  });

  if (subscriptions_ids.length) {
    yield all(
      subscriptions_ids.map(subscription_id => call(lego_subscriptions_change_shipping, subscription_id, address_id))
    );
  }

  yield put({ type: RECEIVE_CHANGE_SHIPPING_ADDRESS, payload: { address: { id: address_id } } });
}
