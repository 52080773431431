import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

/**
 * Wraps a lodash equivalen function inito a memoized filter
 * @param {*} filterFn
 * @returns
 */
export default function wrapFilter(filterFn) {
  const fn = memoize(
    (...predicate) =>
      createSelector(
        state => state,
        state => filterFn(state, ...predicate)
      ),
    JSON.stringify
  );
  const res = (list, ...identity) => fn(...identity)(list);
  res.cache = fn.cache;
  return res;
}
