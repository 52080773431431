import reduxCreateSagaMiddleware from 'redux-saga';

export const isPrepaidEligibilityGroup = group => group.group_type === 'eligibility' && group.name === 'prepaid';

export function findBestMatchingLocale(needle, availables = [], defaultNull = false) {
  // return null if no locales; SMI will load with no localized text
  // pass defaultNull if we should return null if no exact match (i18n_display)
  const defaultReturn = defaultNull ? null : availables.length > 0 ? availables[0] : null;

  if (!needle) return defaultReturn;

  // return exact match if available
  if (availables.includes(needle)) return needle;

  // prefer to return 'en-US' for 'en' if available
  if (needle === 'en' && availables.includes('en-US')) return 'en-US';

  return (
    availables.find(key => key.toLowerCase().substring(0, 2) === needle.toLowerCase().substring(0, 2)) || defaultReturn
  );
}

export const parseOrNull = raw => {
  try {
    return JSON.parse(raw);
  } catch (err) {
    return null;
  }
};

export const groupBy = (xs, key) =>
  xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

export const indexBy = (arr, key) => arr.reduce((acc, cur) => ({ ...acc, [cur[key]]: cur }), {});

export const getPayload = ({ payload }) => payload;

export const toCamel = s => {
  return s.replace(/([-_][a-z])/gi, $1 => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const createSagaMiddleware = opts => reduxCreateSagaMiddleware(opts);

export function uniq(list) {
  return Array.from(new Set(list));
}

export function without(list, el) {
  const ix = list.indexOf(el);
  const copy = list.slice();
  if (ix >= 0) copy.splice(ix, 1);
  return copy;
}

export function getUserAgent() {
  return navigator.userAgent;
}

export function getTimestamp() {
  return Math.floor(Date.now() / 1000);
}
