import { call, put, select } from 'redux-saga/effects';
import { lego_addresses_edit, lego_subscriptions_list } from './api';
import { ERROR_SHIPPING_ADDRESS_IS_IN_USE, RECEIVE_DELETE_SHIPPING_ADDRESS } from '../constants';
import { State } from '../types';

export default function* REQUEST_DELETE_SHIPPING_ADDRESS({ payload: { address_id } }) {
  const subscriptionResponse = yield call(lego_subscriptions_list, {
    shipping_address: address_id,
    live: true
  });
  const { orders } = (yield select()) as State;

  // make sure the address is not associated with any current subscriptions or orders
  if (subscriptionResponse.count > 0 || orders.find(o => o.shipping_address === address_id && o.status === 'UNSENT')) {
    yield put({
      type: ERROR_SHIPPING_ADDRESS_IS_IN_USE
    });
    return;
  }

  yield call(lego_addresses_edit, address_id, { live: false });

  yield put({
    type: RECEIVE_DELETE_SHIPPING_ADDRESS,
    payload: {
      public_id: address_id
    }
  });
}
