import mapValues from 'lodash/mapValues';
import { State } from '../types';
import { SET_LOCALE, RECEIVE_PRODUCT, RECEIVE_PRODUCTS } from '../constants';
import { findBestMatchingLocale } from '../utils';

export const mapProductLocale = (locale, product) => {
  const key =
    product.extra_data &&
    product.extra_data.i18n_display &&
    findBestMatchingLocale(locale, Object.keys(product.extra_data.i18n_display), true);
  return {
    ...product,
    // original name, image and detail_url are saved into
    // initial_i18n this needs to be overritten if i18n key is present
    ...product.initial_i18n,
    ...(key && product.extra_data.i18n_display[key])
  };
};

export default function localization_reducer(state: State, action) {
  switch (action.type) {
    case SET_LOCALE:
    case RECEIVE_PRODUCT:
    case RECEIVE_PRODUCTS:
      return {
        ...state,
        product_by_id: mapValues(state.product_by_id, product => mapProductLocale(state.locale, product))
      };
    default:
      return state;
  }
}
