import { call, put } from 'redux-saga/effects';

import { RECEIVE_ORDER_CANCEL } from '../constants';
import { lego_orders_cancel } from './api';

export default function* REQUEST_ORDER_CANCEL({ payload: { order_id } = {} }: { payload: { order_id?: string } }) {
  const response = yield call(lego_orders_cancel, order_id);

  yield put({ type: RECEIVE_ORDER_CANCEL, payload: response });
}
