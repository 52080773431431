import dayjs from 'dayjs';
import requestReactivateSubscription from '@ordergroove/smi-store/sagas/request-reactivate-subscription';
import { sagaMiddleware } from '../core/store';
import { selectSubscription } from './selectors';
import { parseFrequency } from './change-subscription-frequency';

export function initialize(form) {
  const subscriptionEl = form.querySelector('[name="subscription"]');
  if (!subscriptionEl) {
    throw new Error('Missing <input type="hidden" name="subscription" value="{{ subscription.public_id }}">');
  }
  const everyEl = form.querySelector('[name="every"]');
  if (!everyEl) {
    throw new Error('Missing <input type="hidden" name="every" value="{{ subscription.every }}">');
  }
  const everyPeriodEl = form.querySelector('[name="every_period"]');
  if (!everyPeriodEl) {
    throw new Error('Missing <input type="hidden" name="every_period" value="{{ subscription.every_period }}">');
  }
}

/**
 *
 * @param {FormData} data
 * @param {Object} state
 * @returns
 */
export function validate(data, state) {
  const subscription_id = data.get('subscription');

  if (!subscription_id) return { valid: false, field: 'subscription', reason: 'INVALID_SUBSCRIPTION_ID' };

  const subscription = selectSubscription(subscription_id)(state);

  if (typeof subscription === 'undefined')
    return { valid: false, field: 'shipment_date', reason: 'SUBSCRIPTION_NOT_FOUND' };

  return { valid: true };
}

export function submit(formData) {
  const subscriptionId = formData.get('subscription');
  const frequency = formData.get('frequency');
  const { every, every_period: everyPeriod } = frequency
    ? parseFrequency(frequency)
    : {
        every: formData.get('every'),
        every_period: formData.get('every_period')
      };

  // Backwards compatible start date for old templates. Set start date to tomorrow if not provided.
  let startDate = dayjs(formData.get('start_date') || dayjs().add(1, 'day')).format('YYYY-MM-DD');

  // the 0.x template does not let the user choose frequency or first order date
  // the '24 template does
  let firstOrderDate = frequency ? startDate : null;

  const task = sagaMiddleware.run(requestReactivateSubscription, {
    payload: {
      subscription_id: subscriptionId,
      every,
      every_period: everyPeriod,
      start_date: startDate,
      first_order_date: firstOrderDate
    }
  });

  return task;
}

export default {
  validate,
  initialize,
  submit
};
