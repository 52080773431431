import {
  ERROR_CANCEL_SUBSCRIPTION,
  ERROR_CHANGE_PRODUCT,
  ERROR_CHANGE_SHIPMENT_DATE,
  ERROR_CHANGE_SHIPPING_ADDRESS,
  ERROR_CHANGE_ORDER_ADDRESS_ID,
  ERROR_ORDER_CANCEL,
  ERROR_REACTIVATE_SUBSCRIPTION,
  ERROR_SEND_NOW,
  RECEIVE_CANCEL_SUBSCRIPTION,
  RECEIVE_CHANGE_PRODUCT,
  RECEIVE_CHANGE_SHIPMENT_DATE,
  RECEIVE_CHANGE_SHIPPING_ADDRESS,
  RECEIVE_ORDER_CANCEL,
  RECEIVE_CHANGE_SUBSCRIPTION_QUANTITY,
  RECEIVE_UPGRADE_SUBSCRIPTION_TO_PREPAID,
  ERROR_UPGRADE_SUBSCRIPTION_TO_PREPAID,
  RECEIVE_UPGRADE_ONE_TIME_TO_SUBSCRIPTION,
  ERROR_UPGRADE_ONE_TIME_TO_SUBSCRIPTION,
  ERROR_CHANGE_SUBSCRIPTION_QUANTITY,
  RECEIVE_REACTIVATE_SUBSCRIPTION,
  RECEIVE_SEND_NOW,
  RECEIVE_CHANGE_SUBSCRIPTION_FREQUENCY,
  ERROR_CHANGE_SUBSCRIPTION_FREQUENCY,
  RECEIVE_CHANGE_ITEM_QUANTITY,
  ERROR_CHANGE_ITEM_QUANTITY,
  RECEIVE_DELETE_ITEM,
  ERROR_DELETE_ITEM,
  RECEIVE_PAUSE_SUBSCRIPTION,
  ERROR_PAUSE_SUBSCRIPTION,
  RECEIVE_SEND_PAYMENT_EMAIL,
  ERROR_SEND_PAYMENT_EMAIL,
  RECEIVE_CHANGE_SUBSCRIPTION_RENEWAL_BEHAVIOR,
  ERROR_CHANGE_SUBSCRIPTION_RENEWAL_BEHAVIOR,
  RECEIVE_DELETE_SHIPPING_ADDRESS,
  ERROR_SHIPPING_ADDRESS_IS_IN_USE,
  ERROR_DELETE_SHIPPING_ADDRESS,
  RECEIVE_APPLY_DISCOUNT_CODE_TO_ORDER,
  ERROR_APPLY_DISCOUNT_CODE_TO_ORDER,
  ERROR_DISCOUNT_CODE_NOT_APPLICABLE
} from '../constants';
import { PrepaidSubscriptionRenewalBehavior } from '../sagas/request-change-subscription-renewal-behavior';
import { getChangeProductErrorMessage } from '../selectors/errors';
import { mapOrder } from './orders';

export default function notifications(state = [], { type, payload }) {
  const flash: { time: number; message?: string; target?: any; date?: any; error?: boolean } = {
    time: new Date().getTime()
  };
  switch (type) {
    case RECEIVE_CHANGE_SHIPMENT_DATE:
      flash.message = 'change_shipment_date_success';
      flash.target = mapOrder(payload.current);
      break;
    case ERROR_CHANGE_SHIPMENT_DATE:
      flash.message = 'change_shipment_date_error';
      break;
    case RECEIVE_SEND_NOW:
      flash.message = 'send_now_success';
      flash.target = mapOrder(payload);
      break;
    case ERROR_SEND_NOW:
      flash.message = 'send_now_error';
      break;
    case RECEIVE_CANCEL_SUBSCRIPTION:
      flash.message = 'cancel_subscription_success';
      flash.target = payload.subscription;
      break;
    case ERROR_CANCEL_SUBSCRIPTION:
      flash.message = 'cancel_subscription_error';
      break;
    case RECEIVE_REACTIVATE_SUBSCRIPTION:
      flash.message = 'reactivate_subscription_success';
      flash.target = payload.subscription;
      break;
    case ERROR_REACTIVATE_SUBSCRIPTION:
      flash.message = 'reactivate_subscription_error';
      break;
    case RECEIVE_ORDER_CANCEL:
      flash.message = 'order_cancel_success';
      flash.target = payload;
      break;
    case ERROR_ORDER_CANCEL:
      flash.message = 'order_cancel_error';
      break;
    case RECEIVE_CHANGE_PRODUCT:
      flash.message = 'change_product_success';
      flash.target = payload;
      break;
    case ERROR_CHANGE_PRODUCT:
      flash.message = getChangeProductErrorMessage({ error: payload, defaultError: 'change_product_error' });
      break;
    case RECEIVE_CHANGE_SUBSCRIPTION_QUANTITY:
      flash.message = 'change_subscription_quantity_success';
      flash.target = payload.subscription;
      break;
    case RECEIVE_UPGRADE_SUBSCRIPTION_TO_PREPAID:
      flash.message = 'upgrade_subscription_to_prepaid_success';
      flash.target = payload;
      break;
    case ERROR_UPGRADE_SUBSCRIPTION_TO_PREPAID:
      flash.message = 'upgrade_subscription_to_prepaid_error';
      flash.target = payload;
      break;
    case RECEIVE_UPGRADE_ONE_TIME_TO_SUBSCRIPTION:
      flash.message = 'upgrade_one_time_to_subscription_success';
      flash.target = payload;
      break;
    case ERROR_UPGRADE_ONE_TIME_TO_SUBSCRIPTION:
      flash.message = 'upgrade_one_time_to_subscription_error';
      flash.target = payload;
      break;
    case ERROR_CHANGE_SUBSCRIPTION_QUANTITY:
      flash.message = 'change_subscription_quantity_error';
      break;
    case RECEIVE_CHANGE_SHIPPING_ADDRESS:
      flash.message = 'change_shipping_address_success';
      flash.target = payload;
      break;
    case ERROR_CHANGE_SHIPPING_ADDRESS:
    case ERROR_CHANGE_ORDER_ADDRESS_ID:
      flash.message = 'change_shipping_address_error';
      break;
    case RECEIVE_DELETE_SHIPPING_ADDRESS:
      flash.message = 'delete_shipping_address_success';
      break;
    case ERROR_SHIPPING_ADDRESS_IS_IN_USE:
      flash.message = 'delete_shipping_address_error_in_use';
      break;
    case ERROR_DELETE_SHIPPING_ADDRESS:
      flash.message = 'delete_shipping_address_error';
      break;
    case RECEIVE_CHANGE_SUBSCRIPTION_FREQUENCY:
      flash.message = 'change_subscription_frequency_success';
      flash.target = payload.subscription;
      break;
    case ERROR_CHANGE_SUBSCRIPTION_FREQUENCY:
      flash.message = 'change_subscription_frequency_error';
      break;
    case RECEIVE_CHANGE_SUBSCRIPTION_RENEWAL_BEHAVIOR:
      if (
        payload.subscription?.prepaid_subscription_context.renewal_behavior ===
        PrepaidSubscriptionRenewalBehavior.AUTORENEW
      ) {
        flash.message = 'change_subscription_renewal_behavior_to_renew_success';
      } else {
        flash.message = 'change_subscription_renewal_behavior_to_not_renew_success';
      }
      flash.target = payload.subscription;
      break;
    case ERROR_CHANGE_SUBSCRIPTION_RENEWAL_BEHAVIOR:
      flash.message = 'change_subscription_renewal_behavior_error';
      break;
    case RECEIVE_CHANGE_ITEM_QUANTITY:
      flash.message = 'change_one_time_quantity_success';
      flash.target = payload;
      break;
    case ERROR_CHANGE_ITEM_QUANTITY:
      flash.message = 'change_one_time_quantity_error';
      flash.target = payload;
      break;
    case RECEIVE_DELETE_ITEM:
      flash.message = payload.subscription ? 'delete_subscription_item_success' : 'delete_one_time_item_success';
      flash.target = payload;
      break;
    case ERROR_DELETE_ITEM:
      flash.message = payload.subscription ? 'delete_subscription_item_error' : 'delete_one_time_item_error';
      flash.target = payload;
      break;
    case RECEIVE_PAUSE_SUBSCRIPTION:
      flash.message = 'pause_subscription_success';
      flash.date = payload.date;
      break;
    case ERROR_PAUSE_SUBSCRIPTION:
      flash.message = 'pause_subscription_error';
      break;
    case RECEIVE_SEND_PAYMENT_EMAIL:
      flash.message = 'change_billing_send_email_success';
      break;
    case ERROR_SEND_PAYMENT_EMAIL:
      flash.message = 'change_billing_send_email_error';
      break;
    case RECEIVE_APPLY_DISCOUNT_CODE_TO_ORDER:
      flash.message = 'apply_discount_code_success';
      flash.target = { discount_code: payload.discount_code };
      break;
    case ERROR_APPLY_DISCOUNT_CODE_TO_ORDER:
      flash.message = 'apply_discount_code_error';
      break;
    case ERROR_DISCOUNT_CODE_NOT_APPLICABLE:
      flash.message = 'apply_discount_code_not_applicable';
      break;
    default:
  }
  if (flash.message) {
    if (type.startsWith('ERROR')) flash.error = payload;
    return [...state, flash];
  }
  return state;
}
