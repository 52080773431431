import { select, call, put } from 'redux-saga/effects';
import { State } from '../types';
import { lego_subscription_apply_discount_code } from './api';
import { UnexpectedApiResponseError } from './make-api';
import {
  ERROR_APPLY_DISCOUNT_CODE_TO_ORDER,
  ERROR_DISCOUNT_CODE_NOT_APPLICABLE,
  RECEIVE_APPLY_DISCOUNT_CODE_TO_ORDER
} from '../constants';

type ApplyDiscountCodePayload = {
  order: string;
  discount_code: string;
};

export default function* REQUEST_APPLY_DISCOUNT_CODE_TO_ORDER({
  payload: { order, discount_code }
}: {
  payload: ApplyDiscountCodePayload;
}) {
  const { items_by_order }: State = yield select();
  const orderItems = items_by_order[order] || [];
  const subscriptions = orderItems.map(item => item.subscription).filter(subscription => subscription);

  let successful = 0;
  let notApplicable = 0;

  for (const subscriptionId of subscriptions) {
    try {
      yield call(lego_subscription_apply_discount_code, subscriptionId, discount_code);
      successful++;
    } catch (e) {
      // discount code is not eligible; swallow the error
      if (e instanceof UnexpectedApiResponseError && e.status === 422) {
        notApplicable++;
      }
    }
  }

  if (successful > 0) {
    yield put({
      type: RECEIVE_APPLY_DISCOUNT_CODE_TO_ORDER,
      payload: {
        discount_code
      }
    });
  } else if (notApplicable > 0) {
    yield put({
      type: ERROR_DISCOUNT_CODE_NOT_APPLICABLE
    });
  } else {
    yield put({
      type: ERROR_APPLY_DISCOUNT_CODE_TO_ORDER
    });
  }
}
