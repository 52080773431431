import { take, fork } from 'redux-saga/effects';

const TTL = 15000; // 15 seconds
/**
 * Take unique actions (by serializing arguments) in a time window of 15 seconds
 * This take* makes sure repeated actions doesnt re execute in timewindow
 *
 * @param {*} patternOrChannel
 * @param {*} saga
 * @param  {...any} args
 * @returns
 */
export function takeUnique(patternOrChannel, saga, ...args) {
  return fork(function* () {
    const tasks = new Map();
    while (true) {
      const action = yield take(patternOrChannel);
      const key = JSON.stringify(action.payload);
      if (!tasks.has(key)) {
        tasks.set(key, yield fork(saga, ...args.concat(action)));
        setTimeout(() => tasks.delete(key), TTL);
      }
    }
  });
}
