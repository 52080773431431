import { call, put, all, delay } from 'redux-saga/effects';
import { DEFAULT_PAGE_SIZE } from '../constants';

export const INITIAL_DELAY = 1500;

export function* request_all_of(
  api_fn: (...any) => any,
  api_fn_args: object,
  receive_action_type = null,
  page_size: number = DEFAULT_PAGE_SIZE,
  initial_delay: number = INITIAL_DELAY
) {
  const get_page = page => call(api_fn, { page_size, ...(page > 1 && { page }), ...api_fn_args });

  const page1 = yield get_page(1);

  if (receive_action_type) yield put({ type: receive_action_type, payload: page1 });

  let all_results = [...page1.results];
  if (page1.count > page_size) {
    const total_pages = Math.ceil(page1.count / page_size);

    if (initial_delay > 0) yield delay(initial_delay);

    const responses = yield all(
      Array(total_pages - 1)
        .fill('')
        .map((it, ix) => get_page(ix + 2))
    );

    const next_results = responses.reduce((acc, cur) => acc.concat(cur.results), []);
    if (receive_action_type)
      yield put({
        type: receive_action_type,
        payload: { results: next_results }
      });
    all_results = all_results.concat(next_results);
  }
  return {
    results: all_results
  };
}

export default request_all_of;
