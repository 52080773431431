import { Dayjs } from 'dayjs';
import { makeApi } from './make-api';

export const api = makeApi({
  shopify_app: {
    send_payment_email: {
      send: {
        method: 'POST',
        success_status: 200,
        path: '/api/send-smi-update-payment-email',
        body: payment_token => ({
          paymentId: payment_token
        })
      }
    },
    shop_settings: {
      get: {
        ignore_errors: true,
        path: 'api/shop-settings'
      }
    }
  },
  lego: {
    addresses: {
      get: { path: id => `addresses/${id}` },
      list: { path: 'addresses' },
      create: {
        method: 'POST',
        path: `/addresses/create/`,
        body: payload => payload
      },
      edit: {
        method: 'PATCH',
        path: id => `/addresses/${id}/update/`,
        body: (id, payload) => ({ ...payload })
      },
      use_for_all: {
        method: 'POST',
        success_status: 204,
        path: id => `addresses/${id}/use_for_all/`
      }
    },
    payments: { list: { path: 'payments' }, get: { path: id => `payments/${id}` } },
    items: {
      list: { path: 'items' },
      delete: {
        path: item_id => `/items/${item_id}/delete/`
      },
      change_quantity: {
        method: 'PATCH',
        path: item_id => `/items/${item_id}/change_quantity`,
        body: (item_id, quantity) => ({ quantity })
      }
    },
    orders: {
      list: { path: 'orders' },
      get: { path: order_id => `/orders/${order_id}/` },
      send_now: {
        method: 'PATCH',
        path: order_id => `/orders/${order_id}/send_now/`
      },
      change_place_date: {
        method: 'PATCH',
        path: order_id => `/orders/${order_id}/change_place_date/`,
        body: (order_id, place) => ({ place })
      },
      change_shipping: {
        path: order => `/orders/${order}/change_shipping/`,
        method: 'PATCH',
        body: (order, address) => ({ shipping_address: address })
      },
      cancel: {
        path: order => `/orders/${order}/cancel/`,
        method: 'PATCH'
      },
      skip_subscription: {
        path: (order, _subscription_id) => `/orders/${order}/skip_subscription/`,
        method: 'PATCH',
        body: (order, subscription_id) => ({ subscription: subscription_id })
      }
    },
    subscriptions: {
      list: { path: 'subscriptions' },
      cancel: {
        method: 'PATCH',
        path: subscription_id => `/subscriptions/${subscription_id}/cancel/`,
        body: (subscription_id, cancel_reason) => ({ cancel_reason })
      },
      change_shipping: {
        path: subscription => `/subscriptions/${subscription}/change_shipping/`,
        method: 'PATCH',
        body: (order, address) => ({ shipping_address: address })
      },
      reactivate: {
        method: 'PATCH',
        path: subscription_id => `/subscriptions/${subscription_id}/reactivate/`,
        body: (subscription_id, start_date, every, every_period) => ({
          start_date: start_date,
          every: every,
          every_period: every_period
        })
      },
      change_next_order_date: {
        method: 'PATCH',
        path: subscription_id => `/subscriptions/${subscription_id}/change_next_order_date`,
        body: (subscription_id: string, date: Dayjs) => ({
          order_date: date.format('YYYY-MM-DD')
        })
      },
      change_product: {
        method: 'PATCH',
        path: subscription_id => `/subscriptions/${subscription_id}/change_product/`,
        body: (subscription_id, product) => ({ product })
      },
      change_quantity: {
        method: 'PATCH',
        path: subscription_id => `/subscriptions/${subscription_id}/change_quantity/`,
        body: (subscription_id, quantity) => ({ quantity })
      },
      change_frequency: {
        method: 'PATCH',
        path: subscription_id => `/subscriptions/${subscription_id}/change_frequency/`,
        body: (subscription_id, every, every_period) => ({ every, every_period })
      },
      change_renewal_behavior: {
        method: 'PATCH',
        path: subscription_id => `/subscriptions/${subscription_id}/change_renewal_behavior/`,
        body: (subscription_id, renewal_behavior) => ({ renewal_behavior })
      },
      get_discount_codes: {
        method: 'GET',
        path: subscription_id => `/subscriptions/${subscription_id}/discount_codes/`,
        success_status: [200, 400] // This endpoint can return 400 if a subscription has no discount codes on account of it not having a Shopify contract
      },
      upgrade_to_prepaid: {
        method: 'PATCH',
        path: subscription_id => `/subscriptions/${subscription_id}/upgrade_to_prepaid/`,
        body: (subscription_id, orders_per_billing, offer = 'live') => ({ orders_per_billing, offer })
      },
      upgrade_from_one_time: {
        method: 'POST',
        path: `/subscriptions/create_from_item/`,
        body: (item, every, every_period, offer) => ({ item, every, every_period, offer })
      },
      apply_discount_code: {
        method: 'PATCH',
        path: subscription_id => `/subscriptions/${subscription_id}/apply_discount_code`,
        body: (subscription_id, discount_code) => ({ discount_code }),
        success_status: 204
      }
    },
    products: {
      list: { path: '/products/' },
      get: { path: id => `/products/${id}/` }
    },
    prepaid_incentives: {
      list: { path: '/advanced_incentives/prepaid/list_by_offer/live' }
    }
  },
  offers: {
    offers: {
      get: { path: (args, merchant_id, module) => `/offer/${merchant_id}/${module}` }
    }
  },
  tracking: {
    events: {
      collect: {
        method: 'POST',
        ignore_errors: true,
        path: `/c/`,
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
        body: event_payload => event_payload,
        success_status: 200
      }
    }
  }
});

export const lego_addresses_get = api.lego.addresses.get;
export const lego_addresses_list = api.lego.addresses.list;
export const lego_addresses_create = api.lego.addresses.create;
export const lego_addresses_edit = api.lego.addresses.edit;
export const lego_addresses_use_for_all = api.lego.addresses.use_for_all;

export const lego_payments_list = api.lego.payments.list;
export const lego_payments_get = api.lego.payments.get;

export const lego_items_list = api.lego.items.list;
export const lego_items_delete = api.lego.items.delete;

export const lego_item_change_quantity = api.lego.items.change_quantity;

export const lego_orders_list = api.lego.orders.list;
export const lego_orders_get = api.lego.orders.get;
export const lego_orders_send_now = api.lego.orders.send_now;
export const lego_orders_cancel = api.lego.orders.cancel;
export const lego_orders_change_place_date = api.lego.orders.change_place_date;
export const lego_orders_change_shipping = api.lego.orders.change_shipping;
export const lego_orders_skip_subscription = api.lego.orders.skip_subscription;

export const lego_subscriptions_list = api.lego.subscriptions.list;
export const lego_subscriptions_cancel = api.lego.subscriptions.cancel;
export const lego_subscriptions_change_product = api.lego.subscriptions.change_product;
export const lego_subscriptions_change_shipping = api.lego.subscriptions.change_shipping;
export const lego_subscriptions_reactivate = api.lego.subscriptions.reactivate;
export const lego_subscriptions_change_quantity = api.lego.subscriptions.change_quantity;
export const lego_subscriptions_change_frequency = api.lego.subscriptions.change_frequency;
export const lego_subscription_change_renewal_behavior = api.lego.subscriptions.change_renewal_behavior;
export const lego_subscription_upgrade_to_prepaid = api.lego.subscriptions.upgrade_to_prepaid;
export const lego_subscription_upgrade_from_one_time = api.lego.subscriptions.upgrade_from_one_time;
export const lego_subscription_change_next_order_date = api.lego.subscriptions.change_next_order_date;
export const lego_subscription_get_discount_codes = api.lego.subscriptions.get_discount_codes;
export const lego_subscription_apply_discount_code = api.lego.subscriptions.apply_discount_code;
export const lego_products_get = api.lego.products.get;
export const lego_products_list = api.lego.products.list;
export const lego_prepaid_incentives_list = api.lego.prepaid_incentives.list;

export const offers_offers_get = api.offers.offers.get;

export const tracking_events_collect = api.tracking.events.collect;

export const shopify_app_send_payment_email = api.shopify_app.send_payment_email.send;
export const shopify_app_shop_settings_get = api.shopify_app.shop_settings.get;
