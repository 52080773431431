import {
  SKU_SWAP_DIALOG_TRIGGER_CLICKED,
  SKU_SWAP_DIALOG_CLOSED,
  CANCEL_DIALOG_TRIGGER_CLICKED,
  CANCEL_DIALOG_CLOSED,
  CANCEL_SAVED_BY_PAUSE,
  CANCEL_SAVED_BY_SKIP
} from '@ordergroove/smi-store/constants';
import { mergeWith } from 'lodash';
import { Store } from './core/store';

/**
 * These are the selectors used to trigger tracking events
 * These selectors should support as many template versions as possible, since we try to keep all merchants on the same smi-core, regardless of their template version
 * When writing a selector, prefer data- and other attributes that are not likely to change, as opposed to CSS styling classes and elements that may change.
 * Due to backwards-compatibility requirements, you may need to write one selector using existing CSS classes and another using data- attributes present in the latest template version.
 */

const V0_SELECTORS = {
  [SKU_SWAP_DIALOG_TRIGGER_CLICKED]: ['.og-change-product-control > .og-link'],
  [SKU_SWAP_DIALOG_CLOSED]: ['dialog[id^="og-change-product"] button[type="reset"]'],
  [CANCEL_DIALOG_TRIGGER_CLICKED]: ['.og-cancel-subscription-button > .og-link'],
  [CANCEL_DIALOG_CLOSED]: ['.og-cancel-subscription-button button[type="reset"]'],
  [CANCEL_SAVED_BY_PAUSE]: [], // not an option in the v0 template
  [CANCEL_SAVED_BY_SKIP]: ['.og-cancel-subscription-button button[name="skip_subscription"]']
};

const V25_SELECTORS = {
  [SKU_SWAP_DIALOG_TRIGGER_CLICKED]: ['[data-click-target^=og-sku-swap-dialog]'],
  [SKU_SWAP_DIALOG_CLOSED]: [
    // version before relevant data attributes added
    '[id^=og-sku-swap-dialog] :is(.og-dialog-close, .og-dialog-footer button[type="button"])',
    // version after relevant data attributes added
    '[id^=og-sku-swap-dialog] [data-dialog-close]'
  ],
  [CANCEL_DIALOG_TRIGGER_CLICKED]: ['[data-click-target^=og-cancel-sub-dialog]'],
  [CANCEL_DIALOG_CLOSED]: [
    // version before data-dialog-close added
    '[id^=og-cancel-sub-dialog] .og-dialog-close',
    // version after data-dialog-close added
    '[id^=og-cancel-sub-dialog] [data-dialog-close]'
  ],
  [CANCEL_SAVED_BY_PAUSE]: [
    // this needs to be a selector since we only want the pauses from the cancel flow (we can't get all RECEIVE_PAUSE_SUBSCRIPTION)
    '[id^=og-cancel-sub-dialog] [data-step="pause"] button[type="submit"]'
  ],
  [CANCEL_SAVED_BY_SKIP]: [] // not an option in the v25 template
};

function getSubscriptionId(element: HTMLElement) {
  return (
    // v25
    element.closest<HTMLElement>('[data-subscription-id]')?.dataset.subscriptionId ||
    // v0
    element.closest<HTMLElement>('[og-subscription-id]')?.getAttribute('og-subscription-id')
  );
}

// merge the tracking selectors into a single object
export const trackingSelectors = mergeWith(V25_SELECTORS, V0_SELECTORS, (arr1: string[], arr2: string[]) =>
  arr1.concat(arr2)
);

function action(type: string, payload = {}) {
  return {
    type,
    payload
  };
}

export function handleTrackingEvent(type: string, element: HTMLElement, store: Store) {
  switch (type) {
    case SKU_SWAP_DIALOG_TRIGGER_CLICKED:
    case SKU_SWAP_DIALOG_CLOSED:
    case CANCEL_DIALOG_TRIGGER_CLICKED:
    case CANCEL_DIALOG_CLOSED:
    case CANCEL_SAVED_BY_PAUSE:
    case CANCEL_SAVED_BY_SKIP: {
      store.dispatch(
        action(type, {
          subscription_id: getSubscriptionId(element)
        })
      );
      break;
    }
  }
}
