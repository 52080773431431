import memoize from 'lodash/memoize';
import { RECEIVE_PRODUCTS, RECEIVE_PRODUCT, RECEIVE_SUBSCRIPTIONS } from '../constants';
import { Product, Subscription, ProductGroup } from '../types';
import { SkuSwapState, isSkuGroup, isSkuSwapEligible } from './sku_swap';
import { isPrepaidEligibilityGroup } from '../utils';

const isAutoshipEnabled = (p: Product): boolean => p.autoship_enabled;

// returns items that are in list1 and list2
function intersection<T>(list1: T[], list2: T[]) {
  if (list1.length === 0 || list2.length === 0) {
    return [];
  }
  const list2AsSet = new Set(list2);
  return list1.filter(value => list2AsSet.has(value));
}

const getSkuSwapGroupNames = (groups: ProductGroup[]) =>
  groups.reduce<string[]>((listOfNames, productGroup) => {
    if (isSkuGroup(productGroup)) {
      listOfNames.push(productGroup.name);
    }
    return listOfNames;
  }, []);

const isProductInMatchingSkuSwapGroup = memoize(
  (product: Product, subscriptionProduct: Product) => {
    const productSkuSwapGroupNames = getSkuSwapGroupNames(product.groups);
    const subscriptionProductSkuSwapGroupNames = getSkuSwapGroupNames(subscriptionProduct.groups);
    return (
      product.external_product_id !== subscriptionProduct.external_product_id &&
      intersection(productSkuSwapGroupNames, subscriptionProductSkuSwapGroupNames).length > 0
    );
  },
  // if we pass the same products to this, cache and return the result
  // this assumes that a product's SKU Swap groups won't change for the duration of a session
  (product: Product, subscriptionProduct: Product) =>
    `${product.external_product_id},${subscriptionProduct.external_product_id}`
);

const isProductSkuSwapEligibleForSubscription = memoize(
  (product: Product, subscription: Subscription, subscriptionProduct: Product) => {
    const prepaidContext = subscription.prepaid_subscription_context;

    if (!prepaidContext) {
      return true;
    }

    const { prepaid_orders_remaining: ordersRemaining } = prepaidContext;

    return (
      product.groups.find(isPrepaidEligibilityGroup) &&
      (ordersRemaining === 0 || (ordersRemaining > 0 && +product.price <= +subscriptionProduct.price))
    );
  },
  // if we pass the same products and subscription to this, cache and return the result
  // we intentionally check the subscriptionProduct as well -- if the product associated with a subscription changes, then we need to re-run this
  (product: Product, subscription: Subscription, subscriptionProduct: Product) =>
    `${product.external_product_id},${subscription.public_id},${subscriptionProduct.external_product_id}`
);

/**
 * Get a filtered list of products eligible to be sku-swapped with the subscription's product.
 */
function getEligibleProductsForSubscription(subscription: Subscription, autoshipEnabledProducts: Product[]) {
  // OOS subscription products are still eligible for sku swap, so long as eligible alternative products exist
  const matchingProduct = autoshipEnabledProducts.find(p => p.external_product_id === subscription.product);
  if (!matchingProduct) {
    return [];
  }

  const skuSwapEligibleProducts = autoshipEnabledProducts.filter(
    product =>
      isSkuSwapEligible(product) &&
      isProductInMatchingSkuSwapGroup(product, matchingProduct) &&
      isProductSkuSwapEligibleForSubscription(product, subscription, matchingProduct)
  );

  const sortedSkuSwapEligibleProductIds = skuSwapEligibleProducts
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(p => p.external_product_id);

  return sortedSkuSwapEligibleProductIds;
}

function createSubscriptionSkuSwapMap(subscriptions: Subscription[], allProducts: Product[]) {
  if (!subscriptions || !allProducts) {
    return {};
  }

  const autoshipEnabledProducts = allProducts.filter(isAutoshipEnabled);

  return subscriptions.reduce<{ [T: string]: string[] }>((result, subscription) => {
    const subscriptionProducts = getEligibleProductsForSubscription(subscription, autoshipEnabledProducts);
    if (subscriptionProducts.length > 0) {
      result[subscription.public_id] = subscriptionProducts;
    }
    return result;
  }, {});
}

export default function sku_swap_subscriptions(
  state: { subscriptions: Subscription[]; products: Product[]; sku_swap: SkuSwapState } = {
    subscriptions: [],
    products: [],
    sku_swap: { requested: {}, groups: {}, products: {}, subscriptions: {} }
  },
  { type }
) {
  switch (type) {
    case RECEIVE_PRODUCTS:
    case RECEIVE_PRODUCT:
    case RECEIVE_SUBSCRIPTIONS:
      return {
        ...state,
        sku_swap: {
          ...state.sku_swap,
          // this is derived state - we recreate the map each time
          subscriptions: createSubscriptionSkuSwapMap(state.subscriptions, state.products)
        }
      };
    default:
      return state;
  }
}
