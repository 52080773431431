import { RECEIVE_ORDER_ITEMS } from '../constants';

export default function product_id_by_subscription(state = {}, action) {
  if (action.type === RECEIVE_ORDER_ITEMS)
    return {
      ...state,
      ...Object.fromEntries(action.payload.results.map(({ subscription, product }) => [subscription, product]))
    };
  return state;
}
