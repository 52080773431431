import { REQUEST_PRODUCTS, RECEIVE_PRODUCTS, RECEIVE_PRODUCT } from '../constants';
import { Product, ProductGroup } from '../types';
import { uniq, without } from '../utils';

export const isSkuGroup = (g: ProductGroup): boolean => g.group_type === 'sku_swap';
export const isSkuSwapEligible = (p: Product): boolean => p.autoship_enabled && p.live;

function reduceGroups(state: { [T: string]: string[] } = {}, product: Product) {
  return (
    product.groups?.filter(isSkuGroup).reduce(
      (acc, { name }) => ({
        ...acc,
        [name]: (acc[name] || []).concat(
          acc[name] && acc[name].includes(product.external_product_id) ? [] : product.external_product_id
        )
      }),
      state
    ) || state
  );
}

/**
 * calculates the alternatives for each product in results keeping the groups data and initial too.
 * @param initial
 * @param productsInGroups
 * @param newProducts
 * @returns
 */
function calculateProducts(
  initial: IndexOfAlternativesIds,
  productsInGroups: IndexOfAlternativesIds,
  newProducts: Product[]
): IndexOfAlternativesIds {
  const result = { ...initial };

  for (const product of newProducts) {
    for (const group of product.groups) {
      if (group.name in productsInGroups) {
        const currentGroup = productsInGroups[group.name];

        const alternatives = without(
          uniq([...(result[product.external_product_id] || []), ...currentGroup]),
          product.external_product_id
        );

        result[product.external_product_id] = alternatives;

        for (const alt of without(currentGroup, product.external_product_id)) {
          result[alt] = without(uniq([...(result[alt] || []), ...currentGroup]), alt);
        }
      }
    }
  }

  return result;
}
type IndexOfAlternativesIds = { [k: string]: string[] };
export type SkuSwapState = {
  requested: object;
  /**
   * Object containing group names as key and array of eligible product ids to swap to
   */
  groups: IndexOfAlternativesIds;
  /**
   * @deprecated
   * Object containing product id as key and array of eligible product ids to swap to.
   * This will not properly track eligibility for prepaid subscriptions. Instead, `subscriptions` should be used.
   * This property is only kept for backwards compatibility.
   */
  products: IndexOfAlternativesIds;
  /**
   * Object containing subscription id as key and array of eligible product ids to swap to
   */
  subscriptions: IndexOfAlternativesIds;
};

/**
 * Reduces the state adding sku swap data as
 *  - requested: [] groups being requested for sku
 *  - groups availables
 *  - products: {} hash containing { product_id: [alternatives] }
 *  - subscriptions: {} hash containing { subscription_id: [alternatives ]}
 * subscriptions is set in the higher-order sku_swap_subscriptions reducer since it needs access to the list of subscriptions.
 * @param state
 * @param action
 * @returns
 */
export default function sku_swap(
  state: SkuSwapState = { requested: {}, groups: {}, products: {}, subscriptions: {} },
  action
): SkuSwapState {
  if (action.type === REQUEST_PRODUCTS) {
    return { ...state, requested: { ...state.requested, [action.payload.group_name]: true } };
  }
  if (action.type === RECEIVE_PRODUCTS) {
    const results: Product[] = action.payload.results;
    const groups = results.filter(isSkuSwapEligible).reduce(reduceGroups, state.groups || {});
    return {
      ...state,
      groups,
      products: calculateProducts(state.products, groups, results)
    };
  }
  if (action.type === RECEIVE_PRODUCT && isSkuSwapEligible(action.payload)) {
    const product = action.payload as Product;
    const groups = reduceGroups(state.groups, product);

    return {
      ...state,
      groups,
      products: calculateProducts(state.products, groups, [product])
    };
  }

  return state;
}
