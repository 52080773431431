import * as constants from '../constants';

export default function customer(state = false, action) {
  switch (action.type) {
    case constants.AUTHORIZE:
      return {
        ...action.payload,
        authorized: true
      };
    case constants.UNAUTHORIZED:
      return false;
    default:
      return state;
  }
}
