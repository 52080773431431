import { call, put, takeLatest } from 'redux-saga/effects';

import { RECEIVE_PREPAID_INCENTIVES, REQUEST_PREPAID_INCENTIVES } from '../constants';
import { lego_prepaid_incentives_list } from './api';

export function* requestPrepaidIncentives() {
  let payload;
  try {
    payload = yield call(lego_prepaid_incentives_list);
  } catch (err) {
    console.error(err);
    payload = { results: [] };
  } finally {
    yield put({ type: RECEIVE_PREPAID_INCENTIVES, payload });
  }
}

export function* watchForRequestPrepaidIncentives() {
  yield takeLatest(REQUEST_PREPAID_INCENTIVES, requestPrepaidIncentives);
}
