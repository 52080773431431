import requestOrderCancel from '@ordergroove/smi-store/sagas/request-order-cancel';
import { sagaMiddleware } from '../core/store';
import { publicId, selectOrder } from './selectors';

export function submit(formData) {
  const order_id = formData.get('order');
  const task = sagaMiddleware.run(requestOrderCancel, {
    payload: {
      order_id: publicId(order_id)
    }
  });
  return task;
}

export function initialize(form) {
  const orderEl = form.querySelector('[name="order"]');
  if (!orderEl) {
    throw new Error('Missing <input name="order" value="{{ order.public_id }}">');
  }
}

/**
 *
 * @param {FormData} data
 * @param {Object} state
 * @returns
 */
export function validate(data, state) {
  const order_id = data.get('order');

  if (!order_id) return { valid: false, field: 'order', reason: 'INVALID_ORDER_ID' };

  const order = selectOrder(order_id)(state);

  if (typeof order === 'undefined') return { valid: false, field: 'order', reason: 'ORDER_NOT_FOUND' };

  return { valid: true };
}

export default {
  validate,
  initialize,
  submit
};
