import * as constants from '../constants';

export default function setEnvironment(env) {
  switch (env) {
    case 'dev':
      return {
        type: constants.SET_ENVIRONMENT_DEV,
        payload: env
      };
    case 'staging':
      return {
        type: constants.SET_ENVIRONMENT_STAGING,
        payload: env
      };
    case 'prod':
      return {
        type: constants.SET_ENVIRONMENT_PROD,
        payload: env
      };
    default:
      throw new Error(`${env} is not a supported environment`);
  }
}
