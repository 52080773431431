import deleteShippingAddress from '@ordergroove/smi-store/sagas/request-delete-address';

import { sagaMiddleware } from '../core/store';

export function initialize(form) {
  const addressEl = form.querySelector('[name="address_id"]');
  if (!addressEl) {
    throw new Error('Missing <input type="hidden" name="address_id" value="{{ address.public_id }}">');
  }
}

/**
 *
 * @param {FormData} data
 * @param {Object} state
 * @returns
 */
export function validate(data, state, form) {
  return { valid: form.checkValidity() };
}

export function submit(formData) {
  const task = sagaMiddleware.run(deleteShippingAddress, {
    payload: {
      address_id: formData.get('address_id')
    }
  });
  return task;
}

export default {
  validate,
  initialize,
  submit
};
