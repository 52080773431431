/**
 *
 * Every Subscription Manager template has access to objects. These objects represent the data that is
 * stored in Ordergroove. For example, the customer's upcoming orders, subscriptions,
 * addresses, etc. You can find a full list of all available objects in the Redux State section.
 *
 *
 * | Name          | Type   | Desc
 * | ------------- | ------ | ----
 * | locale        | String | This value will represent the current locale being set on the website. The value is pulled form the lang attribute of the html node. For example `<html lang='en'>`
 * | customer      | Object | This object represents the current customer who is logged into your site that is viewing the SMI
 * | merchant_id   | String | This value is used to identify you as the merchant and is the same value that you would find under the customer.public_id key above.
 * | environment   | Object | This object represents the environment that the application is currently running in. You typically will not need to interact with this object unless you're instructed to by an Ordergroove representative.
 * | orders        | Array  | This array represents the list of all of the customer's upcoming orders. Each item in this list is an order object and contains the following fields
 * | items         | Array  | This array represents the list of all of the customer's orders.
 * | subscriptions | Array  | This array represents the list of all of the customer's subscriptions.
 * | products      | Array  | This array represents the list of products pulled into the state for current subscriptions and order items
 * | addresses     | Array  | A list of all customer addresses pulled into the state
 * | payments      | Array  | A list of all customer payments pulled into the state
 *
 * @module Objects
 */
import { createStore, compose, applyMiddleware } from 'redux';
import reducer from '@ordergroove/smi-store/reducers';

/**@ignore */
import { default as createSagaMiddleware, SagaMiddleware } from 'redux-saga';

import rootSaga from '@ordergroove/smi-store/sagas';
import * as constants from '@ordergroove/smi-store/constants';
import { State } from '@ordergroove/smi-store/types';

export * from '@ordergroove/smi-store/types';
/**
 *
 * The Redux store the whole state tree of Subscription Manager application.
 * The only way to change the state inside it is to dispatch an action by calling
 * dispatch method or running a specific saga middleware using the dispatch method.
 *
 * NOTE: Dispatches are not considered part of Ordergroove's public API. **Custom development using dispatches is subject to break without notice**.
 *
 * As documented in the API section, the Subscription Manager store is accessible through `window.og.smi.store`.
 * @category Redux State
 */
export interface Store {
  /**
   * Adds a change listener. It will be called any time an action is dispatched, and some part of the state
   *  tree may potentially have changed. You may then call getState() to read the current state tree inside the callback.
   * @param listener
   */
  subscribe(listener: CallableFunction): undefined;
  /**
   * Returns the current state tree of the Subscription Manager. It is equal to the last value returned by the store's reducer.
   * See https://redux.js.org/api/store#getstate for more details.
   */
  getState(): State;
  /**
   *
   * @param action Dispatches an action. This is the only way to trigger a state change.
   * @example `og.smi.store.dispatch({type: '<action>', payload:{<payload info>}})`
   */
  dispatch(action: { type: string; payload?: any }): void;
}

/**@internal */
export default function makeStore(): { store: Store; sagaMiddleware: SagaMiddleware; restartRootSaga: () => void } {
  const initialState = {};
  let store: Store;
  const composeEnhancers =
    typeof window === 'object' && window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
      ? window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']({
          name: 'Ordergroove SMI'
        })
      : compose;

  const sagaMiddleware = createSagaMiddleware<Store>({
    onError: (error: object, extra) => {
      if (error['status'] === 401) {
        store.dispatch({ type: constants.UNAUTHORIZED });
        return;
      }
      const actionName = (extra.sagaStack.match(/\bREQUEST_(\w+)\n$/) || [])[1];
      if (actionName) {
        const errorActionName = `ERROR_${actionName}`;
        if (errorActionName in constants) {
          store.dispatch({ type: constants[errorActionName], payload: { ...error, message: error.toString() } });
        }
      }
      console.error(error);
      console.error(extra.sagaStack);
    }
  });

  const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));

  store = createStore(reducer, initialState, enhancer) as Store;

  const task = sagaMiddleware.run(rootSaga);

  return {
    store,
    sagaMiddleware,
    // this is only exposed so the tests can restart the data fetching before each test case
    restartRootSaga: () => {
      task.cancel();
      sagaMiddleware.run(rootSaga);
    }
  };
}

const { store, sagaMiddleware, restartRootSaga } =
  (window['__OG_SMI_STORE__'] as { store: Store; sagaMiddleware: SagaMiddleware; restartRootSaga: () => void }) ||
  makeStore();

export { store };

export { sagaMiddleware, restartRootSaga };
