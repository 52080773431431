import * as constants from '../constants';

export default function merchant_id(state = '', action) {
  switch (action.type) {
    case constants.SET_MERCHANT_ID:
      return action.payload;
    default:
      return state;
  }
}
