import * as constants from '../constants';

export const envStaging = {
  name: 'staging',
  api_url: 'https://staging.v2.ordergroove.com',
  // scUrl: 'https://staging.sc.ordergroove.com',
  // widgetsUrl: 'https://staging.static.ordergroove.com',
  // masterDbUrl: 'https://staging.v2.ordergroove.com',
  // reorderUrl: 'https://staging.static.ordergroove.com/reorder/',
  lego_url: 'https://staging.restapi.ordergroove.com',
  shopify_app_url: 'https://staging.shopify.ordergroove.com',
  asset_url: 'https://staging.static.ordergroove.com/assets/sm',
  offers_url: 'https://staging.offers.ordergroove.com',
  tracking_url: 'https://staging.collect.ordergroove.com'
};

export const envDev = {
  name: 'dev',
  api_url: 'https://dev.api.ordergroove.com',
  // scUrl: 'https://dev.sc.ordergroove.com',
  // widgetsUrl: 'https://dev.static.ordergroove.com',
  // masterDbUrl: 'https://dev.api.ordergroove.com',
  // reorderUrl: 'https://staging.static.ordergroove.com/reorder/',
  lego_url: 'https://dev.restapi.ordergroove.com',
  shopify_app_url: 'https://staging.shopify.ordergroove.com',
  asset_url: 'https://staging.static.ordergroove.com/assets/sm',
  offers_url: 'https://dev.offers.ordergroove.com',
  tracking_url: 'https://staging.collect.ordergroove.com'
};

export const envProd = {
  name: 'prod',
  api_url: 'https://api.ordergroove.com',
  // scUrl: 'https://sc.ordergroove.com',
  // widgetsUrl: 'https://static.ordergroove.com',
  // masterDbUrl: 'https://api.ordergroove.com',
  // reorderUrl: 'https://static.ordergroove.com/reorder/',
  lego_url: 'https://restapi.ordergroove.com',
  shopify_app_url: 'https://shop-app.ordergroove.com',
  asset_url: 'https://static.ordergroove.com/assets/sm',
  offers_url: 'https://offers.ordergroove.com',
  tracking_url: 'https://collect.ordergroove.com'
};

export default function environment(state = {}, action) {
  switch (action.type) {
    case constants.SET_ENVIRONMENT_STAGING:
      return {
        ...state,
        ...envStaging
      };
    case constants.SET_ENVIRONMENT_DEV:
      return {
        ...state,
        ...envDev
      };
    case constants.SET_ENVIRONMENT_PROD:
      return {
        ...state,
        ...envProd
      };
    default:
      return state;
  }
}
