import changeShippingAddress from '@ordergroove/smi-store/sagas/request-change-shipping-address';

import { sagaMiddleware } from '../core/store';

export function initialize(form) {
  const customerEl = form.querySelector('[name="customer"]');
  if (!customerEl) {
    throw new Error('Missing <input name="customer" value="{{ customer.sig_field }}">');
  }
}

/**
 *
 * @param {FormData} data
 * @param {Object} state
 * @returns
 */
export function validate(data, state, form) {
  return { valid: form.checkValidity() };
}

export function submit(formData) {
  const task = sagaMiddleware.run(changeShippingAddress, {
    payload: {
      address_type: 'shipping_address',
      first_name: formData.get('first_name'),
      last_name: formData.get('last_name'),
      customer: formData.get('customer'),
      address: formData.get('address'),
      address2: formData.get('address2'),
      city: formData.get('city'),
      state_province_code: formData.get('state_province_code'),
      zip_postal_code: formData.get('zip_postal_code'),
      country_code: formData.get('country_code'),
      phone: formData.get('phone'),
      order_id: formData.get('order_id'),
      use_for_all: formData.get('use_for_all'),
      prev_address_id: formData.get('prev_address_id')
    }
  });
  return task;
}

export default {
  validate,
  initialize,
  submit
};
