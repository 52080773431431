import requestCancelSubscription from '@ordergroove/smi-store/sagas/request-cancel-subscription';

import { sagaMiddleware } from '../core/store';
import { selectSubscription } from './selectors';

export function initialize(form) {
  const subscriptionEl = form.querySelector('[name="subscription"]');
  if (!subscriptionEl) {
    throw new Error('Missing <input type="hidden" name="subscription" value="{{ subscription.public_id }}">');
  }
}

/**
 *
 * @param {FormData} data
 * @param {Object} state
 * @returns
 */
export function validate(data, state) {
  const reason = data.get('cancel_reason');
  const subscription_id = data.get('subscription');

  if (!subscription_id) return { valid: false, field: 'subscription', reason: 'INVALID_SUBSCRIPTION_ID' };

  const subscription = selectSubscription(subscription_id)(state);

  if (typeof subscription === 'undefined')
    return { valid: false, field: 'shipment_date', reason: 'SUBSCRIPTION_NOT_FOUND' };
  if (!reason) return { valid: false, field: 'cancel_reason', reason: 'CANCEL_REASON_REQUIRED' };

  return { valid: true };
}

export function submit(formData) {
  let reason = formData.get('cancel_reason');

  if (reason && !reason.includes('|')) {
    reason = `${reason}|${(formData.get('cancel_reason_details') || '').trim()}`;
  }

  const task = sagaMiddleware.run(requestCancelSubscription, {
    payload: {
      subscription_id: formData.get('subscription'),
      ...(reason && { cancel_reason: reason })
    }
  });
  return task;
}

export default {
  validate,
  initialize,
  submit
};
