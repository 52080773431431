import uniqBy from 'lodash/uniqBy';
import property from 'lodash/property';
import { put, take, all } from 'redux-saga/effects';
import {
  AUTHORIZE,
  RECEIVE_ORDER_ITEMS,
  REQUEST_AUTH,
  REQUEST_ORDERS,
  REQUEST_ORDER_ITEMS,
  REQUEST_PRODUCT,
  REQUEST_SUBSCRIPTIONS,
  REQUEST_PREPAID_INCENTIVES,
  REQUEST_ADDRESSES,
  REQUEST_SHOP_SETTINGS
} from '../constants';
import { OrderStatus } from '..';

export function* bootstrapApp() {
  yield put({ type: REQUEST_AUTH });

  yield take(AUTHORIZE);

  yield requestInitialState();

  while (true) {
    const {
      payload: { results }
    } = yield take(RECEIVE_ORDER_ITEMS);

    // query products as soon as receive order items
    yield all(
      uniqBy(results, property('product')).map(({ product: payload }) => put({ type: REQUEST_PRODUCT, payload }))
    );
  }
}

export function* requestInitialState() {
  yield put({ type: REQUEST_ADDRESSES });
  yield put({ type: REQUEST_SUBSCRIPTIONS });
  yield put({ type: REQUEST_PREPAID_INCENTIVES });
  yield put({ type: REQUEST_SHOP_SETTINGS });
  yield put({
    type: REQUEST_ORDERS,
    payload: { status: [OrderStatus.SEND_NOW, OrderStatus.UNSENT], replace_state: true }
  });
  yield put({
    type: REQUEST_ORDER_ITEMS,
    payload: { status: [OrderStatus.SEND_NOW, OrderStatus.UNSENT], replace_state: true }
  });
}
