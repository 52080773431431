import { call, put, select } from 'redux-saga/effects';

import { RECEIVE_CHANGE_SUBSCRIPTION_RENEWAL_BEHAVIOR } from '../constants';
import { OrderItem, State } from '..';
import { lego_subscription_change_renewal_behavior } from './api';

export enum PrepaidSubscriptionRenewalBehavior {
  CANCEL = 'cancel',
  AUTORENEW = 'autorenew'
}

export function isCancelledPrepaidSubscriptionWithNoOrdersRemaining(subscription) {
  return (
    subscription.prepaid_subscription_context?.prepaid_orders_remaining === 0 &&
    subscription.prepaid_subscription_context?.renewal_behavior === PrepaidSubscriptionRenewalBehavior.CANCEL
  );
}

export default function* REQUEST_CHANGE_SUBSCRIPTION_RENEWAL_BEHAVIOR({
  payload: { subscription_id, renewal_behavior }
}: {
  payload: { subscription_id: string; renewal_behavior: string };
}) {
  const subscription = yield call(lego_subscription_change_renewal_behavior, subscription_id, renewal_behavior);
  let refresh_orders = [];
  if (isCancelledPrepaidSubscriptionWithNoOrdersRemaining(subscription)) {
    const { items_by_order } = (yield select()) as State;
    refresh_orders = Array.from(
      new Set(
        Object.values(items_by_order)
          .flat()
          .filter(
            (order_item: OrderItem) =>
              order_item.subscription === subscription_id && items_by_order[order_item.order].length > 1
          )
          .map(order_item => order_item.order)
      )
    );
  }

  yield put({
    type: RECEIVE_CHANGE_SUBSCRIPTION_RENEWAL_BEHAVIOR,
    payload: { subscription, refresh_orders }
  });
}
