import dayjs from 'dayjs';
import requestPauseSubscription from '@ordergroove/smi-store/sagas/request-pause-subscription';

import { sagaMiddleware } from '../core/store';
import { selectOrder, selectSubscription } from './selectors';

export function initialize(form) {
  const subEl = form.querySelector('[name="subscription"]');
  if (!subEl) {
    throw new Error('Missing <input name="subscription" value="{{ subscription.public_id }}">');
  }
  const orderEl = form.querySelector('[name="order"]');
  if (!orderEl) {
    throw new Error('Missing <input name="order" value="{{ order.public_id }}">');
  }
  const shipmentDateEl = form.querySelector('[name="shipment_date"]');
  if (!shipmentDateEl) {
    throw new Error('Missing <input name="shipment_date" >');
  }

  shipmentDateEl.toggleAttribute('required', true);
  shipmentDateEl.min = dayjs().add(1, 'day').format('YYYY-MM-DD');
}

/**
 *
 * @param {FormData} data
 * @param {Object} state
 * @returns
 */
export function validate(data, state) {
  const date = data.get('shipment_date');
  const subscription_id = data.get('subscription');
  const order_id = data.get('order');

  if (!subscription_id) return { valid: false, field: 'subscription', reason: 'INVALID_SUBSCRIPTION_ID' };
  if (!order_id) return { valid: false, field: 'order', reason: 'INVALID_ORDER_ID' };
  if (!date) return { valid: false, field: 'date', reason: 'DATE_NOT_FOUND' };

  const subscription = selectSubscription(subscription_id)(state);
  const order = selectOrder(order_id)(state);

  if (typeof subscription === 'undefined')
    return { valid: false, field: 'subscription', reason: 'SUBSCRIPTION_NOT_FOUND' };
  if (typeof order === 'undefined') return { valid: false, field: 'order', reason: 'ORDER_NOT_FOUND' };
  if (date === order.place) return { valid: false, field: 'shipment_date', reason: 'ORDER_SAME_DATE' };
  if (dayjs(date).isBefore(dayjs())) return { valid: false, field: 'shipment_date', reason: 'ORDER_DATE_IN_PAST' };

  return { valid: true };
}

export function submit(formData) {
  const order_id = formData.get('order');
  const subscription_id = formData.get('subscription');
  const date = formData.get('shipment_date');
  const task = sagaMiddleware.run(requestPauseSubscription, {
    payload: {
      order_id,
      subscription_id,
      date: dayjs(date)
    }
  });
  return task;
}

export default {
  validate,
  initialize,
  submit
};
