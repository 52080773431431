import REQUEST_SEND_PAYMENT_EMAIL from '@ordergroove/smi-store/sagas/request-send-payment-email';

import { sagaMiddleware } from '../core/store';
import { getPaymentId, selectPayment } from './selectors';

export function initialize(form) {
  const paymentEl = form.querySelector('[name="payment_token"]');
  if (!paymentEl) {
    throw new Error('Missing <input name="payment_token" value="{{ payment.token_id }}">');
  }
}

/**
 *
 * @param {FormData} data
 * @param {Object} state
 * @returns
 */
export function validate(data, state) {
  const payment_token = data.get('payment_token');
  if (!payment_token) return { valid: false, field: 'payment', reason: 'INVALID_PAYMENT_TOKEN' };

  const payment = selectPayment(payment_token)(state);

  if (typeof payment === 'undefined') return { valid: false, field: 'payment', reason: 'PAYMENT_NOT_FOUND' };

  return { valid: true };
}

export function submit(formData) {
  const payment_token = formData.get('payment_token');
  const task = sagaMiddleware.run(REQUEST_SEND_PAYMENT_EMAIL, {
    payload: {
      payment_token: getPaymentId(payment_token)
    }
  });
  return task;
}

export default {
  validate,
  initialize,
  submit
};
