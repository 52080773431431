import requestChangeItemQuantity from '@ordergroove/smi-store/sagas/request-change-item-quantity';

import { sagaMiddleware } from '../core/store';
import { selectOrderItem } from './selectors';

export function initialize(form) {
  const orderItemEl = form.querySelector('[name="order_item"]');
  if (!orderItemEl) {
    throw new Error('Missing <input type="hidden" name="order_item" value="{{ order_item.public_id }}">');
  }
}

/**
 *
 * @param {FormData} data
 * @param {Object} state
 * @returns
 */
export function validate(data, state) {
  const order_item_id = data.get('order_item');

  if (!order_item_id) return { valid: false, field: 'order_item', reason: 'INVALID_ORDER_ITEM_ID' };

  const order_item = selectOrderItem(order_item_id)(state);

  if (typeof order_item === 'undefined') return { valid: false, field: 'order_item', reason: 'ORDER_ITEM_NOT_FOUND' };

  const quantity = data.get('quantity');
  if (!quantity) return { valid: false, field: 'quantity', reason: 'QUANTITY_REQUIRED' };

  return { valid: true };
}

export function submit(data, _store, _actionArgument) {
  if (!data.has('order_item')) return false;

  const item_id = data.get('order_item');
  const quantity = data.get('quantity');

  const task = sagaMiddleware.run(requestChangeItemQuantity, {
    payload: {
      item_id,
      quantity
    }
  });
  return task;
}

export default {
  validate,
  initialize,
  submit
};
