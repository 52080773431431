import { call, put } from 'redux-saga/effects';

import { RECEIVE_SEND_NOW } from '../constants';
import { lego_orders_send_now } from './api';

export default function* REQUEST_SEND_NOW({ payload: { order_id } }: { payload: { order_id: string } }) {
  yield put({
    type: RECEIVE_SEND_NOW,
    payload: yield call(lego_orders_send_now, order_id)
  });
}
