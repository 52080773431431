import partition from 'lodash/partition';
import { RECEIVE_ORDER_ITEMS, RECEIVE_SUBSCRIPTIONS, REPLACE_ORDER_ITEMS } from '../constants';
import { OrderItem, Subscription } from '../types';
import { groupBy } from '../utils';

const PARENT_BUNDLE_ITEM_PREFIX = 'bundle-';

function sumPrices(orderItems: OrderItem[]) {
  let price = 0;
  let totalPrice = 0;
  for (const item of orderItems) {
    price += Number(item.price) * item.quantity;
    totalPrice += Number(item.total_price);
  }
  return { price, totalPrice };
}

export function isMultiItemBundleComponentSubscription({ components }: Subscription) {
  return components?.length > 0 && typeof components[0] !== 'string' && components[0].quantity;
}

export function isMultiItemBundleParentItem(itemPublicId: string) {
  return itemPublicId.startsWith(PARENT_BUNDLE_ITEM_PREFIX);
}

function createParentBundleItemPublicId(subscription: Subscription, orderId: string) {
  return `${PARENT_BUNDLE_ITEM_PREFIX}${subscription.public_id}-${orderId}`;
}

function extendParentItemWithNewBundleItems(parentItem: OrderItem, itemsInTheSameBundle: OrderItem[]): OrderItem {
  const existingBundleItemIDs = new Set(parentItem.bundle_items.map(item => item.public_id));
  const newBundleItems = parentItem.bundle_items.concat(
    itemsInTheSameBundle.filter(item => item !== parentItem && !existingBundleItemIDs.has(item.public_id))
  );
  const { price, totalPrice } = sumPrices(newBundleItems);
  return {
    ...parentItem,
    bundle_items: newBundleItems,
    price: price.toFixed(2),
    total_price: totalPrice.toFixed(2)
  };
}

function createNewParentItem(itemsInTheSameBundle: OrderItem[], subscriptions: Subscription[]): OrderItem {
  const matchingSubscription = subscriptions.find(
    subscription => subscription.public_id === itemsInTheSameBundle[0].subscription
  );
  const { price, totalPrice } = sumPrices(itemsInTheSameBundle);
  return {
    ...itemsInTheSameBundle[0],
    // since this is not a "real" order item, we give it a specifically formatted public ID
    public_id: createParentBundleItemPublicId(matchingSubscription, itemsInTheSameBundle[0].order),
    product: matchingSubscription.product,
    quantity: matchingSubscription.quantity,
    bundle_items: itemsInTheSameBundle,
    price: price.toFixed(2),
    total_price: totalPrice.toFixed(2)
  };
}

const collapseBundleComponents = (state: {
  items_by_order: { [key: string]: OrderItem[] };
  subscriptions: Subscription[];
  order_item_by_id: { [key: string]: OrderItem };
}) => {
  // some order items are components of a single bundle subscription
  // we want to collapse them into a single parent bundle order item.
  const subscriptionsWithBundles = state.subscriptions.filter(isMultiItemBundleComponentSubscription);

  if (subscriptionsWithBundles.length === 0) {
    return state;
  }

  const bundleSubscriptionIds = new Set(subscriptionsWithBundles.map(s => s.public_id));

  const newItemsByOrder = {};
  const newOrderItemById = {
    ...state.order_item_by_id
  };

  for (const [orderId, orderItems] of Object.entries(state.items_by_order)) {
    const [bundleItems, nonBundleItems] = partition(orderItems, (item: OrderItem) =>
      bundleSubscriptionIds.has(item.subscription)
    );

    if (bundleItems.length === 0) {
      newItemsByOrder[orderId] = orderItems;
      continue;
    }

    const bundleItemsGroupedBySubscription: { [subscriptionId: string]: OrderItem[] } = groupBy(
      bundleItems,
      'subscription'
    );

    // collapse each set of order items from the same bundle subscription into a single item
    const collapsedItems = Object.values(bundleItemsGroupedBySubscription).map(itemsForSameSubscription => {
      // we could have an existing parent item here if this reducer is called multiple times
      // e.g. if items are split between multiple API responses
      const parentItem = itemsForSameSubscription.find(item => item.bundle_items != null);
      if (parentItem) {
        return extendParentItemWithNewBundleItems(parentItem, itemsForSameSubscription);
      }

      return createNewParentItem(itemsForSameSubscription, state.subscriptions);
    });

    for (const updatedItem of collapsedItems) {
      newOrderItemById[updatedItem.public_id] = updatedItem;
    }

    newItemsByOrder[orderId] = nonBundleItems.concat(collapsedItems);
  }

  return {
    ...state,
    items_by_order: newItemsByOrder,
    order_item_by_id: newOrderItemById
  };
};

export default function order_items_reducer(
  state: any = { orders: [], items_by_order: {}, subscriptions: [] },
  { type }
) {
  switch (type) {
    case RECEIVE_ORDER_ITEMS:
    case REPLACE_ORDER_ITEMS:
    case RECEIVE_SUBSCRIPTIONS:
      return collapseBundleComponents(state);
    default:
      return state;
  }
}
