import { call, put, select } from 'redux-saga/effects';
import { OrderItem, Product, State } from '..';

import { RECEIVE_DELETE_ITEM } from '../constants';
import { lego_items_delete, lego_orders_skip_subscription } from './api';
import { isMultiItemBundleParentItem } from '../reducers/order_items_reducer';
import { delete_orphaned_items } from './helpers/delete-orphans';

export default function* REQUEST_DELETE_ITEM({ payload: item_id }: { payload: string }) {
  let product: Product;
  let subscription: string;

  try {
    const { items_by_order, localized_product_by_id }: State = yield select();
    const item: OrderItem = Array.from(new Set(Object.values(items_by_order).flat())).find(
      order_item => order_item.public_id === item_id
    );
    const order_id = item.order;
    subscription = item.subscription;
    product = localized_product_by_id[item.product];
    if (isMultiItemBundleParentItem(item_id)) {
      // bundles are made up of multiple order items, so call an endpoint to skip all the order items associated with the subscription
      yield call(lego_orders_skip_subscription, order_id, subscription);
    } else {
      yield call(lego_items_delete, item_id);
    }

    // check for deletion if this is a subscription that might have left orphaned IU items
    if (subscription) {
      yield call(delete_orphaned_items, [order_id], items_by_order, subscription);
    }

    yield put({ type: RECEIVE_DELETE_ITEM, payload: { item_id, order_id, subscription, product, items_by_order } });
  } catch (error) {
    Object.assign(error, { product, subscription });
    throw error;
  }
}
